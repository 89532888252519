import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4, validate as uuidValidate } from "uuid";

import { Box, Grid, Typography } from "@mui/material";

import { postDesignImport } from "../../apis/forms/designs/post";
import { getForm } from "../../apis/forms/get";
import { getLayoutData } from "../../apis/layouts/get";
import FormImportDesign from "../../components/FormImportDesign";
import FormSettingsDrawer from "../../components/FormSettingsDrawer";
import NavigationMessage from "../../components/NavigationMessage";
import ToastContent from "../../components/ToastContent";
import { createFormByIdSelector } from "../../store/FormListSlice";
import { selectNotificationsSelectors } from "../../store/NotificationsSlice";
import { State } from "../../store/Reducers";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";
import { LoadingStatus } from "../../typings/GeneralTypes";

export type LayoutState = {
	id: string;
	html: string;
	loading: LoadingStatus;
};

export type DesignImportState = {
	loading: LoadingStatus;
	notified: boolean;
	notificationId?: string;
};

const InitialLayoutState: LayoutState = {
	loading: LoadingStatus.Initial,
	id: "",
	html: "",
};

const InitialDesignImportState: DesignImportState = {
	loading: LoadingStatus.Initial,
	notified: false,
};

const FormImport: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const { getAccessTokenSilently } = useAuth0();
	const form_id = match.params.form_id;
	const { id: workspace_id } = useSelector(selectWorkspace);
	const form = useSelector(createFormByIdSelector(form_id));

	const [layout, setLayout] = useState<LayoutState>({ ...InitialLayoutState });
	const [designImport, setDesignImport] = useState<DesignImportState>({ ...InitialDesignImportState });

	const fetchForm = useCallback(async () => {
		setLayout((state) => ({ ...state, loading: LoadingStatus.Pending }));

		const auth_token = await getAccessTokenSilently();
		const res = await getForm(form_id, { workspace_id, auth_token }).catch((e) => {
			console.warn("フォームにレイアウトの設定がありません");
			console.error(e);
			setLayout((state) => ({ ...state, loading: LoadingStatus.Error }));
		});

		const { layout_id: id } = res || {};
		if (!id) {
			setLayout((state) => ({ ...state, loading: LoadingStatus.Loaded }));
			return;
		}

		await getLayoutData(id, { workspace_id, auth_token })
			.then((html) => {
				setLayout((state) => ({ ...state, id, html, loading: LoadingStatus.Loaded }));
			})
			.catch((e) => {
				console.warn("レイアウト HTML の取得に失敗しました");
				console.error(e);
				setLayout((state) => ({ ...state, loading: LoadingStatus.Error }));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAccessTokenSilently, form_id, workspace_id]);

	useEffect(() => {
		workspace_id && fetchForm();
	}, [fetchForm, workspace_id]);

	const onDesignImport = async () => {
		setLayout((state) => ({ ...state, html: "" }));
		setDesignImport({ ...InitialDesignImportState, loading: LoadingStatus.Pending });

		const auth_token = await getAccessTokenSilently();
		await postDesignImport(form_id, { auth_token, workspace_id })
			.then((res) => {
				setDesignImport((state) => ({ ...state, notificationId: res.notification.id }));
			})
			.catch((reason: any) => {
				console.error(reason);
				setDesignImport((state) => ({ ...state, loading: LoadingStatus.Error }));
				toast(<ToastContent subject="デザインの取り込みを開始できませんでした" message={`詳細: ${reason.message}`} />, {
					toastId: v4(),
					type: toast.TYPE.ERROR,
					autoClose: 5000,
				});
			});
	};

	const notification = useSelector((state: State) => {
		return selectNotificationsSelectors.selectById(state, designImport.notificationId || "");
	});
	useEffect(() => {
		if (!designImport.notified && designImport.notificationId) {
			if (notification?.type === "info") {
				setDesignImport((state) => ({ ...state, loading: LoadingStatus.Loaded, notified: true }));
				fetchForm();
			}
			if (notification?.type === "error") {
				setDesignImport((state) => ({ ...state, notified: true }));
				toast(<ToastContent subject="デザインの取り込みに失敗しました" message={`詳細: ${notification.message}`} />, {
					toastId: v4(),
					type: toast.TYPE.ERROR,
					autoClose: 5000,
				});
			}
		}
	}, [designImport.notificationId, designImport.notified, fetchForm, notification]);

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate pageName="フォーム">
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	return (
		<MainTemplate pageName="フォーム設定" drawer={<FormSettingsDrawer form_id={form_id} />}>
			<Grid container spacing={0} justifyContent="center" data-testid="form-import-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							フォーム設定
						</Typography>
					</Box>
					<Box pt={1}>
						<FormImportDesign
							form_id={form_id}
							layout={layout}
							designImport={designImport}
							onDesignImport={onDesignImport}
							is_archived={form?.is_archive}
						/>
					</Box>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default FormImport;
