import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { PatchFormRequest } from "../apis/forms/patch";
import { PostFormRequest } from "../apis/forms/post";
import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import {
	createFormByIdSelector,
	fetchAsyncForm,
	fetchAsyncFormList,
	formListSelectors,
	registerAsyncForm,
	saveAsyncForm,
	selectArchivedFormList,
	selectEncodedFormPassword,
	selectFormFetchingError,
	selectFormFetchingStatus,
	selectFormListIsLoading,
	selectFormListLastError,
	selectFormListLoaded,
	selectOperationalFormList,
} from "../store/FormListSlice";
import { useAppDispatch } from "../store/store";
import { ListedForm } from "../typings/FormListTypes";
import { WithWorkspaceId } from "../typings/WorkspaceTypes";

export const useFormList = () => {
	const { getAccessTokenSilently } = useAuth0();
	const dispatch = useAppDispatch();

	const archivedFormList = useSelector(selectArchivedFormList);
	const operationalFormList = useSelector(selectOperationalFormList);
	const formList = useSelector(formListSelectors.selectAll);
	const lastError = useSelector(selectFormListLastError);
	const isLoading = useSelector(selectFormListIsLoading);
	const isLoaded = useSelector(selectFormListLoaded);

	// FIXME: 一時的な間借り
	const encodedFormPassword = useSelector(selectEncodedFormPassword);
	const formFetchingStatus = useSelector(selectFormFetchingStatus);
	const formFetchingError = useSelector(selectFormFetchingError);

	/** フォーム一覧を取得しストアに保存します */
	const fetchFormList = useCallback(
		async (workspace_id: string) => {
			if (!workspace_id) {
				return;
			}
			if (isLoaded) {
				return;
			}
			const auth_token = await getAccessTokenSilently();
			await dispatch(fetchAsyncFormList({ options: { workspace_id, auth_token } }));
		},
		[isLoaded, dispatch, getAccessTokenSilently]
	);

	/** アーカイブ済みフォーム一覧を取得しストアに保存します */
	const fetchArchivedFormList = useCallback(
		async (workspace_id: string) => {
			if (!workspace_id) {
				return;
			}
			const auth_token = await getAccessTokenSilently();
			await dispatch(fetchAsyncFormList({ is_archive: true, options: { workspace_id, auth_token } }));
		},
		[dispatch, getAccessTokenSilently]
	);

	const fetchForm = useCallback(
		async (workspace_id: string, form_id: string) => {
			if (!workspace_id) {
				return;
			}
			const auth_token = await getAccessTokenSilently();
			await dispatch(fetchAsyncForm({ id: form_id, options: { workspace_id, auth_token } }));
		},
		[dispatch, getAccessTokenSilently]
	);

	const fetchFormWithoutAuth = useCallback(
		async (workspace_id: string, form_id: string, encodedPassword?: string) => {
			if (!workspace_id) {
				return;
			}

			const options: ApiOptions = {
				workspace_id,

				// 引数で渡されたパスワードを優先的に使用、なければ保存された値を使用
				encodedFormPassword: encodedPassword || encodedFormPassword,
			};

			await dispatch(fetchAsyncForm({ id: form_id, options }));
		},
		[dispatch, encodedFormPassword]
	);

	const registerForm = useCallback(
		async (req: WithWorkspaceId<PostFormRequest>) => {
			if (!req.workspace_id) {
				return;
			}

			const auth_token = await getAccessTokenSilently();

			const arg = {
				postData: req,
				options: {
					auth_token,
					workspace_id: req.workspace_id,
				},
			};
			const resultAction = await dispatch(registerAsyncForm(arg));

			return resultAction?.payload as ListedForm;
		},
		[dispatch, getAccessTokenSilently]
	);

	/**
	 * フォーム設定の保存
	 */
	const saveForm = useCallback(
		async (req: WithWorkspaceId<PatchFormRequest>) => {
			if (!req.workspace_id) {
				return;
			}

			const auth_token = await getAccessTokenSilently();
			const resultAction = await dispatch(
				saveAsyncForm({
					patchData: req,
					options: {
						auth_token,
						workspace_id: req.workspace_id,
					},
				})
			);

			return resultAction?.payload as ListedForm;
		},
		[dispatch, getAccessTokenSilently]
	);

	return {
		archivedFormList,
		formList,
		isLoading,
		isLoaded,
		lastError,
		operationalFormList,
		createFormByIdSelector,
		fetchArchivedFormList,
		fetchFormList,
		fetchForm,
		fetchFormWithoutAuth,
		registerForm,
		saveForm,

		// FIXME: 一時的な間借り
		encodedFormPassword,
		formFetchingStatus,
		formFetchingError,
	};
};
