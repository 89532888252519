import { VFC } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { Box, Typography } from "@mui/material";

import { Contract } from "../typings/ContractType";

dayjs.extend(utc);
dayjs.locale("ja");

type Props = {
	contract?: Contract;
};

const ContractInfoChipElement: VFC<{ contract?: Contract }> = ({ contract }) => {
	const today = dayjs().format("YYYY-MM-DD");
	const diffDay = dayjs(contract?.end_date).diff(dayjs(today), "day") + 1;

	if (!contract) {
		return <></>;
	}

	// お試し期間の場合
	if (contract?.is_trial) {
		// 契約期限切れ
		if (diffDay <= 0) {
			return (
				<>
					<Typography variant="h6" component="p">
						{contract.plan_name} - 利用期限切れ
					</Typography>
				</>
			);
		}
		// 契約終了日が残り１日の場合
		if (diffDay === 1) {
			return (
				<>
					<Typography variant="h6" component="p">
						{contract.plan_name} - 本日が利用期限です
					</Typography>
				</>
			);
		}
		return (
			<>
				<Typography variant="body1">{contract.plan_name} - 残り</Typography>
				<Box mx={0.25}>
					<Typography variant="h6" component="p">
						{diffDay}
					</Typography>
				</Box>
				<Typography variant="body1">日</Typography>
			</>
		);
	}
	// 有料契約中で契約終了日がある場合
	if (!contract?.is_trial && contract?.end_date) {
		// 契約期限切れ
		if (diffDay <= 0) {
			return (
				<>
					<Typography variant="h6" component="p">
						利用期限切れ
					</Typography>
				</>
			);
		}
		// 契約終了日が残り１日の場合
		if (diffDay === 1) {
			return (
				<>
					<Typography variant="h6" component="p">
						本日が利用期限です
					</Typography>
				</>
			);
		}
		return (
			<>
				<Typography variant="body1">残り</Typography>
				<Box mx={0.25}>
					<Typography variant="h6" component="p">
						{diffDay}
					</Typography>
				</Box>
				<Typography variant="body1">日</Typography>
			</>
		);
	}
	// 	有料契約中の場合
	return <></>;
};

const ContractInfoChip: VFC<Props> = ({ contract }) => {
	return (
		<Box display="flex" flexShrink={0} alignItems="center" data-testid="contract-info-chip-root">
			<ContractInfoChipElement contract={contract} />
		</Box>
	);
};

export default ContractInfoChip;
