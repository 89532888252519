import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

export type AttachmentUrlGetRequest = {
	doc_id: string;
	field_id: string;
	file_path: string;
};

/**
 * 添付ファイルのダウンロードリンクを取得します
 *
 * @param options - API オプション
 */
export const getAttachmentUrl = async (params: AttachmentUrlGetRequest, options: ApiOptions): Promise<string> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { doc_id, field_id, file_path } = params || {};

	reqConfig.params = {
		id: field_id,
		path: file_path,
	};

	const { data } = await axios.get(`/docs/${doc_id}/file`, reqConfig).catch(axiosErrorHandler);

	return data;
};
