import { useEffect, VFC } from "react";
import { Redirect, useLocation } from "react-router-dom";

import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Typography } from "@mui/material";

import { APP_NAME } from "../App";
import ColorLink from "../components/ColorLink";
import ContentCenteredCard from "../components/ContentCenteredCard";
import MainAppBar from "../components/MainAppBar";
import theme from "../components/Theme";
import WorkspaceLockedDrawer from "../components/WorkspaceLockedDrawer";
import { LockedMessageMap } from "../constants/WorkspaceConst";
import { useWorkspace } from "../hooks/useWorkspace";
import SimpleTemplate from "../templates/SimpleTemplate";

function resolveExpiredMessage(reason: string): string {
	return LockedMessageMap[reason || ""];
}

const WorkspaceLocked: VFC = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const reason = params.get("reason") ?? "";
	const message = resolveExpiredMessage(reason);

	const { fetchWorkspace, workspace, workspaceLastError } = useWorkspace();

	useEffect(() => {
		fetchWorkspace();
	}, [fetchWorkspace]);

	if (workspaceLastError) {
		console.error(workspaceLastError);
		if (workspaceLastError.code === "403") {
			return <Redirect to={`/error/access-denied?m=${workspaceLastError.message}`} />;
		} else {
			return <Redirect to={`/error?m=${workspaceLastError.message}`} />;
		}
	}

	const title = workspace.name ? `${workspace.name} - ${APP_NAME}` : APP_NAME;

	return (
		<SimpleTemplate title={title} appBar={<MainAppBar />} drawer={<WorkspaceLockedDrawer />}>
			<Box data-testid="workspace-locked-root">
				<ContentCenteredCard variant="outlined">
					<Box p={1}>
						<Typography align="center" data-testid="workspace-locked-message">
							{message}
						</Typography>
						<Typography align="center">
							詳細は{" "}
							<ColorLink to={`/admin/contract`} color={theme.palette.primary.main} startIcon={SettingsIcon}>
								ワークスペース設定&gt;契約情報
							</ColorLink>{" "}
							からご確認ください。
						</Typography>
					</Box>
				</ContentCenteredCard>
			</Box>
		</SimpleTemplate>
	);
};

export default WorkspaceLocked;
