import React from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { Box, Card, CardProps, List, ListItem, Typography } from "@mui/material";

import { Contract } from "../typings/ContractType";

dayjs.extend(utc);
dayjs.locale("ja");

function createEndDateText(val?: string): string {
	if (!val) {
		return "----/--/--";
	}

	const today = dayjs().format("YYYY-MM-DD");
	const diffDay = dayjs(val).diff(dayjs(today), "day") + 1;
	const endDate = dayjs(val).format("YYYY/MM/DD");

	let adviceMsg = "";

	if (diffDay <= 0) {
		adviceMsg = "利用期限切れ";
	} else if (diffDay === 1) {
		adviceMsg = "本日が利用期限です";
	} else {
		adviceMsg = `残り ${diffDay} 日`;
	}

	return `${endDate} (${adviceMsg})`;
}

type Props = CardProps & {
	contract: Contract;
};

const WorkspaceContractViewCard: React.VFC<Props> = ({ contract, ...props }) => {
	const endDate = createEndDateText(contract?.end_date);
	return (
		<Card data-testid="workspace-contract-view-card-root" {...props}>
			<List>
				<ListItem>
					<Box>
						<Box mb={1}>
							<Typography>契約プラン名</Typography>
						</Box>
						<Box px={1}>
							<Typography variant="h5" component="p">
								{contract.plan_name}
							</Typography>
						</Box>
					</Box>
				</ListItem>
				<ListItem>
					<Box>
						<Box mb={1}>
							<Typography>利用期限</Typography>
						</Box>
						<Box px={1}>
							<Typography variant="h5" component="p">
								{endDate}
							</Typography>
						</Box>
					</Box>
				</ListItem>
			</List>
		</Card>
	);
};

export default WorkspaceContractViewCard;
