import { InvalidValueMessageType } from "../constants/FieldConst";
import { RadioButtonFieldEntity } from "../typings/FieldTypes";

import { FieldValidator, ValidateResult } from "./Validator";

/**
 * ラジオボタンフィールドの入力値検証クラス
 */
export class RadioButtonFieldValidator extends FieldValidator<RadioButtonFieldEntity> {
	validate(value: string | null | undefined): ValidateResult {
		// 必須チェック
		if (this.field.required && [null, "", undefined].includes(value)) {
			return this.invalid(InvalidValueMessageType.Required);
		}

		return this.valid();
	}
}
