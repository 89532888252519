import axios from "axios";

import { ServiceLink } from "../../typings/ServiceLinkTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** サービスリンク保存時のレスポンス */
export type PutServiceLinkResponse = {
	connection: {
		id: string;
		name: string;
	};
	config: ServiceLink;
};

/**
 * サービスリンク設定を取得します
 *
 * @param options - API オプション
 */
export const getServiceLink = async (form_id: string, options: ApiOptions): Promise<PutServiceLinkResponse | undefined> => {
	const reqConfig = generateAxiosRequestConfig(options);
	reqConfig.validateStatus = (status) => (status >= 200 && status < 300) || status === 404;

	const res = await axios.get(`/forms/${form_id}/servicelink`, reqConfig);

	if (res.status === 404) {
		return;
	}

	return res.data;
};
