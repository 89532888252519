import { VFC } from "react";

import { Card, CardProps, Grid, List, ListItem } from "@mui/material";

/**
 * ドキュメントのアクションカード
 */
const ActionCard: VFC<CardProps> = ({ children, ...props }) => {
	return (
		<Card variant="outlined" data-testid="document-actions-root" {...props}>
			<Grid container direction="row" justifyContent="flex-end">
				<List>
					<Grid container direction="row">
						{children instanceof Array
							? children.map((node, idx) => (
									<Grid item key={idx}>
										<ListItem>{node}</ListItem>
									</Grid>
							  ))
							: [
									<Grid item key={0}>
										<ListItem>{children}</ListItem>
									</Grid>,
							  ]}
					</Grid>
				</List>
			</Grid>
		</Card>
	);
};

export default ActionCard;
