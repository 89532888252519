import { useEffect, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import jaLocale from "dayjs/locale/ja";

import { Box, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { DateFieldEntity } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";
import { DateUtil } from "../../utils/DateUtil";

/**
 * 有効な日時または null を返します
 *
 * 有効な日付書式であれば Date 型で返しますが、変換できない文字列等であれば例外にはならず null を返します。
 *
 * @param value 変換対象の値
 * @returns 有効な日時 または null
 */
const toDateOrNull = (value: any): Date | null => {
	try {
		return DateUtil.toDate(value) ?? null;
	} catch (_ex) {}
	return null;
};

/**
 * フィールド設定に基づいて初期値を生成します
 *
 * @param field 日付フィールドの設定
 * @returns 有効な日付または未定義なら null
 */
const generateInitialValue = (field: DateFieldEntity): Date | null => {
	// 当日
	if (field.initialize_by === "created_date") {
		// 日付のみで時刻をオフセットごとカットする
		const d = dayjs(new Date().toISOString(), "YYYY-MM-DD");
		return new Date(d.format("YYYY-MM-DD"));
	}
	// 指定
	else if (field.initial_value) {
		return toDateOrNull(field.initial_value);
	}

	return null;
};

type Props = {
	value?: StringFieldValue;
	field: DateFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

const validStrictDateString = (value: string | null | undefined): string | null => {
	if (typeof value === "string") {
		if (dayjs(value, "YYYY/MM/DD", true).isValid()) {
			return value;
		}
	}
	return null;
};

const DateField: React.VFC<Props> = ({ value, field, editable, onChange }) => {
	const { id, table_id, table_row } = field || {};

	const inputRef = useRef<HTMLInputElement>(null);
	const [dateValue, setDateValue] = useState<Dayjs | null>(null);
	const [prevStringValue, setPrevStringValue] = useState<string | null | undefined>(undefined);

	const toDateFormat = (format?: string): string => {
		// FIXME 本来は表示形式のチェックが必要
		if (format) {
			return format;
		}
		return "YYYY/MM/DD";
	};

	const fireChangeEvent = (id: string, value: Date | null) => {
		const stringValue = value && dayjs(value).format("YYYY-MM-DD");
		onChange({ id, value: stringValue, table_id, table_row });
	};

	const checkAndFireChangeEvent = () => {
		// この段階で dateValue は古い値の場合あり。
		// 入力欄の input エレメント値を直接吸い出す
		const currentText = validStrictDateString(inputRef.current?.value);
		if (currentText !== prevStringValue) {
			const v = currentText ? new Date(currentText) : null;
			console.log("fire", v);
			fireChangeEvent(id, v);
			setPrevStringValue(currentText);
		}
	};

	const handleChange = (date: Dayjs | null) => {
		if (!editable) {
			return;
		}

		setDateValue(date);
	};

	/**
	 * カレンダーから日付を選択された
	 */
	const handleAccept = (_date: Dayjs | null) => {
		if (!editable) {
			return;
		}

		// HACK ステート変更からの DOM 反映をまつ
		// 日付選択により onChange → onAccept の順でイベントが連続発火する。
		// ただし、onChange でステート変更を予約してもレンダリング待ちらしく、
		// この段階ではまだ入力欄の input エレメント DOM に反映されてない。
		// 別タイミングにずらすためにタイマーを噛ます。
		// checkAndFireChangeEvent();
		setTimeout(() => {
			checkAndFireChangeEvent();
		}, 1);
	};

	/**
	 * 入力欄に手入力してフォーカスアウトした
	 */
	const handleInputBlur = () => {
		if (!editable) {
			return;
		}

		if (dateValue) {
			if (!dateValue.isValid()) {
				setDateValue(null);
			}
		}

		checkAndFireChangeEvent();
	};

	useEffect(() => {
		if (value === undefined) {
			// undefined の場合は一度も設定されてないフィールドなので初期値を生成する
			// ※null は明示的な入力値無しなので混同しないこと
			const initialValue = generateInitialValue(field);
			setDateValue(initialValue ? dayjs(initialValue) : null);

			// 何らかの初期値があれば1回変更ありとして通知しておく
			initialValue && fireChangeEvent(id, initialValue);
		} else {
			const initialValue = toDateOrNull(value);
			setDateValue(initialValue ? dayjs(initialValue) : null);
		}

		// fireChangeEvent 警告対策
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, field]);

	return (
		<Box data-testid="date-field">
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={jaLocale}>
				<DesktopDatePicker
					value={dateValue}
					onChange={handleChange}
					onAccept={handleAccept}
					inputRef={inputRef}
					renderInput={(props) => (
						<TextField
							{...props}
							disabled
							{...(editable && {
								disabled: false,
							})}
							variant="standard"
							// FIXME
							// InputProps={{ disableUnderline: true }}
							sx={{ paddingRight: 1 }}
						/>
					)}
					// FIXME: 警告がでる
					InputProps={{
						disableUnderline: true,
						onBlur: handleInputBlur,
					}}
					inputFormat={toDateFormat(field?.display_format)}
					mask="____/__/__"
					closeOnSelect
					disabled
					{...(editable && {
						disabled: false,
					})}
				/>
			</LocalizationProvider>
		</Box>
	);
};

export default DateField;
