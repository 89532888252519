import { VFC } from "react";
import { Link as RouterLink } from "react-router-dom";

import styled from "@emotion/styled";
import ArchiveIcon from "@mui/icons-material/Archive";
import InboxIcon from "@mui/icons-material/Inbox";
import { Collapse, Link, List, ListItemButton, ListItemText } from "@mui/material";
import { Skeleton } from "@mui/material";

import { useFormList } from "../hooks/useFormList";

import OverflowTooltip from "./OverflowTooltip";
import StyledDrawer from "./StyledDrawer";
import theme from "./Theme";

const CustomListItem = styled(ListItemButton)({
	paddingLeft: theme.spacing(1.75),
});

const ChildrenList = styled(List)({
	paddingTop: 0,
});

const ChildrenListItem = styled(ListItemButton)({
	paddingTop: "6px",
	paddingBottom: "6px",
	paddingLeft: theme.spacing(2.75),
	fontSize: "15px",
});

const CustomInboxIcon = styled(InboxIcon)({
	width: "18px",
	height: "18px",
	marginRight: theme.spacing(0.75),
});
const CustomArchiveIcon = styled(ArchiveIcon)({
	width: "18px",
	height: "18px",
	marginRight: theme.spacing(0.75),
});

const MainDrawer: VFC = () => {
	const { operationalFormList: forms, isLoaded: isFormsLoaded } = useFormList();

	return (
		<StyledDrawer variant="permanent" data-testid="main-drawer-root">
			<List>
				<Collapse in={true} timeout="auto">
					<Link component={RouterLink} to="/forms" data-testid="drawer-form-list-link">
						<CustomListItem>
							<CustomInboxIcon color="primary" />
							<ListItemText primary="フォーム一覧" />
						</CustomListItem>
					</Link>

					<ChildrenList>
						{isFormsLoaded ? (
							forms.map((form, index) => (
								<Link component={RouterLink} to={`/forms/${form.id}`} key={index}>
									<ChildrenListItem>
										<ListItemText>
											<OverflowTooltip title={form.name} placement="right">
												<>{form.name}</>
											</OverflowTooltip>
										</ListItemText>
									</ChildrenListItem>
								</Link>
							))
						) : (
							<Skeleton variant="rectangular" height={6} />
						)}
					</ChildrenList>
				</Collapse>

				<Link component={RouterLink} to="/forms/archived" data-testid="drawer-archived-form-list-link">
					<CustomListItem>
						<CustomArchiveIcon color="primary" />
						<ListItemText primary="アーカイブ済み" />
					</CustomListItem>
				</Link>
			</List>
		</StyledDrawer>
	);
};

export default MainDrawer;
