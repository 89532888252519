import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

type ActivateWorkspaceLogoResponse = {
	success: true;
};

/**
 * ワークスペースにロゴを設定済みにします
 *
 * @param options - API オプション
 */
export const activateWorkspaceLogo = async (options: ApiOptions): Promise<ActivateWorkspaceLogoResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const res = await axios.post("/workspaces/logo-activate", {}, reqConfig).catch(axiosErrorHandler);

	return res.data;
};
