import axios from "axios";

import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** サブドメインを解決するためのリクエストペイロード */
export type GetSubdomainRequest = {
	/** サブドメイン名 */
	subdomain: string;
};

/** サブドメインを解決するための応答ペイロード */
export type GetSubdomainResponse = {
	/** サブドメイン名 */
	subdomain: string;

	/** ワークスペース */
	workspace: {
		id: string;
		name: string;
		logo_enabled?: boolean;
	};
};

/**
 * サブドメインを取得します。
 *
 * @param reqData - 取得対象のサブドメイン
 * @param options - API のオプション
 */
export const getSubdomains = async (reqData: GetSubdomainRequest, options: ApiOptions): Promise<GetSubdomainResponse> => {
	const endpoint = `/subdomains/${reqData.subdomain}`;
	const reqConfig = generateAxiosRequestConfig(options);

	const response = await axios.get(endpoint, reqConfig);

	return response.data;
};
