import { createSlice, PayloadAction, createAsyncThunk, SerializedError } from "@reduxjs/toolkit";

import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import { getSubdomains } from "../apis/subdomains";
import { getWorkspace } from "../apis/workspace/get";
import { patchWorkspace, PatchWorkspaceRequest } from "../apis/workspace/patch";
import { Contract } from "../typings/ContractType";

import { State } from "./Reducers";

interface WorkspaceOwner {
	user_id: string;
	name: string;
}

export interface Workspace {
	id: string;
	subdomain?: string;
	name?: string;
	description?: string;
	is_active?: boolean;
	logo_enabled?: boolean;
	owner?: WorkspaceOwner;
	created_at?: Date;
	updated_at?: Date;
	contract?: Contract;
}

export interface WorkspaceState {
	isLoading: boolean;
	loaded: boolean;
	workspace: Workspace;
	lastError?: SerializedError;
}

/**
 * サブドメインからワークスペース情報の一部を取得し、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `subdomain.subdomain` 検索対象の サブドメイン名 *
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 *
 */
export const fetchAsyncSubdomains = createAsyncThunk(
	"subdomain/fetch",
	async (request: { subdomain: { subdomain: string }; options: { auth_token?: string } }): Promise<Workspace> => {
		const { subdomain, options } = request;
		const res = await getSubdomains(subdomain, options);
		const workspace: Workspace = {
			id: res.workspace.id,
			name: res.workspace.name,
			logo_enabled: res.workspace.logo_enabled,
			subdomain: res.subdomain,
		};

		return workspace;
	}
);

/**
 * ワークスペース情報を取得し、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `subdomain.subdomain` 検索対象の サブドメイン名 *
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 *
 */
export const fetchAsyncWorkspace = createAsyncThunk(
	"workspace/fetch",
	async (request: { subdomain: { subdomain: string }; options: { auth_token?: string; workspace_id?: string } }): Promise<Workspace> => {
		const { subdomain, options } = request;

		const { workspace } = await getSubdomains(subdomain, options);
		const res = await getWorkspace(workspace.id, { auth_token: options.auth_token, workspace_id: workspace.id });

		return { ...res, subdomain: subdomain.subdomain };
	}
);

/**
 * ワークスペースを更新し、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `patchData` 新規作成データ
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 */
export const saveAsyncWorkspace = createAsyncThunk(
	"workspace/save",
	async (request: { patchData: PatchWorkspaceRequest; options: ApiOptions }): Promise<Workspace> => {
		const res = await patchWorkspace(request.patchData, request.options);
		const workspace: Workspace = {
			id: res.id,
			name: res.name,
		};
		return workspace;
	}
);

const initialState: WorkspaceState = {
	isLoading: false,
	loaded: false,
	workspace: {
		subdomain: "",
		id: "",
		name: "",
	},
};

const workspaceSlice = createSlice({
	name: "workspace",
	initialState,
	reducers: {
		fetchWorkspaceStart(state) {
			state.isLoading = true;
		},
		fetchWorkspaceEnd(state) {
			state.isLoading = false;
			state.loaded = true;
		},
	},
	extraReducers: (builder) => {
		//#region fetchAsyncSubdomains
		builder.addCase(fetchAsyncSubdomains.pending, (state, _action) => {
			state.lastError = undefined;
		});
		builder.addCase(fetchAsyncSubdomains.fulfilled, (state, action: PayloadAction<Workspace>) => {
			return {
				...state,
				workspace: action.payload,
			};
		});
		builder.addCase(fetchAsyncSubdomains.rejected, (state, action) => {
			state.lastError = {
				code: action.error.code,
				name: action.error.name,
				message: action.error.message,
			};
		});
		//#endregion

		//#region fetchAsyncWorkspace
		builder.addCase(fetchAsyncWorkspace.pending, (state, _action) => {
			state.lastError = undefined;
		});
		builder.addCase(fetchAsyncWorkspace.fulfilled, (state, action: PayloadAction<Workspace>) => {
			return {
				...state,
				workspace: action.payload,
			};
		});
		builder.addCase(fetchAsyncWorkspace.rejected, (state, action) => {
			state.lastError = {
				code: action.error.code,
				name: action.error.name,
				message: action.error.message,
			};
		});
		//#endregion

		//#region saveAsyncWorkspace
		builder.addCase(saveAsyncWorkspace.pending, (_state, _action) => {});
		builder.addCase(saveAsyncWorkspace.fulfilled, (state, action: PayloadAction<Workspace>) => {
			return {
				...state,
				workspace: action.payload,
			};
		});
		builder.addCase(saveAsyncWorkspace.rejected, (_state, action) => {
			// eslint-disable-next-line no-console
			console.error(action.error);
			throw new Error(action.error.message);
		});
		//#endregion
	},
});

export const { fetchWorkspaceStart, fetchWorkspaceEnd } = workspaceSlice.actions;

export const selectWorkspaceIsLoading = (state: State) => state.workspace.isLoading;
export const selectWorkspaceLastError = (state: State) => state.workspace.lastError;
export const selectWorkspaceLoaded = (state: State) => state.workspace.loaded;
export const selectWorkspace = (state: State) => state.workspace.workspace;

export default workspaceSlice;
