import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** フォーム作成 のリクエストペイロード */
export type PostFormRequest = {
	/** フォーム名 */
	name: string;
	/** フォーム説明文 */
	description?: string;
	/** フォームロゴ URL */
	logo_picture?: string;
	/** メンテナンスモード */
	is_maintenance?: boolean;
};

/** フォーム作成 のレスポンスペイロード */
export type PostFormResponse = {
	/** フォーム ID */
	id: string;
	/** フォーム ID */
	workspace_id: string;
	/** フォーム名 */
	name: string;
	/** フォーム説明文 */
	description?: string;
	/** フォームロゴ URL */
	logo_picture?: string;
	/** メンテナンスモード */
	is_maintenance?: boolean;
	/** 作成日時 */
	created_at?: string;
	/** 更新日時 */
	updated_at?: string;
};

/**
 * フォームを作成します
 *
 * @param reqData - 作成するフォームのリクエストデータ
 * @param options - API のオプション
 */
export const postForm = async (reqData: PostFormRequest, options: ApiOptions): Promise<PostFormResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	return axios
		.post<PostFormResponse>("/forms", reqData, reqConfig)
		.then(({ data }) => data)
		.catch(axiosErrorHandler);
};
