import { VFC } from "react";

import { Box, BoxProps } from "@mui/material";

import theme, { AppBarHeight } from "../../components/Theme";

const MainBox: VFC<BoxProps> = (props) => {
	return (
		<Box
			component="main"
			mt={`${AppBarHeight}px`}
			maxHeight={`calc(100vh - ${AppBarHeight}px)`}
			flexGrow={1}
			height="100vh"
			overflow="auto"
			sx={{
				[theme.breakpoints.down("sm")]: {
					mt: `${AppBarHeight - 8}px`,
					maxHeight: `calc(100vh - ${AppBarHeight - 8}px)`,
				},
			}}
			{...props}
		/>
	);
};

export default MainBox;
