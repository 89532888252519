import Big from "big.js";

/**
 * Big で数値を扱う場合の単発関数セット
 */
export class BigUtil {
	/**
	 * 文字列か数値型の値を Big に変換します
	 *
	 * @param v 変換する値
	 * @returns 該当すれば Big のインスタンス、未定義値などその他は undefined になります
	 */
	public static toBig(v: any): Big | undefined {
		if (typeof v === "string" && v !== "") {
			try {
				return new Big(v);
			} catch (error) {
				if (error instanceof Error && error.message === "[big.js] Invalid number") {
					return;
				}
				throw new Error(error as any);
			}
		}
		if (typeof v === "number") {
			return new Big(v);
		}
	}

	/**
	 * 値が最小値未満なら真を返します
	 *
	 * @param value 値
	 * @minValue 最小値
	 */
	public static underMinValue(value: any, minValue: string | undefined): boolean {
		const bigMinVal = this.toBig(minValue);
		if (!(value instanceof Big) || !(bigMinVal instanceof Big)) {
			return false;
		}

		return value.lt(bigMinVal);
	}

	/**
	 * 値が最大値を超えるなら真を返します
	 *
	 * @param value 値
	 * @maxValue 最大値
	 */
	public static overMaxValue(value: any, maxValue: string | undefined): boolean {
		const bigMaxVal = this.toBig(maxValue);
		if (!(value instanceof Big) || !(bigMaxVal instanceof Big)) {
			return false;
		}

		return bigMaxVal.lt(value);
	}
}
