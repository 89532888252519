import { VFC } from "react";

import { Card, CardContent, CardProps, Grid } from "@mui/material";

/**
 * アクションメッセージカード
 */
const ActionMessageCard: VFC<CardProps> = ({ children, ...props }) => {
	return (
		<Card variant="outlined" data-testid="action-message-card-root" {...props}>
			<CardContent>
				<Grid container justifyContent="center">
					{children}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ActionMessageCard;
