import React from "react";

import { Box, Typography } from "@mui/material";

import { StepStatusNames } from "../constants/DocConst";
import { WorkingStep } from "../typings/StepTypes";

type Props = {
	step: WorkingStep;
};

const DocumentStepStatus: React.VFC<Props> = ({ step }) => {
	let statusColor: "initial" | "primary" | "secondary" | "error" = "initial";
	if (step.status === "failed" || step.status === "error") {
		statusColor = "error";
	} else if (step.status === "completed") {
		statusColor = "primary";
	}

	return (
		<Box p={2} data-testid="document-step-status-root">
			<Box pb={1}>
				<Typography color="textSecondary">状態</Typography>
				<Box display="flex" alignItems="center" px={2} pt={1}>
					<Typography variant="h5" component="p">
						{step.name}
					</Typography>
					<Box mx={1}>
						<Typography>-</Typography>
					</Box>
					<Typography component="span" color={statusColor}>
						{StepStatusNames[step.status]}
					</Typography>
				</Box>
			</Box>

			{step.error && (
				<>
					<Box pb={2}>
						<Typography color="textSecondary">詳細</Typography>
						<Box px={2} pt={1}>
							<Typography>{step.error.message}</Typography>
							<Typography>
								{step.error.status_code} {step.error.name}
							</Typography>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};

export default DocumentStepStatus;
