import { DocActionType } from "../constants/DocConst";

import { WorkingStep } from "./StepTypes";

/** ドキュメントの表示モード */
export const DocMode = { Read: "read", Edit: "edit", Confirm: "confirm", Sent: "sent" } as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DocMode = typeof DocMode[keyof typeof DocMode];

/** ドキュメント情報 */
export type DocEntity = {
	id?: string;
	workspace_id?: string;
	subject?: string;
	form?: {
		id: string;
		name: string;
	};
	layout?: {
		id: string;
	};
	step?: {
		id: string;
		name: string;
	};
	working_step?: WorkingStep;
	rev?: number;
	created_at?: Date;
	updated_at?: Date;
};

/**
 * ドキュメントアクション
 */
export type DocAction = { type: DocActionType };
