/**
 * String を扱う場合の単発関数セット
 */
export class StringUtil {
	/**
	 * 文字列の長さが指定数値を下回るかの検証
	 *
	 * @param v 検証文字列
	 * @param num 指定数値
	 * @return boolean
	 */
	public static lessThanMinLength(v: string, num: number | undefined): boolean {
		if (v === undefined || num === undefined) {
			return false;
		}
		return [...v].length < num;
	}
	/**
	 * 文字列の長さが指定値を上回るかの検証
	 *
	 * @param v 検証文字列
	 * @param num 指定数値
	 * @return boolean
	 * */
	public static overMaxLength(v: string, num: number | undefined): boolean {
		if (v === undefined || num === undefined) {
			return false;
		}
		return num < [...v].length;
	}
}
