import axios from "axios";

import { Fields } from "../../../typings/FieldTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

/**
 * getLayoutFields の戻り値
 */
export type GetLayoutFieldsResponseBody = {
	layout: {
		/** レイアウト ID */
		id: string;
		/** レイアウトの種類 */
		type: string;
	};
	fields: Fields;
};

/**
 * フィールド設定の一覧を返します
 *
 * @param layout_id レイアウト ID
 */
export const listFields = async (layout_id: string, options: ApiOptions, form_id?: string): Promise<GetLayoutFieldsResponseBody> => {
	const reqConfig = generateAxiosRequestConfig(options);
	if (form_id) {
		reqConfig.params = { f: form_id };
	}

	const uri = options.auth_token ? `/layouts/${layout_id}/fields` : `/g/layouts/${layout_id}/fields`;
	return axios.get(uri, reqConfig).then((res) => {
		if (res.status !== 200) {
			console.error(res.statusText, res.data);
			throw new Error(`${res.status}: ${res.statusText}`);
		}
		return res.data;
	});
};
