import React, { useEffect, useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { Backdrop, Box, CssBaseline, LinearProgress } from "@mui/material";

import { APP_NAME } from "../App";
import { getLogoUrl } from "../apis/workspace/logo/getLogoUrl";
import GuestMainAppBar from "../components/GuestMainAppBar";
import theme from "../components/Theme";
import { fetchAsyncSubdomains, fetchWorkspaceEnd, fetchWorkspaceStart, selectWorkspace, selectWorkspaceLoaded } from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";
import { LoadingStatus } from "../typings/GeneralTypes";

import MainBox from "./parts/MainBox";

/** サブドメインからワークスペースをストアに保存します */
function getSubdomainByLocation(): string {
	const host: string = window.location.host;
	if (host === "localhost:3000") {
		return "localhost";
	}
	return host.split(".")[0];
}

interface Props {
	children: React.ReactNode;
	pageName?: string;
	drawer?: React.ReactNode;
	isLoading?: boolean;
}

const GuestTemplate: React.VFC<Props> = ({ children, pageName, isLoading }) => {
	const workspace = useSelector(selectWorkspace);
	const workspace_id = workspace.id;

	const dispatch: AppDispatch = useDispatch();

	const isWorkspaceLoaded = useSelector(selectWorkspaceLoaded);

	/** サブドメインからワークスペースを取得しストアに保存します */
	const fetchWorkspace = useCallback(async () => {
		if (isWorkspaceLoaded) {
			return;
		}
		const subdomain = getSubdomainByLocation();
		dispatch(fetchWorkspaceStart());

		await dispatch(fetchAsyncSubdomains({ subdomain: { subdomain }, options: {} }));
		dispatch(fetchWorkspaceEnd());
		//TODO: getAccessTokenSilently を含めると document.title のテストがうまくいかない
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const [logoUrl, setLogoUrl] = useState<string>("");
	const [logoLoading, setLogoLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	useEffect(() => {
		if (!workspace_id || !workspace.logo_enabled) return;

		(async () => {
			setLogoLoading(LoadingStatus.Pending);
			const url = await getLogoUrl({ workspace_id });
			setLogoUrl(url);
			setLogoLoading(LoadingStatus.Fulfilled);
		})();
	}, [workspace_id, workspace.logo_enabled]);

	useEffect(() => {
		fetchWorkspace();
	}, [fetchWorkspace]);

	const title = `${pageName ? pageName + " - " : ""}${APP_NAME}`;

	const loading = isLoading || logoLoading === LoadingStatus.Pending;

	return (
		<Box data-testid="guest-template-root">
			<Helmet title={title} />
			<CssBaseline />

			<Box display="flex">
				{loading && (
					<>
						<LinearProgress sx={{ position: "absolute", zIndex: 9999, width: "100%" }} />
						<Backdrop
							sx={{ color: theme.palette.primary.main, backgroundColor: "rgba(255, 255, 255, 0.5)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
							open={loading}
						>
							{/*
							<CircularProgress color="inherit" />
							 */}
						</Backdrop>
					</>
				)}
				<GuestMainAppBar workspaceName={workspace?.name} logoUrl={logoUrl} loading={loading} />
				<MainBox data-testid="guest-template-content">
					<>{children}</>
				</MainBox>
			</Box>
		</Box>
	);
};

export default GuestTemplate;
