/**
 * ローディングステータス
 */
export const LoadingStatus = {
	Initial: "initial",
	Pending: "pending",
	Fulfilled: "fulfilled",
	Error: "error",

	/** @deprecated */
	Loaded: "loaded",
	/** @deprecated */
	Updated: "updated",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LoadingStatus = typeof LoadingStatus[keyof typeof LoadingStatus];
