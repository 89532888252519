import { ReactNode, VFC } from "react";

import { Box, Typography } from "@mui/material";

type Props = {
	subject?: string;
	message?: string;
	component?: ReactNode;
	children?: ReactNode;
};

const ToastContent: VFC<Props> = ({ subject, message, component, children }) => {
	return (
		<Box data-testid="toast-content-root">
			{subject && (
				<Typography variant="body2" data-testid="toast-content-subject">
					{subject}
				</Typography>
			)}
			{message && (
				<Typography variant="body2" data-testid="toast-content-message">
					{message}
				</Typography>
			)}
			{component ? <Box data-testid="toast-content-component">{component}</Box> : children && <Box data-testid="toast-content-children">{children}</Box>}
		</Box>
	);
};

export default ToastContent;
