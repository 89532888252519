import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import { LoadingButton } from "@mui/lab";
import { Accordion, AccordionDetails, Box, CardActions, TextField, Typography } from "@mui/material";

import { PatchFormRequest } from "../../apis/forms/patch";
import { FormConst } from "../../constants/FormConst";
import { saveAsyncForm } from "../../store/FormListSlice";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import { AppDispatch } from "../../store/store";
import { ListedForm } from "../../typings/FormListTypes";
import Form from "../Form";
import { FormWizardStepStatus, FormWizardStepType } from "../FormWizardForm";
import ToastContent from "../ToastContent";

import StepHeader from "./StepHeader";

type FormState = {
	name: string;
	description: string;
	logo_picture: string;
	is_maintenance: boolean;
};

type Props = {
	form: ListedForm;
	changeStatus: (value: FormWizardStepType) => void;
	errorStatusChanger: (value: FormWizardStepType) => void;
	expanded: boolean;
	stepSign: FormWizardStepStatus;
	disabled: boolean;
};

const FormWizardStep1: React.VFC<Props> = ({ form, changeStatus, errorStatusChanger, expanded, stepSign, disabled }) => {
	const { getAccessTokenSilently } = useAuth0();
	const dispatch: AppDispatch = useDispatch();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [loading, setLoading] = useState<boolean>(false);
	const { errors, formState, handleSubmit, register, reset } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			name: form.name,
		},
	});
	const { isValid, dirtyFields } = formState;

	const onSubmit = handleSubmit(async (data: ListedForm) => {
		if (dirtyFields.name === undefined) {
			changeStatus("step2");
			return;
		}

		const patchData: PatchFormRequest = {
			id: form.id,
			name: data.name,
		};

		setLoading(true);
		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});

		const auth_token = await getAccessTokenSilently();
		dispatch(saveAsyncForm({ patchData, options: { auth_token, workspace_id } }))
			.then(() => {
				reset(data);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: 1500,
					render: "フォームの名前を保存しました",
				});
				changeStatus("step2");
			})
			.catch((reason: any) => {
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
				setLoading(false);
				errorStatusChanger("step1");
			});
	});

	return (
		<Accordion expanded={expanded} disabled={disabled} data-testid="form-wizard-step1-root">
			<StepHeader title={"フォームの名前"} stepSign={stepSign} />
			<AccordionDetails>
				<Form onSubmit={onSubmit} data-testid="form-wizard-step1-form">
					<Box ml={1} mb={1}>
						<TextField
							id="name"
							name="name"
							label={FormConst.name.Name}
							helperText={errors.name?.message}
							variant="outlined"
							autoComplete="off"
							InputLabelProps={{
								required: true,
							}}
							fullWidth
							error={errors.name ? true : false}
							inputRef={register({
								required: { value: true, message: `${FormConst.name.Name}は必須です` },
								minLength: {
									value: FormConst.name.MinLength,
									message: `${FormConst.name.Name}は ${FormConst.name.MinLength} 文字以上にしてください`,
								},
								maxLength: {
									value: FormConst.name.MaxLength,
									message: `${FormConst.name.Name}は ${FormConst.name.MaxLength} 文字以内にしてください`,
								},
							})}
							data-testid="form-wizard-step1-name-field"
						/>
						<Typography pt={1} pl={1.75}>
							メニューや一覧に表示されます {FormConst.name.MinLength}-{FormConst.name.MaxLength} 文字以内
						</Typography>
					</Box>
					<CardActions>
						<LoadingButton
							type="submit"
							color="primary"
							disabled={loading || !isValid}
							loading={loading}
							size="large"
							variant="contained"
							data-testid="form-wizard-step1-submit-button"
						>
							次へ
						</LoadingButton>
					</CardActions>
				</Form>
			</AccordionDetails>
		</Accordion>
	);
};

export default FormWizardStep1;
