import { VFC } from "react";

import { Box } from "@mui/material";

import RoundSkeleton from "./RoundSkeleton";

const SettingCardsSkeleton: VFC = () => {
	return (
		<Box data-testid="setting-cards-skeleton-root">
			<Box>
				<RoundSkeleton variant="rectangular" height={220} />
			</Box>
			<Box mt={4}>
				<RoundSkeleton variant="rectangular" height={120} />
			</Box>
		</Box>
	);
};

export default SettingCardsSkeleton;
