import React from "react";
import { Link } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import theme from "./Theme";

export type TableViewItem = {
	[itemId: string]: string;
};

type Props = {
	columns: {
		[columnId: string]: string;
	};
	items: TableViewItem[];
	emptyMessage?: string;
};

const TableView: React.VFC<Props> = ({ columns, items, emptyMessage }) => {
	return (
		<TableContainer component={Paper} data-testid="table-view-root">
			<Table sx={{ minWidth: 650 }}>
				<TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
					<TableRow>
						{Object.entries(columns).map(([key, field]) => (
							<TableCell
								key={key}
								data-testid={`table-view-head-${key}`}
								sx={{
									fontWeight: "bold",
									color: theme.palette.primary.contrastText,
									letterSpacing: ".5px",
								}}
							>
								{field}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item, idx) => (
						<TableRow hover key={idx} component={Link} to={`/docs/${item.id}`} sx={{ textDecoration: "none" }}>
							{Object.keys(columns).map((key) => (
								<TableCell key={`${idx}-${key}`} data-testid={`table-view-body-${key}-${idx}`}>
									{item[key]}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
				{items.length === 0 && emptyMessage && <caption data-testid="table-view-empty-message">{emptyMessage}</caption>}
			</Table>
		</TableContainer>
	);
};

export default TableView;
