import { ComponentType, VFC } from "react";
import { RouteProps } from "react-router";
import { Route, RouteComponentProps } from "react-router-dom";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import Waiting from "./Waiting";

type Props = RouteProps & {
	component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
};

const ProtectedRoute: VFC<Props> = ({ component, ...props }) => {
	return (
		<Route
			component={withAuthenticationRequired(component, {
				returnTo: () => window.location.hash.slice(1),
				onRedirecting: () => <Waiting />,
			})}
			{...props}
		/>
	);
};

export default ProtectedRoute;
