import axios from "axios";

import { JSONFieldValue } from "../../typings/FieldValueTypes";
import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

export type PatchDocumentRequest = {
	/** リビジョン */
	rev: number;
	/** ユーザーの入力値の JSON マップ */
	values: { [fieldId: string]: JSONFieldValue };
};

export type PatchDocumentResponse = {
	/** ドキュメント ID */
	doc_id: string;
	/** リビジョン */
	rev: number;
};

/**
 * 特定のドキュメントのユーザー入力値を更新します
 *
 * * ドキュメントは現在未対応
 *
 * @param reqData - 更新するドキュメントのリクエストデータ
 * @param options - API オプション
 */
export const patchDocument = async (doc_id: string, reqData: PatchDocumentRequest, options: ApiOptions): Promise<PatchDocumentResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.patch(`/docs/${doc_id}`, reqData, reqConfig).catch(axiosErrorHandler);
	return data;
};
