import { createTheme } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";

export const AppBarHeight = 64;
export const DrawerWidth = 240;

// 全体に適用するフォントファミリーの優先順位
const fontFamilies = ["Helvetica Neue", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif"];

const defaultTheme = createTheme({
	palette: {
		primary: {
			main: "rgba(58,142,132,1)",
			contrastText: "rgba(250,250,250,1)",
		},
		secondary: {
			main: "#61B9CC",
			contrastText: "rgba(250,250,250,1)",
		},
		text: {
			primary: "#1a1a1a",
		},
		error: {
			main: "rgba(229,74,71,1)",
			contrastText: "rgba(250,250,250,1)",
		},
		background: {
			default: "rgba(230, 238, 237,1)",
		},
	},
	typography: {
		fontFamily: "'" + fontFamilies.join("','") + "'",
		fontSize: 12,
	},
});

const theme = createTheme(
	{
		components: {
			MuiBackdrop: {
				styleOverrides: {
					root: {
						backgroundColor: "rgba(0, 0, 0, 0.25)",
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					startIcon: {
						marginBottom: 1,
						marginRight: 4,
					},
				},
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						"&:last-child": {
							paddingBottom: 16,
						},
					},
				},
			},
			MuiContainer: {
				styleOverrides: {
					root: {
						paddingTop: 14,
					},
				},
			},
			MuiLink: {
				defaultProps: {
					color: defaultTheme.palette.primary.main,
					underline: "none",
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						color: defaultTheme.palette.text.primary,
					},
				},
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						marginBottom: 2,
						minWidth: 28,
					},
				},
			},
			MuiStepConnector: {
				styleOverrides: {
					vertical: {
						marginLeft: 10, // ステップ間の縦線 位置調整
					},
				},
			},
			MuiStepLabel: {
				styleOverrides: {
					labelContainer: {
						paddingTop: "0.125em", // ステップラベル 位置調整
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					gutters: {
						[defaultTheme.breakpoints.up("xs")]: {
							paddingLeft: 16,
							paddingRight: 16,
						},
						paddingLeft: 16,
						paddingRight: 16,
					},
				},
			},
		},
	},
	defaultTheme
);

export default theme;
