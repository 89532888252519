import axios from "axios";

import { DocAction } from "../../../typings/DocTypes";
import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

/** ドキュメントアクション送信時のリクエスト */
export type PostDocActionRequest = {
	action: DocAction;
};

/** ドキュメントアクション送信時のレスポンス */
export type PostDocActionResponse = {
	/** 通知 ID */
	id?: string;
	/** 相関 ID */
	correlation_id?: string;
};

/**
 * 再送信用ドキュメントアクションを送信します
 *
 * @param options - API オプション
 */
export const postDocRetryAction = async (doc_id: string, reqData: PostDocActionRequest, options: ApiOptions): Promise<PostDocActionResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	const prefix = options.auth_token ? "" : "/g";
	const { data } = await axios.post(`${prefix}/docs/${doc_id}/retry`, reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
