import { InvalidValueMessageType } from "../constants/FieldConst";
import { DateFieldEntity } from "../typings/FieldTypes";
import { DateUtil } from "../utils/DateUtil";

import { FieldValidator, ValidateResult } from "./Validator";

/**
 * 日付フィールドの入力値検証クラス
 */
export class DateFieldValidator extends FieldValidator<DateFieldEntity> {
	validate(value: string | null | undefined): ValidateResult {
		value ??= "";

		// 必須チェック
		if (this.field.required && value.trim() === "") {
			return this.invalid(InvalidValueMessageType.Required);
		}

		// 必須では無く未入力なら特有チェックはスキップ
		if (value === "") {
			return this.valid();
		}

		// 日付化チェック
		let xValue: Date | undefined;
		try {
			xValue = DateUtil.toDate(value);

			// コラボフローが受け付ける限界日付の範囲外か
			if (xValue) {
				const MIN_DATE = new Date("1900-01-01T00:00:00+0900");
				const MAX_DATE = new Date("9999-12-32T23:59:59+0900");
				const epoch = xValue.getTime();
				if (epoch < MIN_DATE.getTime() || epoch > MAX_DATE.getTime()) {
					return this.invalid(InvalidValueMessageType.OutOfRangeDate);
				}
			}
		} catch (_) {}
		if (xValue === undefined) {
			return this.invalid(InvalidValueMessageType.NotDate);
		}

		return this.valid();
	}
}
