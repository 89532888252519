import React from "react";
import { useSelector } from "react-redux";

import { Box, Card, CardHeader, Grid, LinearProgress, Typography } from "@mui/material";

import MainAppBar from "../../components/MainAppBar";
import WorkspaceAdminDrawer from "../../components/WorkspaceAdminDrawer";
import WorkspaceUpdateForm from "../../components/WorkspaceUpdateForm";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import SimpleTemplate from "../../templates/SimpleTemplate";

const AdminSettings: React.VFC = () => {
	const workspace = useSelector(selectWorkspace);

	return (
		<SimpleTemplate title="基本設定" appBar={<MainAppBar />} drawer={<WorkspaceAdminDrawer />}>
			<Grid container spacing={0} justifyContent="center" data-testid="workspace-admin-settings">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							基本設定
						</Typography>
					</Box>
					<Box pt={1}>
						<Card variant="outlined">
							<CardHeader title="基本設定" subheader="ワークスペースの基本的な設定" />
							{workspace.id ? <WorkspaceUpdateForm workspace={workspace} /> : <LinearProgress />}
						</Card>
					</Box>
				</Grid>
			</Grid>
		</SimpleTemplate>
	);
};

export default AdminSettings;
