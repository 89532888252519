import { AxiosRequestConfig } from "axios";

import authConfig from "../../auth-config.json";

/** API リクエストのオプション */
export type ApiOptions = {
	/**Auth0 トークン */
	auth_token?: string;

	/**
	 * ワークスペース ID
	 *
	 * ヘッダーに `x-workspace-id` として追加します
	 */
	workspace_id?: string;

	/**
	 * フォームパスワード
	 *
	 * ヘッダーに `x-form-authorization` として追加します
	 */
	encodedFormPassword?: string;
};

/**
 * 特定のワークスペースにアクセスするための AxiosRequestConfig を生成します。
 *
 * @param options API リクエストのオプション
 * @param audience ベース URL
 * - 省略すると auth-config.json の `audience` を使用します
 */
export function generateAxiosRequestConfig(options?: ApiOptions, audience = authConfig.audience): AxiosRequestConfig {
	const config: AxiosRequestConfig & Required<Pick<AxiosRequestConfig, "headers">> = {
		baseURL: audience,
		headers: {
			"Content-Type": "application/json",
		},
	};

	const { auth_token, encodedFormPassword, workspace_id } = options || {};

	if (auth_token) {
		config.headers.Authorization = "Bearer " + auth_token;
	}

	if (encodedFormPassword) {
		config.headers["x-form-authorization"] = encodedFormPassword;
	}

	// TODO: ワークスペース ID をトークンに含めたいが、実装にコストが高いため先送り
	if (workspace_id) {
		config.headers["x-workspace-id"] = workspace_id;
	}

	return config;
}
