import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";

import FormCard from "../../components/FormCard";
import FormEmptyCard from "../../components/FormEmptyCard";
import RoundSkeleton from "../../components/RoundSkeleton";
import { useFormList } from "../../hooks/useFormList";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";
import { LoadingStatus } from "../../typings/GeneralTypes";

const FormList: VFC = () => {
	const { id: workspace_id } = useSelector(selectWorkspace);
	const history = useHistory();
	const location = useLocation();

	const { registerForm, operationalFormList: forms, isLoaded: formsLoaded } = useFormList();

	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const onClick = async () => {
		setLoading(LoadingStatus.Pending);

		registerForm({
			workspace_id,
			name: "新しいフォーム",
		})
			.then((res) => {
				const loc = location.state as { from: Location };
				if (loc) {
					history.push(loc.from);
				} else if (res?.id) {
					const loc_from = { pathname: `/forms/${res.id}/wizard` };
					history.push(loc_from);
				}
				setLoading(LoadingStatus.Updated);
			})
			.catch((_reason: any) => {
				setLoading(LoadingStatus.Error);
			});
	};

	return (
		<MainTemplate isLoading={!formsLoaded}>
			<Grid container spacing={0} justifyContent="center" alignItems="center" data-testid="form-list-page-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Box flexGrow={1}>
							<Typography variant="h5" component="h2">
								フォーム一覧
							</Typography>
						</Box>
						{!!forms.length && (
							<LoadingButton
								color="primary"
								disabled={loading === LoadingStatus.Pending}
								startIcon={<AddIcon />}
								loading={loading === LoadingStatus.Pending || loading === LoadingStatus.Loaded}
								onClick={onClick}
								size="small"
								variant="contained"
								data-testid="form-list-page-loading-btn"
							>
								フォーム追加
							</LoadingButton>
						)}
					</Box>
					<Box mx={-1}>
						<Grid container justifyContent="flex-start">
							{formsLoaded ? (
								forms.length ? (
									forms.map((form, index) => (
										<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
											<Box p={1}>
												<FormCard form={form} />
											</Box>
										</Grid>
									))
								) : (
									<Grid container justifyContent="center">
										<Box mt={3} ml={1}>
											<FormEmptyCard loading={loading} onClick={onClick} />
										</Box>
									</Grid>
								)
							) : (
								[...Array(4)].map((_, index) => (
									<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
										<Box p={1}>
											<RoundSkeleton variant="rectangular" height={100} />
										</Box>
									</Grid>
								))
							)}
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default FormList;
