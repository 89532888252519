import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

import { PresignedPost } from "./post";

export type prepareLogoPostingRequest = {
	file_name: string;
};

/**
 * ロゴを送信するための準備をします
 *
 * @param options - API オプション
 */
export const prepareLogoPosting = async (reqData: prepareLogoPostingRequest, options: ApiOptions): Promise<PresignedPost> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.post("/workspaces/logo", reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
