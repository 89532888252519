import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppBar, IconButton, Link, Typography, Box, Toolbar, Tooltip } from "@mui/material";

import { useWorkspace } from "../hooks/useWorkspace";

import ContractInfoChip from "./ContractInfoChip";
import { DrawerWidth } from "./Theme";
import UserMenu from "./UserMenu";
import { VersionInfo } from "./VersionInfo";

const MainAppBar = () => {
	const { fetchWorkspace, workspace } = useWorkspace();

	useEffect(() => {
		fetchWorkspace();
	}, [fetchWorkspace]);

	return (
		<AppBar position="fixed" data-testid="main-app-bar-root">
			<Toolbar>
				<Box width={DrawerWidth - 16} flexShrink={0}>
					<Link component={RouterLink} to="/forms" data-testid="form-settings-drawer-back-link">
						<Box component="img" width="180px" paddingTop={0.25} src={`${process.env.PUBLIC_URL}/assets/images/logo-white.svg`} alt="" />
					</Link>
				</Box>
				<Box px={1} flexGrow={1} flexShrink={1} minWidth={100} overflow="hidden">
					<Typography component="p" variant="h6" color="inherit" noWrap data-testid="main-app-bar-workspace-title">
						{workspace.name}
					</Typography>
				</Box>
				<Box display="flex" flexShrink={0}>
					<ContractInfoChip contract={workspace.contract} />
					<VersionInfo />
					<Tooltip title="サポート">
						<IconButton color="inherit" href="https://collaboform.zendesk.com/" target="_blank" rel="noopener" size="large">
							<HelpIcon data-testid="main-app-bar-help-icon" />
						</IconButton>
					</Tooltip>
					<Tooltip title="ワークスペース設定">
						<Link component={RouterLink} to="/admin" color="inherit" mr={0.5}>
							<IconButton color="inherit" size="large">
								<SettingsIcon data-testid="main-app-bar-workspace-settings-link" />
							</IconButton>
						</Link>
					</Tooltip>
					<UserMenu />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default MainAppBar;
