import { VFC } from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

const ArchivedFormsEmptyCard: VFC = () => {
	return (
		<Card data-testid="archived-form-empty-card-root">
			<CardContent>
				<Box m={3} justifyContent="center">
					<Typography variant="h6" component="p">
						アーカイブ済みのフォームはありません
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ArchivedFormsEmptyCard;
