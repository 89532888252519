import { createSlice, createEntityAdapter, createAsyncThunk, SliceCaseReducers, SerializedError } from "@reduxjs/toolkit";

import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import { getNotifications } from "../apis/notifications/get";
import { LoadingStatus } from "../typings/GeneralTypes";
import { Notifications, NotificationEntity } from "../typings/NotificationsType";

import { State } from "./Reducers";

const fetchNotifications = createAsyncThunk("notifications/fetchNotifications", async (arg: { options: ApiOptions }) => {
	return getNotifications(arg.options).then((data) => {
		return data;
	});
});

const notificationsAdapter = createEntityAdapter<NotificationEntity>({
	selectId: (itemId) => itemId.id,
	sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

export type NotificationsState = {
	loading: LoadingStatus;
	error?: SerializedError;
	items: Notifications;
};

const slice = createSlice<NotificationsState, SliceCaseReducers<NotificationsState>>({
	name: "notifications",
	initialState: {
		loading: "initial",
		items: notificationsAdapter.getInitialState(),
	},
	reducers: {
		clear: (state) => {
			state.loading = "initial";
			notificationsAdapter.removeAll(state.items);
		},
	},

	extraReducers: (builder) => {
		// 取得中
		builder.addCase(fetchNotifications.pending, (state, _action) => {
			state.error = undefined;
			state.loading = "pending";
		});

		// 取得成功
		builder.addCase(fetchNotifications.fulfilled, (state, action) => {
			notificationsAdapter.removeAll(state.items);

			for (const notification of action.payload.notifications) {
				notificationsAdapter.addOne(state.items, { ...notification });
			}
			state.loading = "loaded";
		});

		// 処理例外、通信エラーなど
		builder.addCase(fetchNotifications.rejected, (state, action) => {
			state.loading = "error";
			state.error = action.error;
			console.error("通知一覧の取得に失敗", action.error);
		});
	},
});

// アクションたち
export const { clear } = slice.actions;

// AsyncThunk
export { fetchNotifications };

// useSelector 用
export const selectNotificationsLoading = (state: State) => state.notifications.loading;
export const selectNotificationsSelectors = notificationsAdapter.getSelectors((state: State) => state.notifications.items);
export const selectNotificationsError = (state: State) => state.notifications.error;

export default slice;
