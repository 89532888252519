import { VFC } from "react";

import { Box } from "@mui/system";

import RoundSkeleton from "./RoundSkeleton";

const AppBarSkeletons: VFC = () => {
	return (
		<Box display="flex" alignItems="center" data-testid="app-bar-skeletons-root">
			<Box pr={1}>
				<RoundSkeleton variant="rectangular" height={40} width={220} />
			</Box>
			<Box px={1}>
				<RoundSkeleton variant="rectangular" height={26} width={440} />
			</Box>
		</Box>
	);
};

export default AppBarSkeletons;
