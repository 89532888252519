import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import { LoadingButton } from "@mui/lab";
import { Accordion, AccordionDetails, Box, CardActions, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import { listProcess } from "../../apis/processes/list";
import { putServiceLink, PutServiceLinkRequest } from "../../apis/servicelink/put";
import { ServiceLinkConst } from "../../constants/ServiceLinkConst";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import { AuthAndServiceLink } from "../../typings/AuthenticationTypes";
import { LoadingStatus } from "../../typings/GeneralTypes";
import { Process } from "../../typings/ServiceLinkTypes";
import { isPlusSafeIntegerString } from "../../utils/Validator/isPlusSafeIntegerString";
import ExternalSiteLink from "../ExternalSiteLink";
import Form from "../Form";
import { FormWizardStepStatus, FormWizardStepType } from "../FormWizardForm";
import ToastContent from "../ToastContent";

import StepHeader from "./StepHeader";

type FormState = {
	authentication_id: string;
	app_cd: string;
	processes_id: string;
};

type Props = {
	form_id: string;
	authAndServiceLink?: AuthAndServiceLink;
	changeStatus: (value: FormWizardStepType) => void;
	errorStatusChanger: (value: FormWizardStepType) => void;
	expanded: boolean;
	stepSign: FormWizardStepStatus;
	disabled: boolean;
};

const FormWizardStep3: React.VFC<Props> = ({ form_id, authAndServiceLink, changeStatus, errorStatusChanger, expanded, stepSign, disabled }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [loading, setLoading] = useState<boolean>(false);
	const { errors, control, formState, handleSubmit, register, watch } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			authentication_id: authAndServiceLink?.authentication?.id ?? "",
			app_cd: "1",
			processes_id: (authAndServiceLink?.servicelink?.processes_id as string) ?? "",
		},
	});
	const { isValid, dirtyFields } = formState;

	const onSubmit = handleSubmit(async (data: FormState) => {
		const reqData: PutServiceLinkRequest = {
			connection: { id: authAndServiceLink?.authentication?.id ?? "" },
			config: {
				app_cd: data.app_cd,
				processes_id: data.processes_id,
			},
		};

		setLoading(true);
		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});

		const auth_token = await getAccessTokenSilently();
		await putServiceLink(form_id, reqData, { auth_token, workspace_id })
			.then(() => {
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: 1500,
					render: "経路を保存しました",
				});
				changeStatus("step4");
			})
			.catch((reason: any) => {
				console.error(reason);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
				setLoading(false);
				errorStatusChanger("step3");
			});
	});

	const [processList, setProcessList] = useState<Process[] | null>();
	const [processListLoading, setProcessListLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const fetchProcessList = useCallback(
		async (app_cd: string | number, auth_id: string) => {
			setProcessListLoading(LoadingStatus.Pending);
			const auth_token = await getAccessTokenSilently();

			await listProcess({ workspace_id, auth_token }, { auth_id, app_cd })
				.then((res) => {
					const processes = res.processes.map((process) => {
						return {
							id: process.id,
							name: process.title,
						};
					});

					processes.sort(function (a, b) {
						if (a.id > b.id) return 1;
						if (a.id < b.id) return -1;
						return 0;
					});
					setProcessList(processes);
					setProcessListLoading(LoadingStatus.Fulfilled);
				})
				.catch((reason) => {
					console.log(reason);
					setProcessListLoading(LoadingStatus.Error);
					toast(<ToastContent subject="経路を取得できませんでした" message={reason.message} />, {
						type: toast.TYPE.ERROR,
						autoClose: false,
					});
				});
		},
		[getAccessTokenSilently, workspace_id]
	);

	const validAppCd = (): boolean => {
		return isPlusSafeIntegerString(watch("app_cd"));
	};

	const getProcessList = (): void => {
		if (validAppCd() && authAndServiceLink?.authentication?.id) {
			const app_cd = watch("app_cd");
			const auth_id = authAndServiceLink.authentication.id;
			fetchProcessList(app_cd, auth_id);
		}
	};

	const selectProcessesListElement = [];
	if (processList) {
		for (const [key, item] of Object.entries(processList)) {
			selectProcessesListElement.push(
				<MenuItem key={item.id} value={item.id} data-testid={`process-${key}`}>
					{item.id} - {item.name}
				</MenuItem>
			);
		}
	}

	const isProcessReady = processListLoading === LoadingStatus.Pending || processListLoading === LoadingStatus.Initial || !validAppCd();

	return (
		<Accordion expanded={expanded} disabled={disabled} data-testid="form-wizard-step3-root">
			<StepHeader title={"経路選択"} stepSign={stepSign} />
			<AccordionDetails>
				<Form onSubmit={onSubmit} data-testid="form-wizard-step3-form">
					<Box ml={1}>
						<Box>
							<Typography variant="body1">関連付けたいコラボフローの経路を指定します。</Typography>
							<Typography variant="body1">申請書類に「Excelフォーム」を設定している経路を選択してください。</Typography>
							<Typography variant="body1">
								※経路の状態が「完成」の経路のみ指定できます。
								{/* //TODO: ヘルプが充実するまで暫定的にセクションのリンクを設置 */}
								<ExternalSiteLink href="https://collaboform.zendesk.com/hc/ja/sections/4566631136015">関連FAQ</ExternalSiteLink>
							</Typography>
						</Box>

						<Box mt={3} display="flex" flexDirection="column" alignItems="start">
							<Box>
								<InputLabel>{ServiceLinkConst.AppCd.Name}</InputLabel>
								<Box display="flex" alignItems="center" mb={2}>
									<Box mr={2}>
										<TextField
											id="app_cd"
											name="app_cd"
											variant="outlined"
											autoComplete="off"
											onBlur={getProcessList}
											InputLabelProps={{
												required: true,
											}}
											error={errors.app_cd ? true : false}
											inputRef={register({
												required: { value: true, message: `${ServiceLinkConst.AppCd.Name}は必須です` },
												validate: isPlusSafeIntegerString,
											})}
											data-testid="form-wizard-step3-form-app-cd"
										/>
									</Box>
									<LoadingButton
										onClick={getProcessList}
										loading={processListLoading === LoadingStatus.Pending}
										size="large"
										variant="contained"
										disabled={!validAppCd()}
									>
										経路を取得する
									</LoadingButton>
								</Box>
							</Box>

							<Box display="flex" alignItems="center">
								<Box minWidth="460px" maxWidth="650px" mr={2}>
									<InputLabel>{ServiceLinkConst.ProcessesId.Name}</InputLabel>
									<Controller
										name="processes_id"
										control={control}
										as={
											<Select
												id="processes_id"
												name="processes_id"
												autoComplete="off"
												data-testid="form-wizard-step3-form-processes-id"
												disabled={isProcessReady}
												sx={{
													...(isProcessReady && { background: "rgba(0, 0, 0, 0.12)" }),
												}}
											>
												{selectProcessesListElement}
											</Select>
										}
										fullWidth
										variant="outlined"
										rules={{ required: true }}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box mt={2}>
						<CardActions>
							<LoadingButton
								type="submit"
								color="primary"
								disabled={loading || !isValid || !Object.keys(dirtyFields).length}
								loading={loading}
								size="large"
								variant="contained"
								data-testid="form-wizard-step3-submit-button"
							>
								次へ
							</LoadingButton>
						</CardActions>
					</Box>
				</Form>
			</AccordionDetails>
		</Accordion>
	);
};

export default FormWizardStep3;
