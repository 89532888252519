import Big from "big.js";

import { InvalidValueMessageType } from "../constants/FieldConst";
import { NumberFieldEntity } from "../typings/FieldTypes";
import { BigUtil } from "../utils/BigUtil";
import { FieldsConverter } from "../utils/FieldsConverter";

import { FieldValidator, ValidateResult } from "./Validator";

/**
 * 数値フィールドの入力値検証クラス
 */
export class NumberFieldValidator extends FieldValidator<NumberFieldEntity> {
	validate(value: string | null | undefined): ValidateResult {
		value ??= "";

		// 必須チェック
		if (this.field.required && value.trim() === "") {
			return this.invalid(InvalidValueMessageType.Required);
		}

		// 必須では無く未入力なら特有チェックはスキップ
		if (value === "") {
			return this.valid();
		}

		// 形式チェック
		const converter = new FieldsConverter();
		if (!converter.isValidNumberString(value)) {
			return this.invalid(InvalidValueMessageType.NotNumber);
		}

		let xValue = BigUtil.toBig(value);
		if (xValue === undefined) {
			return this.invalid(InvalidValueMessageType.NotNumber);
		}

		// 入力時の端数は小数桁数で四捨五入して揃えておく
		// NOTE 下限値・上限値は小数桁数内に調整済みを前提とする
		xValue = xValue.round(this.field.decimal_places || 0, Big.roundHalfUp);

		// 下限値未満
		if (BigUtil.underMinValue(xValue, this.field.min_value)) {
			return this.invalid(InvalidValueMessageType.UnderMinValue);
		}

		// 上限値超過
		if (BigUtil.overMaxValue(xValue, this.field.max_value)) {
			return this.invalid(InvalidValueMessageType.OverMaxValue);
		}

		return this.valid();
	}
}
