import { createSlice, createEntityAdapter, createAsyncThunk, SliceCaseReducers, SerializedError } from "@reduxjs/toolkit";

import { listDoc } from "../apis/forms/docs/list";
import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import { FormDocs, FormDocItemEntity } from "../typings/DocListType";
import { LoadingStatus } from "../typings/GeneralTypes";

import { State } from "./Reducers";

const fetchFormDocs = createAsyncThunk("formDocs/fetchFormDocs", async (arg: { form_id: string; options: ApiOptions }) => {
	return listDoc(arg.form_id, arg.options).then((data) => {
		return data;
	});
});

const formDocsAdapter = createEntityAdapter<FormDocItemEntity>({
	selectId: (itemId) => itemId.id,
	sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

type Columns = {
	subject?: string;
	step?: string;
	updated_at?: string;
};

export type DocState = {
	loading: LoadingStatus;
	error?: SerializedError;
	columns: Columns;
	items: FormDocs;
};

const slice = createSlice<DocState, SliceCaseReducers<DocState>>({
	name: "formDocs",
	initialState: {
		loading: "initial",
		columns: {
			subject: "件名",
			step: "状態",
			updated_at: "最終更新日時",
		},
		items: formDocsAdapter.getInitialState(),
	},
	reducers: {
		clear: (state) => {
			state.loading = "initial";
			formDocsAdapter.removeAll(state.items);
		},
	},

	extraReducers: (builder) => {
		//#region ドキュメントデータの非同期取得
		// 取得中
		builder.addCase(fetchFormDocs.pending, (state, _action) => {
			state.error = undefined;
			state.loading = "pending";
		});

		// 取得成功
		builder.addCase(fetchFormDocs.fulfilled, (state, action) => {
			formDocsAdapter.removeAll(state.items);

			for (const doc of action.payload.docs) {
				formDocsAdapter.addOne(state.items, {
					id: doc.id,
					subject: doc.subject,
					status: doc.status,
					step: doc?.step,
					working_step: doc?.working_step,
					updated_at: doc.updated_at,
				});
			}
			state.loading = "loaded";
		});

		// 処理例外、通信エラーなど
		builder.addCase(fetchFormDocs.rejected, (state, action) => {
			state.loading = "error";
			state.error = action.error;
			console.error("ドキュメント一覧の読み込みに失敗", action.error);
		});
		//#endregion
	},
});

// アクションたち
export const { clear } = slice.actions;

// AsyncThunk
export { fetchFormDocs };

// useSelector 用
export const selectFormDocsLoading = (state: State) => state.formDocs.loading;
export const selectFormDocsSelectors = formDocsAdapter.getSelectors((state: State) => state.formDocs.items);
export const selectFormDocsColumns = (state: State) => state.formDocs.columns;
export const selectDocError = (state: State) => state.formDocs.error;

export default slice;
