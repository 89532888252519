import { UnitPosition } from "../typings/FieldTypes";

/**
 * 各種 Field Components で使用する hooks を管理
 */
export class FieldsConverter {
	//#region Number Field
	/**
	 * 単位の表示位置調整
	 *
	 * @param unit_position 表示位置の指定
	 */
	public toInputUnitPosition(unit_position: UnitPosition): string {
		if (unit_position === "prefix") {
			return "startAdornment";
		} else if (unit_position !== "suffix") {
			console.warn("単位の位置指定が不正です");
		}

		return "endAdornment";
	}

	/**
	 * 正しい形式の数値文字列かどうか検証します
	 *
	 * @param value 対象の値
	 */
	public isValidNumberString(value: any): value is string {
		if (value !== undefined) {
			const regExp = RegExp(/^-?[0-9]+(\.[0-9]+)?$/);
			return regExp.test(value);
		}
		return false;
	}

	//#endregion
}
