import React, { useEffect, useRef } from "react";

import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Card, CardContent, CardHeader, LinearProgress, Typography } from "@mui/material";

import { LoadingStatus } from "../typings/GeneralTypes";

import CirclerProgressCard from "./CirclerProgressCard";
import ColorLink from "./ColorLink";
import theme from "./Theme";

type Props = {
	form_id: string;
	layout: {
		id: string;
		html: string;
		loading: LoadingStatus;
	};
	designImport: {
		loading: LoadingStatus;
		notified: boolean;
		notificationId?: string;
	};
	onDesignImport: () => void;
	is_archived?: boolean;
};

const FormImportDesign: React.VFC<Props> = ({ form_id, is_archived = false, layout, designImport, onDesignImport }) => {
	const divRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (divRef.current) {
			divRef.current.innerHTML = layout.html;
		}
	}, [layout.html]);

	return (
		<Box data-testid="form-import-design-root">
			<Card variant="outlined">
				<CardHeader title="デザイン取り込み" subheader="接続で設定したフォームをコラボフォームにインポートをします" />
				{["pending", "initial"].includes(layout.loading) && !layout.id ? (
					<LinearProgress />
				) : (
					<CardContent>
						<Box mb={2}>
							<Typography className="text" variant="body1" color="textSecondary">
								<LiveHelpIcon fontSize="small" />
								「デザインを取り込む」ボタンを押すと、
								<ColorLink to={`/forms/${form_id}/connect`} color={theme.palette.primary.main} startIcon={SettingsIcon}>
									接続
								</ColorLink>{" "}
								で設定したコラボフローの経路 ID をもとに、デザインの取り込みを開始します
							</Typography>
						</Box>

						{layout.loading === LoadingStatus.Loaded && !layout.id && (
							<Box mb={1.5}>
								<Typography className="text" variant="body1" color="textPrimary">
									フォームにデザインが取り込まれていません
								</Typography>
							</Box>
						)}

						<Box>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								onClick={onDesignImport}
								disabled={is_archived || designImport.loading === LoadingStatus.Pending || layout.loading === LoadingStatus.Pending}
								data-testid="form-import-design-button"
							>
								デザインを取り込む
							</Button>
						</Box>
					</CardContent>
				)}
			</Card>

			<Box my={2}>
				{layout.loading === LoadingStatus.Pending ? (
					<CirclerProgressCard variant="outlined">
						<Typography variant="body2" color="textSecondary" align="center" data-testid="form-import-design-loading">
							デザインを読み込んでいます
						</Typography>
					</CirclerProgressCard>
				) : designImport.loading === LoadingStatus.Pending ? (
					<CirclerProgressCard variant="outlined">
						<Typography variant="body2" color="textSecondary" align="center" data-testid="form-import-design-importing">
							デザインを取り込んでいます
						</Typography>
						<Typography variant="body2" color="textSecondary" align="center">
							取り込みには数分ほどかかる場合があります
						</Typography>
					</CirclerProgressCard>
				) : layout.loading !== LoadingStatus.Loaded || designImport.loading === LoadingStatus.Error ? (
					<></>
				) : (
					<Card variant="outlined">
						<CardContent data-testid="form-import-design-html">
							<Box display="flex" justifyContent="center" m={2}>
								<div id="document" ref={divRef} />
							</Box>
						</CardContent>
					</Card>
				)}
			</Box>
		</Box>
	);
};

export default FormImportDesign;
