import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { Box } from "@mui/material";

import MainTemplate from "../../templates/MainTemplate";

/**
 * 開発者用コンポーネント等の動作確認用テストページ
 *
 * このページでの修正はコミット NG です。
 * 認証済みの状態で必要なロジックのテストに利用できます。
 *
 * @param match - パスの合致情報
 * @returns
 */
const DevelopView1: React.VFC<RouteComponentProps<{ id: string }>> = ({ match }) => {
	// なんかの ID
	// Route パス `/develop/view1/:id` の :id 部分。
	// eslint-disable-next-line
	const id = match.params.id;

	return (
		<MainTemplate pageName="Developer View 1">
			<Box>Developer only</Box>
		</MainTemplate>
	);
};

export default DevelopView1;
