import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import AssignmentIcon from "@mui/icons-material/Assignment";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CardProps,
	DialogActions,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Switch,
	TextField,
	Typography,
} from "@mui/material";

import { PatchFormRequest } from "../apis/forms/patch";
import { saveAsyncForm } from "../store/FormListSlice";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";
import { ListedForm } from "../typings/FormListTypes";

import Form from "./Form";
import ToastContent from "./ToastContent";

type FormState = {
	is_public: boolean;
};

type Props = CardProps & {
	form_id: string;
	is_public: boolean | undefined;
	is_archive: boolean | undefined;
};

const FormPublicAddressCard: React.VFC<Props> = ({ form_id, is_public = false, is_archive = false, ...args }) => {
	const { getAccessTokenSilently } = useAuth0();
	const dispatch: AppDispatch = useDispatch();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [loading, setLoading] = useState<boolean>(false);
	const { control, formState, handleSubmit, reset, watch } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			is_public,
		},
	});
	const { isValid, dirtyFields } = formState;
	const isPublicWatching = watch("is_public");

	const onCopy = (): void => {
		toast("公開アドレスをコピーしました", {
			type: toast.TYPE.INFO,
			toastId: uuidV4(),
			autoClose: 1000,
		});
	};

	const onSubmit = handleSubmit(async (data: ListedForm) => {
		const patchData: PatchFormRequest = {
			id: form_id,
		};

		dirtyFields.is_public !== undefined && (patchData.is_public = data.is_public);

		setLoading(true);
		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});

		const auth_token = await getAccessTokenSilently();
		dispatch(saveAsyncForm({ patchData, options: { auth_token, workspace_id } }))
			.then(() => {
				reset(data);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: null,
					render: "フォームを保存しました",
				});
			})
			.catch((reason: any) => {
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
			});
	});

	const host: string = window.location.host;

	const guestUri = `https://${host}/#/g/${form_id}`;

	return (
		<Card data-testid="form-public-address-card-root" {...args}>
			<CardHeader title="公開アドレス" subheader="フォームを公開することができます" />
			<Form onSubmit={onSubmit}>
				<CardContent>
					<Box ml={1.75}>
						<Controller
							name="is_public"
							control={control}
							render={(props) => (
								<FormControlLabel
									control={
										<Switch
											onChange={(e) => props.onChange(e.target.checked)}
											checked={props.value}
											color="primary"
											disabled={is_archive}
											data-testid="form-public-address-card-public-btn"
										/>
									}
									label="公開する"
								/>
							)}
						/>
						<Typography variant="caption" color="textSecondary" component="p">
							有効にするとフォームが公開状態になります
						</Typography>
					</Box>
					<Box mt={2}>
						<TextField
							value={isPublicWatching ? guestUri : " "}
							label="公開アドレス"
							helperText="このアドレスから公開されたフォームにアクセスすることができます"
							variant="outlined"
							autoComplete="off"
							fullWidth
							InputProps={{
								readOnly: true,
								endAdornment: (
									<InputAdornment position="end">
										{isPublicWatching && (
											<CopyToClipboard text={guestUri}>
												<IconButton onClick={onCopy} data-testid="form-public-address-card-guest-url-copy" tabIndex={-1} size="large">
													<AssignmentIcon />
												</IconButton>
											</CopyToClipboard>
										)}
									</InputAdornment>
								),
							}}
							data-testid="form-public-address-card-guest-url"
						/>
					</Box>
				</CardContent>

				<Divider />
				<DialogActions>
					<LoadingButton
						type="submit"
						variant="contained"
						color="primary"
						disabled={loading || !isValid || !Object.keys(dirtyFields).length}
						loading={loading}
						data-testid="form-public-address-card-create-btn"
					>
						保存
					</LoadingButton>
				</DialogActions>
			</Form>
		</Card>
	);
};

export default FormPublicAddressCard;
