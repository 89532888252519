import { forwardRef, ReactNode, VFC } from "react";

import styled from "@emotion/styled";
import { Fade, Modal, ModalProps } from "@mui/material";

const ForwardRefDiv = forwardRef<HTMLDivElement, { children?: ReactNode }>(({ children }, ref) => {
	return (
		<div ref={ref} tabIndex={0}>
			{children}
		</div>
	);
});

const CustomModal = styled(Modal)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	"& > *:focus-visible": {
		outline: 0,
	},
});

const FadingModal: VFC<ModalProps> = ({ children, ...args }) => {
	return (
		<CustomModal data-testid="fading-modal-root" closeAfterTransition BackdropProps={{ timeout: 500 }} {...args}>
			<Fade in={args.open}>
				<ForwardRefDiv>{children}</ForwardRefDiv>
			</Fade>
		</CustomModal>
	);
};

export default FadingModal;
