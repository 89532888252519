import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import styled from "@emotion/styled";
import { Box, Accordion, AccordionDetails, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import { listAuthentications } from "../../../apis/authentications/list";
import { selectWorkspace } from "../../../store/WorkspaceSlice";
import { AuthAndServiceLink, OstensibleAuthentication } from "../../../typings/AuthenticationTypes";
import { FormWizardStepStatus, FormWizardStepType } from "../../FormWizardForm";
import StepHeader from "../StepHeader";

import ExistingAuthenticationForm from "./ExistingAuthenticationForm";
import NewAuthenticationForm from "./NewAuthenticationForm";

const CustomAccordionDetails = styled(AccordionDetails)({
	display: "block",
});

type Props = {
	changeStatus: (value: FormWizardStepType) => void;
	errorStatusChanger: (value: FormWizardStepType) => void;
	setAuthAndServiceLink: React.Dispatch<React.SetStateAction<AuthAndServiceLink | undefined>>;
	expanded: boolean;
	stepSign: FormWizardStepStatus;
	disabled: boolean;
};

const FormWizardStep2: React.VFC<Props> = ({ changeStatus, errorStatusChanger, setAuthAndServiceLink, expanded, stepSign, disabled }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [mode, setMode] = useState<"new" | "existing" | undefined>();
	const handleModeChange = (v: React.ChangeEvent<HTMLInputElement>): void => {
		if (v.target.value === "new" || v.target.value === "existing") {
			setMode(v.target.value);
		}
	};
	const [authentications, setAuthentications] = useState<OstensibleAuthentication[] | undefined>();
	const [isAuthenticationsLoad, setIsAuthenticationsLoad] = useState<boolean>(false);
	const fetchAuthentications = useCallback(async () => {
		const auth_token = await getAccessTokenSilently();
		const res = await listAuthentications({ workspace_id, auth_token });

		const authentications = res.map((auth) => {
			return {
				id: auth.id,
				name: auth.name,
				provider: auth.provider,
			};
		});
		authentications.sort(function (a, b) {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		});
		if (authentications[0]) {
			setAuthentications(authentications);
			setMode("existing");
		} else {
			setMode("new");
		}
		setIsAuthenticationsLoad(true);
	}, [getAccessTokenSilently, workspace_id]);

	useEffect(() => {
		workspace_id && fetchAuthentications();
	}, [fetchAuthentications, workspace_id]);

	return (
		<Accordion expanded={expanded} disabled={disabled} data-testid="form-wizard-step2-root">
			<StepHeader title={"コラボフローの認証情報"} stepSign={stepSign} />

			<CustomAccordionDetails>
				{isAuthenticationsLoad && (
					<Box ml={2}>
						<RadioGroup aria-label="gender" name="gender1" value={mode} row onChange={(v) => handleModeChange(v)}>
							<FormControlLabel value="new" control={<Radio />} label="新しい認証の作成" />
							{authentications && <FormControlLabel value="existing" control={<Radio />} label="既存の認証" />}
						</RadioGroup>
					</Box>
				)}

				{isAuthenticationsLoad && mode === "new" && (
					<NewAuthenticationForm changeStatus={changeStatus} errorStatusChanger={errorStatusChanger} setAuthAndServiceLink={setAuthAndServiceLink} />
				)}

				{isAuthenticationsLoad && authentications && mode === "existing" && (
					<ExistingAuthenticationForm authentications={authentications} changeStatus={changeStatus} setAuthAndServiceLink={setAuthAndServiceLink} />
				)}
			</CustomAccordionDetails>
		</Accordion>
	);
};

export { FormWizardStep2 };
