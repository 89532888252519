import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { ApiOptions, generateAxiosRequestConfig } from "../misc/generateAxiosRequestConfig";

export type PresignedPost = {
	url: string;
	fields: { [key: string]: any };
};

export type AttachmentPostRequest = {
	file: File;
} & PresignedPost;

/**
 * 添付ファイルを送信します
 */
export const postAttachment = async (input: AttachmentPostRequest, options: ApiOptions): Promise<void> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { url, fields, file } = input || {};

	const form = new FormData();
	for (const key in fields) {
		form.append(key, fields[key]);
	}

	form.append("file", file);

	await axios.post(url, form, reqConfig).catch(axiosErrorHandler);

	return;
};
