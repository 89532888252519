import { FieldType } from "../constants/FieldConst";
import { StringConvertRule } from "../utils/StringConverter";

/**
 * フィールド
 */
export type FieldEntity = {
	/** ID */
	id: string;
	/** フィールド ID */
	field_id?: string;
	/** テーブル ID */
	table_id?: string;
	/** テーブル 行 */
	table_row?: number;
	/** フィールドの種類 */
	field_type: FieldType;
	/** フィールドの表示名 */
	name: string;
	/** ヒント */
	tips?: string;
	/** 必須かどうか */
	required?: boolean;
} & {
	// NOTE: バリデーターで共通処理しているため追加
	/** 変換ルール */
	convert_rules?: StringConvertRule[];
};

/**
 * フィールドのマップ
 */
export type Fields = {
	[field_id: string]: FieldEntity;
};

/**
 * 表示する中身の並び方向
 *
 * - horizontal - 水平に並べます
 * - vertical - 垂直に並べます
 */
export type ContentAlign = "horizontal" | "vertical";

/**
 * 文字種制限
 */
export type AllowedCharacterTypes = "alphabet_lower" | "alphabet_upper" | "number";

/**
 * 文字列フィールド
 */
export type TextFieldEntity = FieldEntity & {
	allowed_character_types?: AllowedCharacterTypes[];
	convert_rules?: StringConvertRule[];
	initial_value?: string;
	max_length?: number;
	min_length?: number;
};

/**
 * 複数行フィールド
 */
export type MultilineFieldEntity = FieldEntity & {
	allowed_character_types?: AllowedCharacterTypes[];
	convert_rules?: StringConvertRule[];
	initial_value?: string;
	max_length?: number;
	min_length?: number;
};

/**
 * フィールドにおける単位の表示位置
 */
export type UnitPosition = "prefix" | "suffix";

/**
 * 数値フィールド
 */
export type NumberFieldEntity = FieldEntity & {
	decimal_places: number;
	initial_value?: string;
	max_value?: string;
	min_value?: string;
	show_comma?: boolean;
	unit_position?: UnitPosition;
	unit_text?: string;
};

/**
 * 日付フィールド
 */
export type DateFieldEntity = FieldEntity & {
	initial_value?: string;
	initialize_by?: string;
	display_format?: string;
	// allowed_min_type?:string;
	// allowed_min_offset?:number;
	// allowed_max_type?:string;
	// allowed_max_offset?:number;
};

/**
 * 選択肢
 *
 * ドロップダウン、リストボックスなどで併用予定
 */
export type Selection = { label: string; value: string };

/**
 * ドロップダウンフィールド
 */
export type DropdownFieldEntity = FieldEntity & {
	initial_value?: string;
	selections?: Selection[];
};

/**
 * チェックボックスフィールドの表示スタイル
 */
export const CheckBoxViewStyle = {
	Standard: "standard",
	CheckboxOrLabel: "checkbox_or_label",
} as const;
export type CheckBoxViewStyle = typeof CheckBoxViewStyle[keyof typeof CheckBoxViewStyle];

/**
 * チェックボックスフィールド
 */
export type CheckboxFieldEntity = FieldEntity & {
	/** 初期でチェック済みにする値のリスト(不順) */
	initial_values?: string[];
	/** チェックボックスの選択肢一覧とチェック時の値 */
	selections?: Selection[];
	/** チェックボックスの未選択肢一覧と未チェック時の値 */
	no_selection: Selection;
	/** 表示スタイル */
	view_style: CheckBoxViewStyle;
};

/**
 * ラジオボタンフィールド
 */
export type RadioButtonFieldEntity = FieldEntity & {
	/** 初期選択するラジオボタンの値。未定義の場合はどれも選択しない状態 */
	initial_value?: string;
	/** ラジオボタンの選択肢 */
	selections?: Selection[];
	/** ラジオボタンの選択肢を配置する方向 */
	align?: ContentAlign;
};

/**
 * 添付ファイルフィールド
 */
export type AttachmentFieldEntity = FieldEntity & {
	/** 添付ファイルのコメント (通常フィールドのみ) */
	comment?: string;
};

/**
 * テーブルのメタ情報
 */
export type TableMeta = {
	columns?: string[];
	default_row?: number;
	display_row_number?: boolean;
	field_type?: string;
	id?: string;
	max_row?: number;
	name?: string;
	table_id?: string;
	table_index?: number;
};
