import { FC } from "react";

import styled from "@emotion/styled";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, LinkProps } from "@mui/material";

const NoDecorationLink = styled(Link)({
	"&:hover": {
		textDecoration: "none",
	},
});

const AdjustedIcon = styled(OpenInNewIcon)({
	marginBottom: -4,
});

type Props = LinkProps & {
	disableIcon?: boolean;
};

const ExternalSiteLink: FC<Props> = ({ children, disableIcon, ...args }) => {
	return (
		<NoDecorationLink target="_blank" data-testid="external-site-link-root" {...args}>
			{children} {disableIcon ? <></> : <AdjustedIcon fontSize="small" data-testid="external-site-link-external-icon" />}
		</NoDecorationLink>
	);
};

export default ExternalSiteLink;
