/** 文字列の変換ルール */
export type StringConvertRule =
	| "trim"
	| "trim_start"
	| "trim_end"
	| "to_upper"
	| "to_lower"
	| "to_narrow"
	| "to_wide"
	| "w2n_whitespace"
	| "w2n_numeric"
	| "w2n_alphabet"
	| "w2n_symbol"
	| "w2n_kana"
	| "n2w_whitespace"
	| "n2w_numeric"
	| "n2w_alphabet"
	| "n2w_symbol"
	| "n2w_kana"
	| "hira_to_kana";

/**
 * ひらがな→カタカナ リスト
 *
 * **注意** このリストを変換で使用する場合、2 文字で構成される文字(濁点、半濁点付き文字)の順序によって結果が変わります。リストを編集する際は順序に気をつけてください。
 */

const hiraToKanaMap = new Map([
	// NOTE: 順序に注意
	//#region 2 文字
	["が", "ガ"],
	["ぎ", "ギ"],
	["ぐ", "グ"],
	["げ", "ゲ"],
	["ご", "ゴ"],
	["ざ", "ザ"],
	["じ", "ジ"],
	["ず", "ズ"],
	["ぜ", "ゼ"],
	["ぞ", "ゾ"],
	["だ", "ダ"],
	["ぢ", "ヂ"],
	["づ", "ヅ"],
	["で", "デ"],
	["ど", "ド"],
	["ば", "バ"],
	["び", "ビ"],
	["ぶ", "ブ"],
	["べ", "ベ"],
	["ぼ", "ボ"],
	["ぱ", "パ"],
	["ぴ", "ピ"],
	["ぷ", "プ"],
	["ぺ", "ペ"],
	["ぽ", "ポ"],
	//#endregion
	//#region 1 文字
	["ぁ", "ァ"],
	["ぃ", "ィ"],
	["ぅ", "ゥ"],
	["ぇ", "ェ"],
	["ぉ", "ォ"],
	["ゃ", "ャ"],
	["ゅ", "ュ"],
	["ょ", "ョ"],
	["っ", "ッ"],
	["あ", "ア"],
	["い", "イ"],
	["う", "ウ"],
	["え", "エ"],
	["お", "オ"],
	["か", "カ"],
	["き", "キ"],
	["く", "ク"],
	["け", "ケ"],
	["こ", "コ"],
	["さ", "サ"],
	["し", "シ"],
	["す", "ス"],
	["せ", "セ"],
	["そ", "ソ"],
	["た", "タ"],
	["ち", "チ"],
	["つ", "ツ"],
	["て", "テ"],
	["と", "ト"],
	["な", "ナ"],
	["に", "ニ"],
	["ぬ", "ヌ"],
	["ね", "ネ"],
	["の", "ノ"],
	["は", "ハ"],
	["ひ", "ヒ"],
	["ふ", "フ"],
	["へ", "ヘ"],
	["ほ", "ホ"],
	["ま", "マ"],
	["み", "ミ"],
	["む", "ム"],
	["め", "メ"],
	["も", "モ"],
	["や", "ヤ"],
	["ゆ", "ユ"],
	["よ", "ヨ"],
	["ら", "ラ"],
	["り", "リ"],
	["る", "ル"],
	["れ", "レ"],
	["ろ", "ロ"],
	["わ", "ワ"],
	["を", "ヲ"],
	["ん", "ン"],
	//#endregion
]);

/**
 * カタカナリスト (全角 -> 半角)
 *
 * **注意** このリストを変換で使用する場合、2 文字で構成される文字(濁点、半濁点付き文字)の順序によって結果が変わります。リストを編集する際は順序に気をつけてください。
 */
const kanaW2NMap = new Map([
	// NOTE: 順序に注意
	//#region 2 文字
	["ガ", "ｶﾞ"],
	["ギ", "ｷﾞ"],
	["グ", "ｸﾞ"],
	["ゲ", "ｹﾞ"],
	["ゴ", "ｺﾞ"],
	["ザ", "ｻﾞ"],
	["ジ", "ｼﾞ"],
	["ズ", "ｽﾞ"],
	["ゼ", "ｾﾞ"],
	["ゾ", "ｿﾞ"],
	["ダ", "ﾀﾞ"],
	["ヂ", "ﾁﾞ"],
	["ヅ", "ﾂﾞ"],
	["デ", "ﾃﾞ"],
	["ド", "ﾄﾞ"],
	["バ", "ﾊﾞ"],
	["ビ", "ﾋﾞ"],
	["ブ", "ﾌﾞ"],
	["ベ", "ﾍﾞ"],
	["ボ", "ﾎﾞ"],
	["ヴ", "ｳﾞ"],
	["ヷ", "ﾜﾞ"],
	["ヺ", "ｦﾞ"],
	["パ", "ﾊﾟ"],
	["ピ", "ﾋﾟ"],
	["プ", "ﾌﾟ"],
	["ペ", "ﾍﾟ"],
	["ポ", "ﾎﾟ"],
	//#endregion
	//#region 1 文字
	["ァ", "ｧ"],
	["ィ", "ｨ"],
	["ゥ", "ｩ"],
	["ェ", "ｪ"],
	["ォ", "ｫ"],
	["ャ", "ｬ"],
	["ュ", "ｭ"],
	["ョ", "ｮ"],
	["ッ", "ｯ"],
	["ア", "ｱ"],
	["イ", "ｲ"],
	["ウ", "ｳ"],
	["エ", "ｴ"],
	["オ", "ｵ"],
	["カ", "ｶ"],
	["キ", "ｷ"],
	["ク", "ｸ"],
	["ケ", "ｹ"],
	["コ", "ｺ"],
	["サ", "ｻ"],
	["シ", "ｼ"],
	["ス", "ｽ"],
	["セ", "ｾ"],
	["ソ", "ｿ"],
	["タ", "ﾀ"],
	["チ", "ﾁ"],
	["ツ", "ﾂ"],
	["テ", "ﾃ"],
	["ト", "ﾄ"],
	["ナ", "ﾅ"],
	["ニ", "ﾆ"],
	["ヌ", "ﾇ"],
	["ネ", "ﾈ"],
	["ノ", "ﾉ"],
	["ハ", "ﾊ"],
	["ヒ", "ﾋ"],
	["フ", "ﾌ"],
	["ヘ", "ﾍ"],
	["ホ", "ﾎ"],
	["マ", "ﾏ"],
	["ミ", "ﾐ"],
	["ム", "ﾑ"],
	["メ", "ﾒ"],
	["モ", "ﾓ"],
	["ヤ", "ﾔ"],
	["ユ", "ﾕ"],
	["ヨ", "ﾖ"],
	["ラ", "ﾗ"],
	["リ", "ﾘ"],
	["ル", "ﾙ"],
	["レ", "ﾚ"],
	["ロ", "ﾛ"],
	["ワ", "ﾜ"],
	["ヲ", "ｦ"],
	["ン", "ﾝ"],
	["゛", "ﾞ"],
	["゜", "ﾟ"],
	//#endregion
]);

/**
 * カタカナリスト (半角 -> 全角)
 *
 * **注意** このリストを変換で使用する場合、2 文字で構成される文字(濁点、半濁点付き文字)の順序によって結果が変わります。リストを編集する際は順序に気をつけてください。
 *  */
const kanaN2WMap = new Map(Array.from(kanaW2NMap, (e) => [e[1], e[0]]));

/**
 * 記号リスト (全角 -> 半角)
 *
 * **注意** このリストを変換で使用する場合、2 文字で構成される文字の順序によって結果が変わります。リストを編集する際は順序に気をつけてください。
 */
const symbolW2NMap = new Map([
	// NOTE: 順序に注意
	["ー", "ｰ"],
	["”", '"'],
	["’", "'"],
	["’", "'"],
	["￥", "\\"],
	["‘", "`"],
]);

/**
 * 記号リスト (半角 -> 全角)
 *
 * **注意** このリストを変換で使用する場合、2 文字で構成される文字の順序によって結果が変わります。リストを編集する際は順序に気をつけてください。
 *  */
const symbolN2WMap = new Map(Array.from(symbolW2NMap, (e) => [e[1], e[0]]));

/**
 * 文字列をルールに基づいて別の文字列に変換します
 */
export class StringConverter {
	/** 変換ルールの関数対応マップ */
	private _methodMap = {
		trim: this.trim,
		trim_start: this.trimStart,
		trim_end: this.trimEnd,
		to_upper: this.toUpper,
		to_lower: this.toLower,
		to_narrow: this.toNarrow,
		to_wide: this.toWide,
		w2n_whitespace: this.toNarrowWhitespace,
		w2n_numeric: this.toNarrowNumeric,
		w2n_alphabet: this.toNarrowAlphabet,
		w2n_symbol: this.toNarrowSymbol,
		w2n_kana: this.toNarrowKana,
		n2w_whitespace: this.toWideWhitespace,
		n2w_numeric: this.toWideNumeric,
		n2w_alphabet: this.toWideAlphabet,
		n2w_symbol: this.toWideSymbol,
		n2w_kana: this.toWideKana,
		hira_to_kana: this.hiraToKana,
	} as const;

	/**
	 * 与えられた変換ルールに従って文字列を変換します
	 *
	 * 変換ルールの配列の順に処理します
	 *
	 * @param s 文字列
	 * @param convertRules 変換ルールの配列
	 */
	public convert(s: string, convertRules: StringConvertRule[]): string {
		if (s !== undefined && convertRules?.length) {
			for (const rule of convertRules) {
				s = this._methodMap[rule]?.call(this, s) || s;
			}
		}
		return s;
	}

	//#region 空白文字の除去
	/**
	 * 両端の空白を取り除いた文字列を返します
	 *
	 * 除去対象の文字種：半角空白、全角空白、タブ文字、改行コード（CR、LF）、no-break space
	 * @see https://tc39.es/ecma262/#sec-trimstring
	 * @see https://tc39.es/ecma262/#prod-WhiteSpace
	 * @see https://tc39.es/ecma262/#prod-LineTerminator
	 */
	public trim(s: string): string {
		return s.trim();
	}

	/**
	 * 先頭の空白を取り除いた文字列を返します
	 */
	public trimStart(s: string): string {
		// NOTE: ES2019以降
		return s.trimStart();
	}

	/**
	 * 末尾の空白を取り除いた文字列を返します
	 */
	public trimEnd(s: string): string {
		// NOTE: ES2019以降
		return s.trimEnd();
	}
	//#endregion

	//#region 大 ↔ 小
	/**
	 * 大文字を小文字に変換します
	 *
	 * 半角/全角 を維持したまま変換します。
	 */
	public toLower(s: string): string {
		return s.toLocaleLowerCase();
	}

	/**
	 * 小文字を大文字に変換します
	 *
	 * 半角/全角 を維持したまま変換します。
	 */
	public toUpper(s: string): string {
		return s.toLocaleUpperCase();
	}
	//#endregion

	//#region 全 → 半
	/** 対応文字を全角から半角にします */
	public toNarrow(s: string): string {
		s = this.toNarrowWhitespace(s);
		s = this.toNarrowNumeric(s);
		s = this.toNarrowAlphabet(s);
		s = this.toNarrowKana(s);
		s = this.toNarrowSymbol(s);
		return s;
	}

	/** スペースを全角から半角にします */
	public toNarrowWhitespace(s: string): string {
		return s.replace(/　/g, " ");
	}

	/** 数字を全角から半角にします */
	public toNarrowNumeric(s: string): string {
		return s.replace(/[０-９]/g, this.unShiftCharCode);
	}

	/** 英文字を全角から半角にします */
	public toNarrowAlphabet(s: string): string {
		return s.replace(/[Ａ-Ｚａ-ｚ]/g, this.unShiftCharCode);
	}

	/** カタカナを全角から半角にします */
	public toNarrowKana(s: string): string {
		const regexp = new RegExp(`(${Array.from(kanaW2NMap.keys()).join("|")})`, "g");
		return s.replace(regexp, (v) => kanaW2NMap.get(v) || v);
	}

	/**
	 * 一般的な記号を全角から半角にします
	 *
	 * 日本語の句読点は変換対象に含みません
	 */
	public toNarrowSymbol(s: string): string {
		s = s.replace(/[！-／：-＠［-｀｛-～]/g, this.unShiftCharCode);
		const regexp = new RegExp(`(${Array.from(symbolW2NMap.keys()).join("|")})`, "g");
		return s.replace(regexp, (v) => symbolW2NMap.get(v) || v);
	}
	//#endregion

	//#region 半 → 全
	/** 対応文字を半角から全角にします */
	public toWide(s: string): string {
		s = this.toWideWhitespace(s);
		s = this.toWideNumeric(s);
		s = this.toWideAlphabet(s);
		s = this.toWideKana(s);
		s = this.toWideSymbol(s);
		return s;
	}

	/** スペースを半角から全角にします */
	public toWideWhitespace(s: string): string {
		return s.replace(/ /g, "　");
	}

	/** 数字を半角から全角にします */
	public toWideNumeric(s: string): string {
		return s.replace(/[0-9]/g, this.shiftCharCode);
	}

	/** 英文字を半角から全角にします */
	public toWideAlphabet(s: string): string {
		return s.replace(/[A-Za-z]/g, this.shiftCharCode);
	}

	/** カタカナを半角から全角にします */
	public toWideKana(s: string): string {
		const regexp = new RegExp(`(${Array.from(kanaN2WMap.keys()).join("|")})`, "g");
		return s.replace(regexp, (v) => kanaN2WMap.get(v) || v);
	}

	/**
	 * 一般的な記号を半角から全角にします
	 *
	 * 日本語の句読点は変換対象に含みません
	 */
	public toWideSymbol(s: string): string {
		s = s.replace(/[!-/:-@[-`{-~]/g, this.shiftCharCode);
		const regexp = new RegExp(`(${Array.from(symbolN2WMap.keys()).join("|")})`, "g");
		return s.replace(regexp, (v) => symbolN2WMap.get(v) || v);
	}
	//#endregion

	/** 文字コードを 0xFEE0 プラス方向にシフトします */
	private shiftCharCode(v: string) {
		return String.fromCharCode(v.charCodeAt(0) + 0xfee0);
	}

	/** 文字コードを 0xFEE0 マイナス方向にシフトします */
	private unShiftCharCode(v: string) {
		return String.fromCharCode(v.charCodeAt(0) - 0xfee0);
	}

	/** ひらがなをカタカナにします */
	public hiraToKana(s: string): string {
		const regexp = new RegExp(`(${Array.from(hiraToKanaMap.keys()).join("|")})`, "g");
		return s.replace(regexp, (v) => hiraToKanaMap.get(v) || v);
	}
}
