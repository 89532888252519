import { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { Box, CircularProgress, Paper, ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";

import { DocActionType } from "../constants/DocConst";
import { DocActionHandler, AttachmentFileHandler } from "../pages/guest/GuestDocumentView";
import { FieldValueHandler, SetDocumentModeHandler } from "../pages/guest/GuestDocumentView";
import { DocMode } from "../typings/DocTypes";
import { Fields } from "../typings/FieldTypes";
import { FieldValueDictionary } from "../typings/FieldValueTypes";
import { LoadingStatus } from "../typings/GeneralTypes";

import ActionButton from "./ActionButton";
import ActionCard from "./ActionCard";
import ActionMessageCard from "./ActionMessageCard";
import DocumentSentModal from "./DocumentSentModal";
import FieldBase from "./FieldBase";
import fieldTheme from "./fields/FieldTheme";

/**
 * フィールド ID から DOM のエレメントを探索し返します
 */
function findFieldElement(field_id: string, table_row?: number): Element | null {
	if (table_row !== undefined) {
		return document.querySelector(`div[data-parts-id="${field_id}"][data-row="${table_row}"]`);
	}

	return document.querySelector(`div[data-parts-id="${field_id}"]`);
}

type Props = {
	mode: DocMode;
	layoutHtml: string;
	fields: Fields;
	values: FieldValueDictionary;
	onAction: DocActionHandler;
	onChange: FieldValueHandler;
	onAttach: AttachmentFileHandler;
	setMode: SetDocumentModeHandler;
	sending: LoadingStatus;
	fieldsLoading: LoadingStatus;
	isSomeValueInvalid: boolean;
};

const GuestLayoutHTMLContent: React.VFC<Props> = ({
	mode,
	layoutHtml,
	fields,
	values,
	onAction,
	onChange,
	onAttach,
	setMode,
	sending,
	fieldsLoading,
	isSomeValueInvalid,
}) => {
	const divRef = useRef<HTMLDivElement>(null);
	const [ready, setReady] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleConfirm = () => {
		// TODO: 確認ボタンを押したとき、ユーザーに確認させるためのスクロールや表示、必須項目の指摘など追加の挙動が必要。
		setMode(DocMode.Confirm);
	};

	// レイアウト HTML を DOM に配置する
	useEffect(() => {
		if (divRef.current) {
			setReady(false);
			divRef.current.innerHTML = layoutHtml;
		}
	}, [layoutHtml]);

	// DOM にフィールドを配置する
	useEffect(() => {
		if (fieldsLoading === LoadingStatus.Loaded) {
			for (const [key, field] of Object.entries(fields)) {
				if (!field.field_id) continue;

				const target = findFieldElement(field.field_id, field.table_row);
				if (target) {
					const entity = values[key];
					const { value, valueMap, editable, is_valid } = entity || {};
					// FIXME: 未保存の値の editable は true として暫定処理
					const props = {
						field_type: field.field_type,
						field,
						value,
						valueMap,
						editable: mode === DocMode.Edit && editable !== false,
						failed: is_valid === false,
						onChange,
						onAttach,
						disableLink: true,
					};

					// FIXME: テーマを渡さないと適用されないため暫定処理
					const element = (
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={fieldTheme}>
								<FieldBase {...props} />
							</ThemeProvider>
						</StyledEngineProvider>
					);
					ReactDOM.render(element, target);
				}
			}
			setReady(true);
		}
	}, [fieldsLoading, mode, fields, values, onChange, onAttach]);

	useEffect(() => {
		if (mode === DocMode.Sent) {
			setIsModalOpen(true);
		}
	}, [mode]);

	// FIXME: 無効値の場合、編集モードを戻す。正攻法ではない
	useEffect(() => {
		if (mode === DocMode.Confirm && isSomeValueInvalid) {
			setMode(DocMode.Edit);
		}
	}, [setMode, mode, isSomeValueInvalid]);

	const actionArea = (): ReactNode => {
		switch (mode) {
			case DocMode.Edit:
				return (
					<ActionCard>
						<ActionButton
							variant="contained"
							color="primary"
							disabled={isSomeValueInvalid}
							onClick={handleConfirm}
							endIcon={<ArrowForwardIcon color="inherit" />}
						>
							確認する
						</ActionButton>
					</ActionCard>
				);
			case DocMode.Confirm:
				return (
					<ActionCard>
						<ActionButton
							variant="outlined"
							color="primary"
							disabled={sending === LoadingStatus.Pending}
							onClick={() => setMode(DocMode.Edit)}
							endIcon={<EditIcon color="inherit" />}
						>
							修正する
						</ActionButton>
						<ActionButton
							variant="contained"
							color="primary"
							disabled={isSomeValueInvalid || sending === LoadingStatus.Pending}
							onClick={() => onAction({ type: DocActionType.Create })}
							loading={sending === LoadingStatus.Pending}
							loadingIcon={<CircularProgress size={22} color="inherit" />}
							endIcon={<SendIcon color="inherit" />}
						>
							送信する
						</ActionButton>
					</ActionCard>
				);
			case DocMode.Sent:
				return (
					<ActionMessageCard>
						<Typography variant="h5" component="p" color="primary" data-testid="guest-layout-html-content-action-sent-message">
							<b>送信しました</b>
						</Typography>
					</ActionMessageCard>
				);
		}

		return <></>;
	};

	return (
		<Box display="flex" data-testid="guest-layout-html-content-root">
			<Box display={ready ? undefined : "none"} flexShrink={0} p={3}>
				<Paper variant="outlined">
					<Box p={4}>
						<Box id="document" ref={divRef} data-testid="guest-layout-html-content-div-ref" />
					</Box>
				</Paper>
				{ready ? <Box>{actionArea()}</Box> : <></>}
			</Box>
			<DocumentSentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</Box>
	);
};

export default GuestLayoutHTMLContent;
