import React from "react";

import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";

import theme from "./Theme";

type Props = {
	message: string;
	hint?: string;
};

const NavigationMessage: React.VFC<Props> = ({ message, hint }) => {
	return (
		<Grid container justifyContent="center" data-testid="navigation-message-root">
			<Grid item xs={8}>
				<Box pt={4}>
					<Paper variant="outlined">
						<Box my={4}>
							<Box display="flex" alignItems="center" flexDirection="column">
								<Avatar sx={{ margin: theme.spacing(1), backgroundColor: theme.palette.primary.main }}>
									<AnnouncementIcon />
								</Avatar>
							</Box>
							<Box mt={1}>
								<Typography
									variant="body1"
									align="center"
									color="textSecondary"
									component="p"
									whiteSpace="pre-line"
									data-testid="navigation-message-msg"
								>
									{message}
								</Typography>
								<Box mt={0.5}>
									<Typography
										variant="body2"
										align="center"
										color="textSecondary"
										component="p"
										whiteSpace="pre-line"
										data-testid="navigation-message-hint"
									>
										{hint}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Paper>
				</Box>
			</Grid>
		</Grid>
	);
};

export default NavigationMessage;
