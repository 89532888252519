import { VFC } from "react";

import { Box, BoxProps, Grid } from "@mui/material";

const CenterTwoThirdsBox: VFC<BoxProps> = ({ children, ...props }) => {
	return (
		<Grid container justifyContent="center" data-testid="center-two-thirds-box-root">
			<Grid item xs={8}>
				<Box pt={4} {...props}>
					{children}
				</Box>
			</Grid>
		</Grid>
	);
};

export default CenterTwoThirdsBox;
