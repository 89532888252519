import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import { Box, Grid, Typography } from "@mui/material";

import { getLogoUrl } from "../../apis/workspace/logo/getLogoUrl";
import MainAppBar from "../../components/MainAppBar";
import SettingCardsSkeleton from "../../components/SettingCardsSkeleton";
import ToastContent from "../../components/ToastContent";
import WorkspaceAdminDrawer from "../../components/WorkspaceAdminDrawer";
import WorkspaceLogoUpdateCard from "../../components/WorkspaceLogoUpdateForm";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import SimpleTemplate from "../../templates/SimpleTemplate";
import { LoadingStatus } from "../../typings/GeneralTypes";

const AdminCustomize: React.VFC = () => {
	const workspace = useSelector(selectWorkspace);
	const { getAccessTokenSilently } = useAuth0();

	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const [logoUrl, setLogoUrl] = useState<string>("");
	const getWorkspaceLogoUrl = useCallback(async () => {
		setLoading(LoadingStatus.Pending);

		const auth_token = await getAccessTokenSilently();
		await getLogoUrl({ workspace_id: workspace.id, auth_token })
			.then((res) => {
				res && setLogoUrl(res);
				setLoading(LoadingStatus.Fulfilled);
			})
			.catch((reason) => {
				console.error(reason);
				setLoading(LoadingStatus.Error);
				toast(<ToastContent subject="ロゴの取得に失敗しました" message={reason.message} />, { type: toast.TYPE.ERROR, autoClose: 5000 });
			});
	}, [getAccessTokenSilently, workspace.id]);

	useEffect(() => {
		if (workspace.logo_enabled) {
			getWorkspaceLogoUrl();
		} else {
			setLoading(LoadingStatus.Fulfilled);
		}
	}, [getWorkspaceLogoUrl, workspace, workspace.logo_enabled]);

	return (
		<SimpleTemplate title="基本設定" appBar={<MainAppBar />} drawer={<WorkspaceAdminDrawer />}>
			<Grid container spacing={0} justifyContent="center" data-testid="workspace-admin-customize-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							カスタマイズ
						</Typography>
					</Box>
					<Box pt={1}>
						{workspace.id && loading === LoadingStatus.Fulfilled ? (
							<WorkspaceLogoUpdateCard workspace={workspace} logo_url={logoUrl} />
						) : (
							<SettingCardsSkeleton />
						)}
					</Box>
				</Grid>
			</Grid>
		</SimpleTemplate>
	);
};

export default AdminCustomize;
