import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { validate as uuidValidate } from "uuid";

import { Box, Grid, Typography } from "@mui/material";

import { listAuthentications } from "../../apis/authentications/list";
import { getServiceLink } from "../../apis/servicelink/get";
import FormServiceLinkUpdateForm from "../../components/FormServiceLinkUpdateForm";
import FormSettingsDrawer from "../../components/FormSettingsDrawer";
import NavigationMessage from "../../components/NavigationMessage";
import SettingCardsSkeleton from "../../components/SettingCardsSkeleton";
import ToastContent from "../../components/ToastContent";
import { createFormByIdSelector } from "../../store/FormListSlice";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";
import { AuthAndServiceLink, OstensibleAuthentication } from "../../typings/AuthenticationTypes";
import { LoadingStatus } from "../../typings/GeneralTypes";

const FormServiceLink: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const { getAccessTokenSilently } = useAuth0();

	const [authentications, setAuthentications] = useState<OstensibleAuthentication[] | null>();
	const [authAndServiceLink, setAuthAndServiceLink] = useState<AuthAndServiceLink>();
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const form_id = match.params.form_id;
	const form = useSelector(createFormByIdSelector(form_id));
	const { id: workspace_id } = useSelector(selectWorkspace);

	const fetchAuthentications = useCallback(async () => {
		const auth_token = await getAccessTokenSilently();
		const res = await listAuthentications({ workspace_id, auth_token });

		const authentications = res.map((auth) => {
			return {
				id: auth.id,
				name: auth.name,
				provider: auth.provider,
			};
		});
		authentications.sort(function (a, b) {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		});
		setAuthentications(authentications);
	}, [getAccessTokenSilently, workspace_id]);

	useEffect(() => {
		workspace_id && fetchAuthentications();
	}, [fetchAuthentications, workspace_id]);

	const fetchServiceLink = useCallback(async () => {
		setLoading(LoadingStatus.Pending);

		const auth_token = await getAccessTokenSilently();
		await getServiceLink(form_id, { workspace_id, auth_token })
			.then((res) => {
				if (res) {
					setAuthAndServiceLink({
						authentication: {
							id: res.connection.id,
						},
						servicelink: {
							app_cd: res.config.app_cd,
							processes_id: res.config.processes_id,
						},
					});
				}
				setLoading(LoadingStatus.Fulfilled);
			})
			.catch((reason) => {
				console.error(reason);
				setLoading(LoadingStatus.Error);
				toast(<ToastContent subject="接続の取得に失敗しました" message={reason.message} />, { type: toast.TYPE.ERROR, autoClose: 5000 });
			});
	}, [form_id, getAccessTokenSilently, workspace_id]);

	useEffect(() => {
		workspace_id && form_id && fetchServiceLink();
	}, [fetchServiceLink, form_id, workspace_id]);

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate pageName="フォーム">
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	const loaded = loading === LoadingStatus.Fulfilled;

	return (
		<MainTemplate pageName="フォーム設定" drawer={<FormSettingsDrawer form_id={form_id} />} isLoading={!loaded}>
			<Grid container spacing={0} justifyContent="center" data-testid="form-servicelink-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							フォーム設定
						</Typography>
					</Box>
					<Box pt={1}>
						{authentications && loading === LoadingStatus.Fulfilled ? (
							<FormServiceLinkUpdateForm
								form_id={form_id}
								authAndServiceLink={authAndServiceLink}
								authentications={authentications}
								is_archive={form?.is_archive}
							/>
						) : (
							<SettingCardsSkeleton />
						)}
					</Box>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default FormServiceLink;
