import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

export type PostAuthenticationRequest = {
	provider: string;
	name: string;
	config: {
		endpoint: string;
		user_id: string;
		api_key: string;
		basic_user?: string;
		basic_password?: string;
	};
};

export type PostAuthenticationResponse = {
	id: string;
	provider: string;
	name: string;
};

/**
 * 認証情報を保存します
 *
 * @param options - API オプション
 */
export const postAuthentication = async (reqData: PostAuthenticationRequest, options: ApiOptions): Promise<PostAuthenticationResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	// FIXME: エンドポイント名称変更
	const { data } = await axios.post("/connections", reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
