import { VFC } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, CardProps, DialogActions, TextField, Typography } from "@mui/material";

import { FormSecretControl } from "../hooks/useFormSecret";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { LoadingStatus } from "../typings/GeneralTypes";
import { StringConverter } from "../utils/StringConverter";

import Form from "./Form";
import ToastContent from "./ToastContent";

type FormState = {
	password: string;
};

type Props = CardProps & {
	form_id: string;
	formSecretControl: FormSecretControl;
	onClose: () => void;
};

const FormPasswordSettingCard: VFC<Props> = ({ form_id, onClose, formSecretControl, ...args }) => {
	const { id: workspace_id } = useSelector(selectWorkspace);

	const { errors, formState, getValues, handleSubmit, register, setValue } = useForm<FormState>({
		mode: "onBlur",
		reValidateMode: "onBlur",
		defaultValues: {
			password: "",
		},
	});

	const { isValid, dirtyFields, isDirty } = formState;

	const { registerFormSecret, formSecretState } = formSecretControl;
	const { registering } = formSecretState;

	const onSubmit = handleSubmit(async (data) => {
		if (!isDirty) return;

		const password = data.password;

		await registerFormSecret({ form_id, password, workspace_id })
			.then(() => {
				onClose();
			})
			.catch((reason) => {
				console.error(reason);
				toast(<ToastContent subject="パスワード制限に失敗しました" message={reason?.message} />, {
					type: toast.TYPE.ERROR,
					autoClose: false,
				});
			});
	});

	const onBlur = () => {};

	const pending = registering === LoadingStatus.Pending;

	return (
		<Card data-testid="form-password-setting-card-root" {...args}>
			<CardHeader title="パスワードを設定する" />
			<Form onSubmit={onSubmit} onBlur={onBlur} width={380} maxWidth="100%">
				<CardContent>
					<Box>
						<Typography variant="body1">半角の英数字記号が使用できます</Typography>
					</Box>
					<Box mt={2}>
						<TextField
							fullWidth
							id="password"
							name="password"
							label="パスワード"
							variant="outlined"
							autoComplete="off"
							helperText={errors.password?.message}
							InputLabelProps={{
								required: true,
							}}
							inputRef={register({
								required: { value: true, message: "パスワードは必須です" },
								pattern: { value: /^[!-~]+$/, message: "半角の英字、数字、記号のみにしてください" },
								maxLength: { value: 70, message: "70 文字以下にしてください" },
							})}
							error={errors.password ? true : false}
							onBlur={() => {
								setValue("password", new StringConverter().trim(getValues("password")));
							}}
							data-testid="form-password-setting-card-password-input"
						/>
					</Box>
				</CardContent>
				<DialogActions>
					<Button color="primary" onClick={onClose} disabled={pending} tabIndex={-1} data-testid="form-password-setting-card-cancel-btn">
						キャンセル
					</Button>
					<LoadingButton
						type="submit"
						variant="contained"
						color="primary"
						disabled={pending || !isValid || !Object.keys(dirtyFields).length}
						loading={pending}
						startIcon={<LockIcon fontSize="inherit" style={{ marginBottom: 1 }} />}
						data-testid="form-password-setting-card-submit-btn"
					>
						パスワード制限する
					</LoadingButton>
				</DialogActions>
			</Form>
		</Card>
	);
};

export default FormPasswordSettingCard;
