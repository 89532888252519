import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { validate as uuidValidate } from "uuid";

import { Box } from "@mui/material";

import { postDocRetryAction } from "../../apis/docs/action/retry";
import { getAttachmentUrl } from "../../apis/docs/getAttachmentUrl";
import { getLayoutData } from "../../apis/layouts/get";
import DocumentViewDrawer from "../../components/DocumentViewDrawer";
import LayoutHTMLContent from "../../components/LayoutHTMLContent";
import NavigationMessage from "../../components/NavigationMessage";
import ToastContent from "../../components/ToastContent";
import { DocActionType } from "../../constants/DocConst";
import {
	// clear as clearDoc,
	docValueSelectors,
	fetchDocumentById,
	selectDocError,
	selectDocInfo,
	selectDocLoading,
	selectDocMode,
	setMode,
} from "../../store/DocSlice";
import { fetchFieldsById, selectLayoutError, selectFields, selectFieldsLoading } from "../../store/LayoutSlice";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import updateDoc from "../../store/actions/UpdateDoc";
import { AppDispatch } from "../../store/store";
import MainTemplate from "../../templates/MainTemplate";
import { DocMode } from "../../typings/DocTypes";
import { FieldValue, FieldValueEvent } from "../../typings/FieldValueTypes";
import { LoadingStatus } from "../../typings/GeneralTypes";

//#region Types
/** フィールド値 (Primitives/Instances) を扱うイベントハンドラー */
export type FieldValueHandler = (entity: FieldValueEvent) => Promise<void>;

/** ドキュメントモードを変更するためのイベントハンドラー */
export type SetDocumentModeHandler = (mode: DocMode) => void;

export type AttachmentLinkCreateHandler = (params: { field_id: string; file_path: string }) => Promise<{ url: string } | undefined>;
//#endregion

const DocumentView: React.VFC<RouteComponentProps<{ document_id: string }>> = ({ match }) => {
	const doc_id = match.params.document_id;

	const { getAccessTokenSilently } = useAuth0();
	const dispatch: AppDispatch = useDispatch();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const doc = useSelector(selectDocInfo);
	const docLoading = useSelector(selectDocLoading);
	const docError = useSelector(selectDocError);
	// const layout = useSelector(selectLayoutInfo); // TODO: レイアウトは現状 ドキュメントから解決する
	const fields = useSelector(selectFields);
	const fieldsLoading = useSelector(selectFieldsLoading);
	const fieldsError = useSelector(selectLayoutError);
	const docMode = useSelector(selectDocMode);
	const values = useSelector(docValueSelectors.selectEntities);

	const [layoutHtml, setLayoutHtml] = useState<string>("");
	const [layoutLoading, setLayoutLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [isSomeValueInvalid, setIsSomeValueInvalid] = useState<boolean>(true);

	const docIdentified = doc_id === doc.id;

	/** ドキュメントモードを変更するハンドラー */
	const handleSetMode: SetDocumentModeHandler = (mode: DocMode) => {
		dispatch(setMode(mode));
	};

	//#region ユーザー入力値 非同期更新
	const onChange: FieldValueHandler = async (_entity: FieldValueEvent): Promise<void> => {
		// TODO: TEXPA-1484 一時的に機能をオミット
		// if (docMode === DocMode.Edit) {
		// 	dispatch(updateDoc({ content: { doc: { id: doc_id }, values: [entity] } }));
		// 	dispatch(syncDoc({ apiOptions: { workspace_id, auth_token: await getAccessTokenSilently() } }));
		// }	);
	};
	//#endregion

	const onCreateLink: AttachmentLinkCreateHandler = async (params) => {
		const { file_path, field_id } = params || {};

		if (!file_path || !doc_id || !field_id || !workspace_id) return;

		const auth_token = await getAccessTokenSilently();
		const url = await getAttachmentUrl({ doc_id, field_id, file_path }, { workspace_id, auth_token });
		return { url };
	};

	//#region 無効値のトースト通知
	useEffect(() => {
		if (!docIdentified) return;

		setIsSomeValueInvalid(false);
		for (const key in values) {
			const { id, is_valid, validation_message } = values[key] as FieldValue;
			const toastId = `InvalidFieldValue:${id}`;
			if (toast.isActive(toastId)) {
				!is_valid ? toast.update(toastId, { render: validation_message }) : toast.dismiss(toastId);
			} else {
				!is_valid && toast(validation_message, { toastId, type: toast.TYPE.ERROR, autoClose: false });
			}

			if (!is_valid) {
				setIsSomeValueInvalid(true);
			}
		}
	}, [docIdentified, values]);
	//#endregion

	// FIXME: 削除しても効果がないためコメントアウト
	// //#region ステート掃除
	// useEffect(() => {
	// 	setLayoutHtml("");
	// 	setLayoutLoading(LoadingStatus.Initial);
	// 	dispatch(clearDoc(undefined));
	// }, [dispatch]);
	// //#endregion

	//#region ドキュメント取得
	useEffect(() => {
		if (doc_id && workspace_id) {
			(async () => {
				const auth_token = await getAccessTokenSilently();
				dispatch(fetchDocumentById({ doc_id, options: { workspace_id, auth_token } }));
			})();
		}
	}, [doc_id, dispatch, getAccessTokenSilently, workspace_id]);
	//#endregion

	//#region デザイン取得
	useEffect(() => {
		if (workspace_id && docIdentified && docLoading === LoadingStatus.Loaded && doc.layout?.id && doc?.workspace_id) {
			setLayoutLoading(LoadingStatus.Pending);
			(async (layout_id: string) => {
				const auth_token = await getAccessTokenSilently();
				const rawHtml = await getLayoutData(layout_id, { workspace_id, auth_token });
				setLayoutHtml(rawHtml);
				await dispatch(fetchFieldsById({ layout_id, options: { workspace_id, auth_token } }));
				setLayoutLoading(LoadingStatus.Loaded);
			})(doc.layout.id);
		}
	}, [doc, docIdentified, docLoading, dispatch, getAccessTokenSilently, workspace_id]);
	//#endregion

	useEffect(() => {
		if (docMode === DocMode.Confirm) {
			for (const [id, field] of Object.entries(fields)) {
				if (field.required) {
					const value = values[id]?.value ?? null;
					dispatch(updateDoc({ content: { values: [{ id, value }] } }));
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docMode, fields]);

	const [isResendDocumentLoaded, setIsResendDocumentLoaded] = useState<LoadingStatus>(LoadingStatus.Initial);
	const onResendDocument = async (): Promise<void> => {
		setIsResendDocumentLoaded(LoadingStatus.Pending);
		const auth_token = await getAccessTokenSilently();
		await postDocRetryAction(doc_id, { action: { type: DocActionType.Create } }, { auth_token, workspace_id, encodedFormPassword: "" })
			.then(() => {
				toast(<ToastContent subject={"ドキュメントの再送信を受け付けました"} />, {
					type: toast.TYPE.SUCCESS,
				});
				setIsResendDocumentLoaded(LoadingStatus.Fulfilled);
			})
			.catch((reason) => {
				toast(<ToastContent subject={"ドキュメントの送信に失敗しました"} message={reason?.message} />, {
					type: toast.TYPE.ERROR,
					autoClose: false,
				});
				setIsResendDocumentLoaded(LoadingStatus.Fulfilled);
			});
	};

	// 読み込み状況
	const status = [docLoading, layoutLoading, fieldsLoading];
	const loading: boolean = status.some((s) => s === LoadingStatus.Pending);
	const loaded: boolean = status.every((s) => s === LoadingStatus.Loaded) && docIdentified;
	const errored: boolean = status.some((s) => s === LoadingStatus.Error);

	//
	// 以下レンダリング
	//

	if (!uuidValidate(doc_id)) {
		return (
			<MainTemplate drawer={<DocumentViewDrawer form_id={doc.form?.id} />}>
				<Box data-testid="document-view-invalid-doc-id">
					<NavigationMessage message="正しい形式の ドキュメント ID ではないようです" hint="フォームページからもう一度試してみてください" />
				</Box>
			</MainTemplate>
		);
	}

	// FIXME: エラーメッセージを適切に表示する
	if (errored) {
		const hint = docError?.message || fieldsError?.message;
		return (
			<MainTemplate drawer={<DocumentViewDrawer form_id={doc.form?.id} />}>
				<Box data-testid="document-view-doc-loading-failed">
					<NavigationMessage message="ドキュメントの読み込みに失敗しました" hint={hint} />
				</Box>
			</MainTemplate>
		);
	}

	return (
		<MainTemplate
			pageName={doc?.subject}
			isLoading={loading}
			drawer={
				<DocumentViewDrawer
					form_id={doc.form?.id}
					step={doc.working_step}
					values={values}
					onResendDocument={onResendDocument}
					isResendDocumentLoaded={isResendDocumentLoaded}
					docDate={{ created_at: doc.created_at, updated_at: doc.updated_at }}
				/>
			}
			disableContainer
		>
			<Box data-testid="document-view-root" display="flex" justifyContent="center">
				{loaded && (
					<LayoutHTMLContent
						mode={DocMode.Read}
						layoutHtml={layoutHtml}
						fields={fields}
						values={values}
						onChange={onChange}
						onCreateLink={onCreateLink}
						setMode={handleSetMode}
						isSomeValueInvalid={isSomeValueInvalid}
					/>
				)}
			</Box>
		</MainTemplate>
	);
};

export default DocumentView;
