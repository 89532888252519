import { createAsyncThunk, createSlice, SerializedError, SliceCaseReducers } from "@reduxjs/toolkit";

import { listFields } from "../apis/layouts/fields/list";
import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import { FieldEntity, Fields, TableMeta } from "../typings/FieldTypes";
import { LoadingStatus } from "../typings/GeneralTypes";
import { LayoutEntity } from "../typings/LayoutTypes";

import { State } from "./Reducers";

export type LayoutState = {
	loading: LoadingStatus;
	info: LayoutEntity;
	fields: Fields;
	error?: Error | SerializedError;
};

/**
 * テーブルフィールドをフロントの形式に互換させます
 *
 * バックエンドから取得したフィールドの抽象的なテーブル部分をフラットに展開し、すべてのフィールドを返します
 *
 * Ex.
 * フィールド ID が `Table:1:fidName` で行数が `3` の場合、
 * フィールド ID を `Table:1:fidName:1` `Table:1:fidName:2` `Table:1:fidName:3` として複写する
 *
 * @param fields
 * @returns
 */
const adaptTableCompatibly = (fields: Fields): Fields => {
	const resultFields: Fields = {};

	const tables: { [table_id: string]: TableMeta } = {};

	const tableFields: FieldEntity[] = [];

	// 分類
	for (const key in fields) {
		const field = fields[key];

		// テーブルではないフィールドはなにもせず追加
		if (!field.table_id) {
			resultFields[key] = field;
		}
		// テーブルのメタ情報を一時抽出
		if (field.field_type === "table") {
			const table = field as TableMeta;
			if (table.table_id) {
				tables[table.table_id] = field as TableMeta;
			}
		}
		// テーブルフィールドを一時抽出
		if (field.table_id && field.field_id) {
			tableFields.push(field);
		}
	}

	// テーブルフィールドを再構築し追加
	for (const field of tableFields) {
		if (!field.table_id) break;

		const max_row = tables[field.table_id].max_row;

		if (!max_row) break;

		// TODO: max_row が Html とかけ離れている場合、存在しないフィールドへの考慮
		for (let row = 1; row <= max_row; row++) {
			const fieldKey = field.id + ":" + row;

			resultFields[fieldKey] = { ...field, id: fieldKey, table_row: row };
		}
	}

	return resultFields;
};

const fetchFieldsById = createAsyncThunk("layout/fetchFieldsById", async (arg: { layout_id: string; options: ApiOptions; form_id?: string }) => {
	return listFields(arg.layout_id, arg.options, arg.form_id).then((data) => {
		// FIXME: 最後に表示されているレイアウト ID が必要なため暫定処置
		return { ...data, layout: { id: arg.layout_id } };
	});
});

const slice = createSlice<LayoutState, SliceCaseReducers<LayoutState>>({
	name: "layout",
	initialState: {
		loading: "initial",
		info: { layout_id: "" },
		fields: {},
	},
	reducers: {
		clear: () => {
			return {
				loading: "initial",
				info: { layout_id: "" },
				fields: {},
			};
		},
	},
	extraReducers: (builder) => {
		//#region レイアウト非同期取得
		// 取得中
		builder.addCase(fetchFieldsById.pending, (state, _action) => {
			state.error = undefined;
			state.loading = "pending";
			state.fields = {};
		});

		// 取得成功
		builder.addCase(fetchFieldsById.fulfilled, (state, action) => {
			// FIXME: 最後に表示されているレイアウト ID が必要なため暫定処置
			state.info.layout_id = action.payload.layout?.id;
			//state.info.layout_type = action.payload.layout.type;

			state.fields = adaptTableCompatibly(action.payload.fields);
			state.loading = "loaded";
		});

		// 処理例外、通信エラーなど
		builder.addCase(fetchFieldsById.rejected, (state, action) => {
			state.loading = "error";
			state.error = action.error;
			console.error("フィールドの読み込みに失敗", action.error);
		});
		//#endregion
	},
});

// アクションたち
export const { clear } = slice.actions;
export { fetchFieldsById };

// useSelector 用
export const selectFields = (state: State) => state.layout.fields;
export const selectFieldsLoading = (state: State) => state.layout.loading;
export const selectLayoutInfo = (state: State) => state.layout.info;
export const selectLayoutError = (state: State) => state.layout.error;

export default slice;
