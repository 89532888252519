import { VFC } from "react";
import { Link as RouterLink } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import SettingsIcon from "@mui/icons-material/Settings";
import { Divider, Link, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import StyledDrawer from "./StyledDrawer";

type Props = {
	form_id: string;
	not_completed?: boolean;
};

const FormSettingsDrawer: VFC<Props> = ({ form_id, not_completed }) => {
	return (
		<StyledDrawer variant="permanent" data-testid="form-settings-drawer-root">
			<List>
				<Link component={RouterLink} to={`/forms/${form_id}`} data-testid="form-settings-drawer-back-link">
					<ListItemButton>
						<ListItemIcon>
							<ArrowBackIosIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="フォームに戻る" />
					</ListItemButton>
				</Link>
			</List>
			<Divider />
			<List>
				{not_completed ? (
					<Link component={RouterLink} to={`/forms/${form_id}/wizard`} data-testid="form-settings-drawer-wizard-link">
						<ListItemButton>
							<ListItemIcon>
								<GraphicEqIcon color="primary" />
							</ListItemIcon>
							<ListItemText primary="初期セットアップ" />
						</ListItemButton>
					</Link>
				) : (
					<>
						<Link component={RouterLink} to={`/forms/${form_id}/settings`} data-testid="form-settings-drawer-basic-link">
							<ListItemButton>
								<ListItemIcon>
									<SettingsIcon color="primary" />
								</ListItemIcon>
								<ListItemText primary="基本設定" />
							</ListItemButton>
						</Link>
						<Link component={RouterLink} to={`/forms/${form_id}/connect`} data-testid="form-settings-drawer-connect-link">
							<ListItemButton>
								<ListItemIcon>
									<SettingsIcon color="primary" />
								</ListItemIcon>
								<ListItemText primary="接続" />
							</ListItemButton>
						</Link>
						<Link component={RouterLink} to={`/forms/${form_id}/import`} data-testid="form-settings-drawer-import-link">
							<ListItemButton>
								<ListItemIcon>
									<SettingsIcon color="primary" />
								</ListItemIcon>
								<ListItemText primary="デザイン取り込み" />
							</ListItemButton>
						</Link>
						<Link component={RouterLink} to={`/forms/${form_id}/access`} data-testid="form-settings-drawer-access-link">
							<ListItemButton>
								<ListItemIcon>
									<SettingsIcon color="primary" />
								</ListItemIcon>
								<ListItemText primary="公開アドレス" />
							</ListItemButton>
						</Link>
					</>
				)}
				<Link component={RouterLink} to={`/forms/${form_id}/maintenance`} data-testid="form-settings-drawer-maintenance-link">
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="メンテナンス" />
					</ListItemButton>
				</Link>
			</List>
		</StyledDrawer>
	);
};

export default FormSettingsDrawer;
