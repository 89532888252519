import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchDocument, PatchDocumentResponse } from "../../apis/docs/patch";
import { ApiOptions } from "../../apis/misc/generateAxiosRequestConfig";
import { JSONFieldValueTypeMap } from "../../constants/FieldConst";
import { FieldValue, JSONFieldValue, JSONFieldValues } from "../../typings/FieldValueTypes";
import { State } from "../Reducers";

type SyncDocContent = {
	doc: PatchDocumentResponse;
	/** スライスを更新するための値の配列 */
	updatedValues: FieldValue[];
};

type SyncDocPayload = {
	apiOptions: ApiOptions;
};

// FIXME: !!! 再開発時、添付ファイルフィールドの値は一部送らないので気をつけること !!!
// FIXME: ドキュメントの件名などの同期
/**
 * スライスに保存されたドキュメントをバックエンドに同期します
 */
const syncDoc = createAsyncThunk<SyncDocContent | undefined, SyncDocPayload, { state: State }>("doc/syncDoc", async ({ apiOptions }, thunkApi) => {
	const state = thunkApi.getState();
	const doc = state.doc.info;
	const values = state.doc.values.entities;
	const fields = state.layout.fields;

	// 同期する値と、更新に使用する値を生成
	if (doc.id && doc.rev !== undefined) {
		/** 同期する値のコレクション */
		const jsonValues: JSONFieldValues = {};

		/** スライスを更新するための値の配列 */
		const updatedValues: FieldValue[] = [];

		for (const field_id in values) {
			const entity = values[field_id];
			if (!entity) continue;

			const { value, is_dirty, is_valid } = entity;
			if (is_dirty !== true || is_valid === false) continue;

			// NOTE: JSON 形式の値辞書を生成しつつ、fulfilled 用の is_dirty フラグをオフにしておく。entity への値の直代入は read-only のため不可
			let jsonValue: JSONFieldValue | undefined = undefined;
			if (value === null) jsonValue = { E: true };
			else {
				const { field_type } = fields[field_id];
				// NOTE: 通信時の形式に変換します フィールド追加時は JSONFieldValueTypeMap に定義を追加してください
				const jsonValueType = JSONFieldValueTypeMap[field_type];
				if (jsonValueType) jsonValue = { [jsonValueType]: value };
			}

			if (jsonValue) {
				jsonValues[field_id] = jsonValue;
				updatedValues.push({ ...entity, is_dirty: false });
			}
		}
		console.debug("SyncDoc::jsonValues?", jsonValues);

		if (!Object.keys(jsonValues).length) return;

		return patchDocument(doc.id, { rev: doc.rev, values: jsonValues }, apiOptions).then((res) => {
			return { doc: res, updatedValues };
		});
	} else {
		console.warn("同期するドキュメントが特定できませんでした");
	}
});

export default syncDoc;
