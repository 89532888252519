/**
 * フィールドタイプ
 */
export const FieldType = {
	Attachment: "attachment",
	Checkbox: "checkbox",
	Date: "date",
	DateTime: "datetime",
	Dropdown: "dropdown",
	Interval: "interval",
	Label: "label",
	Listbox: "listbox",
	MultiLine: "multiline",
	Number: "number",
	RadioButton: "radiobutton",
	Text: "text",
	Time: "time",
	Table: "table",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FieldType = typeof FieldType[keyof typeof FieldType];

/**
 * JSON 形式のフィールド値の型対応マップ
 */
export const JSONFieldValueTypeMap: Record<string, "S" | "N" | "D"> = {
	// NOTE: フィールド追加時に同時に定義してください
	// attachment: undefined,
	checkbox: "S",
	date: "D",
	// datetime: undefined,
	dropdown: "S",
	// interval: undefined,
	// label: undefined,
	// listbox: undefined,
	multiline: "S",
	number: "N",
	// radiobutton: undefined,
	text: "S",
	// time: undefined,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type JSONFieldValueType = typeof JSONFieldValueTypeMap[keyof typeof JSONFieldValueTypeMap];

/**
 * フィールド値が無効な値なときに表示するメッセージタイプ
 */
export const InvalidValueMessageType = {
	Required: "入力が必須です",
	NotNumber: "数値として無効な値です",
	NotDate: "日付として無効な値です",
	OutOfRangeDate: "範囲外の日付です",
	LessThanMinLength: "最小文字数を満たしていません",
	OverMaxLength: "最大文字数を超えています",
	UnderMinValue: "最小値未満です",
	OverMaxValue: "最大値を超えています",
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvalidValueMessageType = typeof InvalidValueMessageType[keyof typeof InvalidValueMessageType];
