import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";

import { validate as uuidValidate } from "uuid";

import { Box, Card, CardHeader, Grid, Typography } from "@mui/material";

import FormSettingsDrawer from "../../components/FormSettingsDrawer";
import FormUpdateForm from "../../components/FormUpdateForm";
import NavigationMessage from "../../components/NavigationMessage";
import SettingCardsSkeleton from "../../components/SettingCardsSkeleton";
import { useFormList } from "../../hooks/useFormList";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";

const FormSettings: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const form_id = match.params.form_id;
	const { fetchForm, createFormByIdSelector } = useFormList();
	const form = useSelector(createFormByIdSelector(form_id));

	const { id: workspace_id } = useSelector(selectWorkspace);

	useEffect(() => {
		workspace_id && form_id && fetchForm(workspace_id, form_id);
	}, [fetchForm, workspace_id, form_id]);

	if (form && !form.layout_id) {
		return <Redirect to={`/forms/${form_id}/wizard`} />;
	}

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate pageName="フォーム">
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	return (
		<MainTemplate pageName="フォーム設定" drawer={<FormSettingsDrawer form_id={form_id} />} isLoading={!form}>
			<Grid container spacing={0} justifyContent="center" data-testid="form-settings-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							フォーム設定
						</Typography>
					</Box>
					<Box pt={1}>
						{form ? (
							<Card variant="outlined">
								<CardHeader title="基本設定" subheader="フォームの基本的な設定" />
								<FormUpdateForm form={form} />
							</Card>
						) : (
							<SettingCardsSkeleton />
						)}
					</Box>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default FormSettings;
