/**
 * 認証情報の定数
 */
export const AuthenticationConst = {
	AuthenticationName: { Name: "認証情報名" },
	Endpoint: { Name: "API エンドポイント" },
	UserId: { Name: "ユーザー ID" },
	ApiKey: { Name: "API キー" },
	BasicUser: { Name: "共通ユーザー名" },
	BasicPassword: { Name: "共通パスワード" },
};
export type AuthenticationConst = typeof AuthenticationConst[keyof typeof AuthenticationConst];
