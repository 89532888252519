import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import { validate as uuidValidate } from "uuid";

import { Box, Typography } from "@mui/material";

import FormArchiveSettingCard from "../../components/FormArchiveSettingCard";
import FormSettingsDrawer from "../../components/FormSettingsDrawer";
import NavigationMessage from "../../components/NavigationMessage";
import SettingCardsSkeleton from "../../components/SettingCardsSkeleton";
import { useFormList } from "../../hooks/useFormList";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";

const FormMaintenance: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const form_id = match.params.form_id;
	const { id: workspace_id } = useSelector(selectWorkspace);
	const { fetchForm, createFormByIdSelector } = useFormList();
	const form = useSelector(createFormByIdSelector(form_id));

	useEffect(() => {
		workspace_id && form_id && fetchForm(workspace_id, form_id);
	}, [fetchForm, workspace_id, form_id]);

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate pageName="フォーム">
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	return (
		<MainTemplate pageName="フォーム設定" drawer={<FormSettingsDrawer form_id={form_id} not_completed={!form?.layout_id} />} isLoading={!form}>
			<Box data-testid="form-settings-maintenance-root">
				<Box height={40} display="flex" alignItems="center" mb={1}>
					<Typography variant="h5" component="h1">
						フォーム設定
					</Typography>
				</Box>
				{form ? (
					<Box>
						{/* <Box><FormMaintenanceCard form={{ id: form_id, is_maintenance: form?.is_maintenance ?? false }} /></Box> */}
						{/* <Box mt={4}><FormArchiveSettingCard form_id={form_id} is_archive={form?.is_archive} /></Box> */}
						<Box>
							<FormArchiveSettingCard form_id={form_id} is_archive={form?.is_archive} />
						</Box>
					</Box>
				) : (
					<SettingCardsSkeleton />
				)}
			</Box>
		</MainTemplate>
	);
};

export default FormMaintenance;
