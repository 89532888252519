import { VFC } from "react";

import { Box, BoxProps } from "@mui/material";

import { AppBarHeight, DrawerWidth } from "../../components/Theme";

const NavBox: VFC<BoxProps> = (props) => {
	return <Box component="nav" marginTop={`${AppBarHeight}px`} width={DrawerWidth} flexShrink={0} background="#414141" {...props} />;
};

export default NavBox;
