import axios from "axios";

import { JSONFieldValue } from "../../typings/FieldValueTypes";
import { WorkingStep } from "../../typings/StepTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

export type GetDocumentRequest = {
	/** ドキュメント ID */
	doc_id: string;
};

export type GetDocumentResponse = {
	/** ドキュメント ID */
	doc_id: string;
	workspace_id: string;
	created_at: Date;
	updated_at: Date;
	rev: number;
	subject: string;
	form: {
		id: string;
		name: string;
	};
	layout: {
		id: string;
	};
	step: {
		id: string;
		name: string;
	};
	working_step?: WorkingStep;

	/** ユーザーの入力値一覧 */
	values: { [key: string]: JSONFieldValue };
};

/**
 * 指定ドキュメントの概要とユーザーの入力値を取得します。
 *
 * @param doc_id - ドキュメント ID
 * @param options - API オプション
 */
export const getDocument = async (doc_id: string, options: ApiOptions): Promise<GetDocumentResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.get<GetDocumentResponse>(`/docs/${doc_id}`, reqConfig);
	return data;
};
