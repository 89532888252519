import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { CheckBoxOutlineBlankRounded } from "@mui/icons-material";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { CheckboxFieldEntity, Selection, CheckBoxViewStyle } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";

const UiCheckbox = styled(Checkbox)({
	padding: 3,
});
const NonEditableFormControlLabel = styled(FormControlLabel)({
	cursor: "default",
	margin: 3,
});

type Props = {
	value?: StringFieldValue;
	values?: string[];
	field: CheckboxFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

type ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

/**
 * 指定の値リストを選択肢順に整頓して返します
 *
 * @param values 不順の値リスト
 * @param selections 選択肢一覧
 */
const tidySelectedValues = (values: string[], selections: Selection[]): string[] => {
	return selections
		.filter((entry) => {
			return values.includes(entry.value);
		})
		.map<string>((entry) => entry.value);
};

/**
 * チェックボックスの選択項目を列挙
 *
 * @param selections 選択項目一覧
 * @param checkedValues チェック済み項目値の配列
 * @param handleChange コントロールチェック時のイベントハンドラー
 * @returns FormControlLabel コンポーネントの配列
 */
const makeCheckboxControls = (
	selections: Selection[],
	no_selection: Selection,
	checkedValues: string[],
	editable: boolean,
	view_style: CheckBoxViewStyle,
	handleChange: ChangeEventHandler
): JSX.Element[] => {
	const controls = selections.map((entry) => {
		const checked = checkedValues.includes(entry.value);
		if (editable) {
			return (
				<FormControlLabel
					key={entry.value}
					label={view_style === CheckBoxViewStyle.CheckboxOrLabel ? "" : entry.label}
					data-testid="checkbox-field-editable"
					control={<UiCheckbox value={entry.value} checked={checked} onChange={handleChange} />}
				/>
			);
		} else {
			const label = checked ? entry.label : no_selection.label;

			// チェックボックスを表示せずラベルのみ表示する
			if (view_style === CheckBoxViewStyle.CheckboxOrLabel) {
				return <NonEditableFormControlLabel key={entry.value} label={label} data-testid="checkbox-field-no-editable" control={<></>} />;
			}

			// 表示専用モード時はチェックボックスの枠を出しつつ、枠だけ無効っぽくする
			// 不用意にイベント発動しないようにする
			return (
				<FormControlLabel
					key={entry.value}
					label={entry.label}
					data-testid="checkbox-field-no-editable"
					control={
						<UiCheckbox
							checked={checked}
							icon={<CheckBoxOutlineBlankRounded color="disabled" />}
							readOnly={true}
							disableFocusRipple={true}
							disableRipple={true}
							disableTouchRipple={true}
						/>
					}
				/>
			);
		}
	});
	return controls;
};

/**
 * チェックボックスフィールド
 */
const CheckboxField: React.VFC<Props> = ({ values, field, editable, onChange }) => {
	const { id, table_id, table_row } = field || {};

	// 省略されてる設定を補完
	const selections = field.selections ?? [];

	const [checkedValues, setCheckedValues] = useState<string[]>(field.initial_values ?? []);

	const handleChange: ChangeEventHandler = (event, checked) => {
		const targetValue = event.target.value;

		// NOTE
		// チェック済みの値配列を直接書き換えないこと。
		// 変更を伝搬させるために変更後の値セット全体を setCheckedValues を通じて渡す。

		let newValues: string[];
		if (checked) {
			// チェック済み値の一覧に追加
			newValues = checkedValues.concat(targetValue);
		} else {
			// チェック済みの値一覧に除外
			newValues = checkedValues.filter((value) => value !== targetValue);
		}

		// 選択肢順に選択した値を並び変える
		newValues = tidySelectedValues(newValues, selections);

		setCheckedValues(newValues);

		onChange &&
			onChange({
				id,
				value: newValues.join("\x01"),
				values: newValues,
				table_id,
				table_row,
			});
	};

	useEffect(() => {
		setCheckedValues(values ?? field.initial_values ?? []);
	}, [values, field.initial_values]);

	const controls = makeCheckboxControls(selections, field.no_selection, checkedValues, editable ?? false, field.view_style, handleChange);
	return (
		<Box data-testid="checkbox-field-root">
			<FormControl fullWidth>
				<FormGroup row={true}>{controls}</FormGroup>
			</FormControl>
		</Box>
	);
};

export default CheckboxField;
