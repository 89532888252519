import React from "react";
import { Controller, useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Box, CardActions, List, ListItem, MenuItem, Select } from "@mui/material";

import { AuthAndServiceLink, OstensibleAuthentication } from "../../../typings/AuthenticationTypes";
import Form from "../../Form";
import { FormWizardStepType } from "../../FormWizardForm";

type FormState = {
	authentication_id: string;
};

type Props = {
	authentications: OstensibleAuthentication[];
	changeStatus: (value: FormWizardStepType) => void;
	setAuthAndServiceLink: React.Dispatch<React.SetStateAction<AuthAndServiceLink | undefined>>;
};

const ExistingAuthenticationForm: React.VFC<Props> = ({ authentications, changeStatus, setAuthAndServiceLink }) => {
	const { handleSubmit, control } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			authentication_id: authentications[0].id,
		},
	});

	const onSubmit = handleSubmit(async (data: FormState) => {
		if (data.authentication_id) {
			setAuthAndServiceLink((state) => ({ ...state, authentication: { id: data.authentication_id } }));
			changeStatus("step3");
		}
	});

	const selectElements = [];
	for (const [key, item] of Object.entries(authentications)) {
		selectElements.push(
			<MenuItem key={item.id} value={item.id} data-testid={`authentications-${key}`}>
				{item.name}
			</MenuItem>
		);
	}
	return (
		<Form onSubmit={onSubmit} data-testid="existing-authentication-form-root">
			<Box pb={1.8}>
				<Box minWidth="460px" maxWidth="650px" mr="15px">
					<List>
						<ListItem>
							<Controller
								name="authentication_id"
								control={control}
								as={
									<Select
										id="authentication_id"
										name="authentication_id"
										autoComplete="off"
										data-testid="existing-authentication-form-dialog-authentication_id"
									>
										{selectElements}
									</Select>
								}
								fullWidth
								variant="outlined"
								rules={{ required: true }}
							/>
						</ListItem>
					</List>
				</Box>
			</Box>
			<CardActions>
				<LoadingButton type="submit" color="primary" size="medium" variant="contained">
					次へ
				</LoadingButton>
			</CardActions>
		</Form>
	);
};

export default ExistingAuthenticationForm;
