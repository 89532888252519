import { useDispatch } from "react-redux";

import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";

import reducers, { State } from "./Reducers";

const store = configureStore({
	reducer: reducers,
	devTools: /\.test$/.test(window.location.origin),
});

export type AppDispatch = ThunkDispatch<State, null, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
