import { VFC } from "react";
import { Link as RouterLink } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SettingsIcon from "@mui/icons-material/Settings";
import { Divider, Link, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import StyledDrawer from "./StyledDrawer";

const WorkspaceAdminDrawer: VFC = () => {
	return (
		<StyledDrawer variant="permanent" data-testid="workspace-admin-drawer-root">
			<List>
				<Link component={RouterLink} to="/" data-testid="back-to-workspace-link">
					<ListItemButton>
						<ListItemIcon>
							<ArrowBackIosIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="ワークスペースに戻る" />
					</ListItemButton>
				</Link>
			</List>
			<Divider />
			<List>
				<Link component={RouterLink} to="/admin" data-testid="drawer-form-list-link">
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="基本設定" />
					</ListItemButton>
				</Link>
				<Link component={RouterLink} to="/admin/authentications" data-testid="drawer-form-list-link">
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="認証情報" />
					</ListItemButton>
				</Link>
				<Link component={RouterLink} to="/admin/customize" data-testid="drawer-form-list-link">
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="カスタマイズ" />
					</ListItemButton>
				</Link>
				<Link component={RouterLink} to="/admin/contract" data-testid="drawer-contract-link">
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="契約情報" />
					</ListItemButton>
				</Link>
			</List>
		</StyledDrawer>
	);
};

export default WorkspaceAdminDrawer;
