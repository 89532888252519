import React, { useState, VFC } from "react";
import { useDropzone } from "react-dropzone";

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { red, grey } from "@mui/material/colors";

import { LogoConst, Validate } from "../constants/LogoConst";

type Props = {
	logo_file_path?: string;
	onAttach: (action: "set" | "delete", file?: File) => void;
};

type Error = {
	type: "accept" | "size";
	message: string;
};

type AttachmentZoneProps = {
	file_path?: string;
	field_id?: string;
};

const ImagePreviewBox: VFC<AttachmentZoneProps> = ({ file_path }) => {
	if (file_path) {
		return <Box component="img" maxWidth={LogoConst.ImageSize.Width} maxHeight={LogoConst.ImageSize.Height} src={file_path} />;
	} else {
		return (
			<Box minWidth={LogoConst.ImageSize.Width} minHeight={LogoConst.ImageSize.Height} display="flex" alignItems="center" justifyContent="center">
				<Typography color={grey[400]}>ロゴが設定されていません</Typography>
			</Box>
		);
	}
};

const LogoAttachment: React.VFC<Props> = ({ logo_file_path, onAttach: onLogoChange }) => {
	const [error, setError] = useState<Error>();

	const validateLogoAcceptType = (file: File) => {
		if (Validate.AllowMIMETypes.indexOf(file.type) === -1) {
			return false;
		}
		return true;
	};

	const validateLogoSize = (file: File) => {
		if (Validate.MaxSize < file.size) {
			return false;
		}
		return true;
	};

	const onDrop = ([file]: File[]) => {
		if (!validateLogoSize(file)) {
			setError({
				type: LogoConst.Error.MaxSize.Type,
				message: LogoConst.Error.MaxSize.Message,
			});
		} else if (validateLogoAcceptType(file)) {
			onLogoChange("set", file);
			setError(undefined);
		} else {
			setError({
				type: LogoConst.Error.AllowMIMETypes.Type,
				message: LogoConst.Error.AllowMIMETypes.Message,
			});
		}
	};

	const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const removeFile = (e: React.MouseEvent<HTMLInputElement>) => {
		acceptedFiles.splice(0);
		onLogoChange && onLogoChange("delete");
		setError(undefined);
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	return (
		<Box pb={1.8} data-testid="logo-attachment-root">
			<Box display="flex" alignItems="center">
				<Box
					width={LogoConst.ImageSize.Width}
					height={LogoConst.ImageSize.Height}
					sx={{
						outlineWidth: "1px",
						outlineStyle: "dashed",
						outlineColor: grey[500],
						...(error && { outlineColor: red[500] }),
						cursor: "pointer",
						transition: "all .05s ease-in-out",
						...(isDragActive && { backgroundColor: "whitesmoke" }),
						background: grey[200],
						"&:hover": {
							background: grey[300],
						},
					}}
					{...getRootProps({ className: "dropzone" })}
				>
					<Box component="input" {...getInputProps()} />
					<ImagePreviewBox file_path={logo_file_path} />
				</Box>
				<Box
					onClick={(e: React.MouseEvent<HTMLInputElement>) => {
						removeFile(e);
					}}
					ml={1}
				>
					<IconButton
						color="inherit"
						component="label"
						disabled={!logo_file_path}
						sx={{
							color: grey[400],
							"&:hover": {
								color: grey[500],
							},
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</Box>

			<Box mt={0.5} height="24px">
				{error && <Typography color={red[500]}>{error.message}</Typography>}
			</Box>
		</Box>
	);
};

export default LogoAttachment;
