import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const SignoutListItemButton: React.FC = () => {
	const { logout } = useAuth0();

	const redirectToLandingPage = () => {
		logout({
			returnTo: "https://" + window.location.hostname,
		});
	};

	return (
		<ListItemButton onClick={redirectToLandingPage} data-testid="user-menu-logout-button-root">
			<ListItemIcon>
				<ExitToAppIcon color="primary" />
			</ListItemIcon>
			<ListItemText primary="サインアウト" />
		</ListItemButton>
	);
};

export default SignoutListItemButton;
