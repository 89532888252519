import { VFC } from "react";

import styled from "@emotion/styled";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Avatar, Box, Card, CardProps, Typography } from "@mui/material";

import { ErrorPayload } from "../typings/ErrorTypes";

import theme from "./Theme";

const CustomAvatar = styled(Avatar)({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.primary.main,
});

type Props = CardProps & {
	title: string;
	error?: ErrorPayload;
};

const ErrorMessageCard: VFC<Props> = ({ title, error, ...props }) => {
	const { name, statusCode, message } = error || {};

	return (
		<Card data-testid="error-message-card-root" variant="outlined" {...props}>
			<Box my={4}>
				<Box display="flex" alignItems="center" flexDirection="column">
					<CustomAvatar>
						<AnnouncementIcon />
					</CustomAvatar>
				</Box>
				<Box mt={1}>
					<Typography variant="body1" align="center" color="textSecondary" data-testid="error-message-card-title">
						{title}
					</Typography>
					<Typography variant="body1" align="center" color="textSecondary" data-testid="error-message-card-message">
						{message}
					</Typography>
				</Box>
				<Box mt={1}>
					<Typography variant="body2" align="center" color="textSecondary" data-testid="error-message-card-status-and-name">
						{statusCode} {name}
					</Typography>
				</Box>
			</Box>
		</Card>
	);
};

export default ErrorMessageCard;
