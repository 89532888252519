import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

// cspell:disable
const formatList = [
	"YYYY-MM-DD",
	"YYYY/MM/DD",
	"YYYY/M/D",
	"YYYY MM DD",
	"YYYY-MM-DDTHH:mm:ss",
	"YYYY-MM-DDTHH:mm:ss[Z]",
	"YYYY-MM-DDTHH:mm:ss.SSS",
	"YYYY-MM-DDTHH:mm:ss.SSS[Z]",
];
// cspell:enable

/**
 *
 * Dateを扱う場合の単発関数セット
 */
export class DateUtil {
	/**
	 * string,Date の値を Date に変換
	 *
	 * @param v 変換する値
	 * @returns 該当すれば Date のインスタンス、未定義値は undefined  不正な値はエラー の応答
	 */
	public static toDate(v: any): Date | undefined {
		if (v instanceof Date) {
			if (!isNaN(v.getTime())) {
				return v;
			}
			throw Error("Invalid Date");
		}

		if (v === undefined || v === null) {
			return undefined;
		}

		if (dayjs(v, formatList, true).isValid()) {
			if (/z/i.test(v)) {
				// cspell:disable-next-line
				return new Date(dayjs(v).format("YYYY-MM-DDTHH:mm:ss.SSS"));
			}
			// cspell:disable-next-line
			return new Date(dayjs(v).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
		}
		throw Error("Invalid Date");
	}

	/**
	 * number の値を Date に変換
	 *
	 * @param ms ミリ秒単位のエポック値
	 * @returns 該当すれば Date のインスタンス、その他は undefined の応答
	 */
	public static epochToDate(ms: number | undefined | null): Date | undefined {
		if (typeof ms === "number" && !Number.isNaN(ms)) {
			return new Date(ms);
		}
		return undefined;
	}

	/**
	 * 特定の日時文字列から、今日までの日数を返します
	 *
	 * @param stringDate 日付文字列
	 * @returns
	 */
	public static countDaysLeftFromToday(stringDate: string | undefined): number | undefined {
		if (typeof stringDate !== "string" || !stringDate.length) return;

		const today = dayjs().format("YYYY-MM-DD");
		const days = dayjs(stringDate).diff(dayjs(today), "day") + 1;
		return days;
	}
}
