import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

type GetFormSecretResponse = {
	password: string;
};

/**
 * フォームのパスワードを取得します
 *
 * @param form_id
 * @param options
 */
export const getFormSecret = async (form_id: string, options: ApiOptions): Promise<GetFormSecretResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.get(`/forms/${form_id}/secret`, reqConfig).catch(axiosErrorHandler);

	return data;
};
