import { ReactNode, useEffect, VFC } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { useWorkspace } from "../hooks/useWorkspace";

import Waiting from "./Waiting";

type Props = {
	children?: ReactNode;
};

const WorkspaceProvider: VFC<Props> = ({ children }) => {
	const { fetchWorkspace, workspace, isWorkspaceLoaded } = useWorkspace();
	const location = useLocation();

	useEffect(() => {
		fetchWorkspace();
	}, [fetchWorkspace]);

	const isAdminRoute = location.pathname?.startsWith("/admin");

	if ("is_active" in workspace) {
		const queryString = workspace?.contract?.is_trial ? "?reason=trial_expired" : "?reason=expired";

		if (!isAdminRoute && !workspace.is_active) {
			return <Redirect to={`/workspace-locked${queryString}`} />;
		}

		if (isWorkspaceLoaded) {
			return <>{children}</>;
		}
	}

	return <Waiting />;
};

export default WorkspaceProvider;
