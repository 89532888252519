import { combineReducers } from "@reduxjs/toolkit";

import docSlice from "./DocSlice";
import formDocsSlice from "./FormDocsSlice";
import formListSlice from "./FormListSlice";
import layoutSlice from "./LayoutSlice";
import notifications from "./NotificationsSlice";
import workspaceSlice from "./WorkspaceSlice";

const reducers = combineReducers({
	workspace: workspaceSlice.reducer,
	formList: formListSlice.reducer,
	formDocs: formDocsSlice.reducer,
	layout: layoutSlice.reducer,
	doc: docSlice.reducer,
	notifications: notifications.reducer,
});

export type State = ReturnType<typeof reducers>;

export default reducers;
