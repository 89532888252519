import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { ApiOptions, generateAxiosRequestConfig } from "../misc/generateAxiosRequestConfig";

export type GetAuthenticationResponse = {
	id: string;
	endpoint: string;
	name: string;
	user_id: string;
	basic_user?: string;
};

/**
 * 認証情報を取得します。
 *
 * @param options - API オプション
 */
export const getAuthentication = async (authentication_id: string, options: ApiOptions): Promise<GetAuthenticationResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.get(`/authentications/${authentication_id}`, reqConfig).catch(axiosErrorHandler);
	return data;
};
