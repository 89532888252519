export const LogoConst = {
	ImageSize: { Width: "220px", Height: "40px" },
	Error: {
		AllowMIMETypes: {
			Type: "accept",
			Message: "この拡張子は使用できません",
		},
		MaxSize: {
			Type: "size",
			Message: "ファイルサイズが大きすぎます",
		},
	},
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LogoConst = typeof LogoConst[keyof typeof LogoConst];

export const Validate = {
	AllowMIMETypes: ["image/jpeg", "image/png", "image/gif", "image/svg+xml"],
	MaxSize: 307200, //300KB
};
