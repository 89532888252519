import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardHeader, DialogActions, Divider, InputLabel, List, ListItem, MenuItem, Select, TextField } from "@mui/material";

import { PostAuthenticationResponse } from "../apis/authentications/post";
import { listProcess } from "../apis/processes/list";
import { putServiceLink, PutServiceLinkRequest } from "../apis/servicelink/put";
import { ServiceLinkConst } from "../constants/ServiceLinkConst";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { AuthAndServiceLink, OstensibleAuthentication } from "../typings/AuthenticationTypes";
import { LoadingStatus } from "../typings/GeneralTypes";
import { Process } from "../typings/ServiceLinkTypes";
import { isPlusSafeIntegerString } from "../utils/Validator/isPlusSafeIntegerString";

import AuthenticationAddForm from "./AuthenticationAddForm";
import FadingModal from "./FadingModal";
import Form from "./Form";
import ToastContent from "./ToastContent";

type FormState = {
	authentication_id: string;
	app_cd: string;
	processes_id: string;
};

type Props = {
	form_id: string;
	authAndServiceLink?: AuthAndServiceLink;
	authentications: OstensibleAuthentication[];
	is_archive?: boolean;
};

const FormServiceLinkUpdateForm: React.VFC<Props> = ({ form_id, authAndServiceLink, authentications, is_archive = false }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [loading, setLoading] = useState<boolean>(false);
	const [modalView, setModalView] = useState<boolean>(false);

	const handleModalOpen = (): void => {
		setModalView(true);
	};
	const handleModalClose = (): void => {
		setModalView(false);
	};

	const { errors, formState, watch, handleSubmit, register, control, setValue, reset } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			authentication_id: authAndServiceLink?.authentication?.id ?? "",
			app_cd: (authAndServiceLink?.servicelink?.app_cd as string) ?? "",
			processes_id: (authAndServiceLink?.servicelink?.processes_id as string) ?? "",
		},
	});
	const { isValid, dirtyFields } = formState;

	const addAuthentication = (value: PostAuthenticationResponse) => {
		authentications.push(value);
		setValue("authentication_id", value.id, { shouldValidate: true });
	};

	const onSubmit = handleSubmit(async (data: FormState) => {
		setLoading(true);
		const toastId = uuidV4();
		toast("保存中...", { toastId, autoClose: false });

		const reqData: PutServiceLinkRequest = {
			connection: { id: data.authentication_id },
			config: {
				app_cd: data.app_cd,
				processes_id: data.processes_id,
			},
		};

		const auth_token = await getAccessTokenSilently();
		await putServiceLink(form_id, reqData, { auth_token, workspace_id })
			.then(() => {
				reset(data);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: null,
					render: "接続設定を保存しました",
				});
			})
			.catch((reason: any) => {
				console.error(reason);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
			});
	});

	const selectAuthenticationElement = [];

	for (const [key, item] of Object.entries(authentications)) {
		selectAuthenticationElement.push(
			<MenuItem key={item.id} value={item.id} data-testid={`authentications-${key}`}>
				{item.name}
			</MenuItem>
		);
	}

	// 経路に関する処理

	const [processList, setProcessList] = useState<Process[] | null>();
	const [processListLoading, setProcessListLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const fetchProcessList = useCallback(
		async (app_cd: string | number, auth_id: string) => {
			setProcessListLoading(LoadingStatus.Pending);
			const auth_token = await getAccessTokenSilently();

			await listProcess({ workspace_id, auth_token }, { auth_id, app_cd })
				.then((res) => {
					const processes = res.processes.map((process) => {
						return {
							id: process.id,
							name: process.title,
						};
					});
					processes.sort(function (a, b) {
						if (a.id > b.id) return 1;
						if (a.id < b.id) return -1;
						return 0;
					});
					setProcessList(processes);
					setProcessListLoading(LoadingStatus.Fulfilled);
				})
				.catch((reason) => {
					console.log(reason);
					setProcessListLoading(LoadingStatus.Error);
					toast(<ToastContent subject="経路を取得できませんでした" message={reason.message} />, {
						type: toast.TYPE.ERROR,
						autoClose: false,
					});
				});
		},
		[getAccessTokenSilently, workspace_id]
	);

	const appCd = authAndServiceLink?.servicelink?.app_cd;
	const authenticationId = authAndServiceLink?.authentication?.id;

	useEffect(() => {
		if (authenticationId && typeof appCd === "string" && appCd) {
			workspace_id && fetchProcessList(appCd, authenticationId);
		}
	}, [authenticationId, appCd, fetchProcessList, workspace_id]);

	const selectProcessesListElement = [];
	if (processList) {
		for (const [key, item] of Object.entries(processList)) {
			selectProcessesListElement.push(
				<MenuItem key={item.id} value={item.id} data-testid={`process-${key}`}>
					{item.id} - {item.name}
				</MenuItem>
			);
		}
	}

	const validAppCd = (): boolean => {
		return isPlusSafeIntegerString(watch("app_cd"));
	};

	const getProcessList = (): void => {
		if (validAppCd()) {
			const app_cd = watch("app_cd");
			fetchProcessList(app_cd, watch("authentication_id"));
		}
	};

	const isProcessReady = processListLoading === LoadingStatus.Pending || processListLoading === LoadingStatus.Initial || !validAppCd();

	return (
		<Card variant="outlined">
			<CardHeader title="接続設定" subheader="コラボフローの接続設定" />
			<Form onSubmit={onSubmit} data-testid="form-servicelink-update-form-root">
				<Box pb={1.8}>
					<List>
						<ListItem>
							<Box minWidth="460px" maxWidth="650px" mr={2}>
								<Controller
									name="authentication_id"
									control={control}
									as={
										<Select
											id="authentication_id"
											name="authentication_id"
											autoComplete="off"
											data-testid="form-servicelink-update-form-authentication"
										>
											{selectAuthenticationElement}
										</Select>
									}
									fullWidth
									variant="outlined"
									rules={{ required: true }}
									disabled={is_archive}
								/>
							</Box>
							<Box>
								<Button
									variant="contained"
									size="large"
									color="primary"
									onClick={handleModalOpen}
									// 認証情報の追加はフォームではなくワークスペースに依る機能だが、混乱を避けるためアーカイブで一律無効化する
									disabled={is_archive}
									data-testid="form-servicelink-update-form-authentication-add-btn"
								>
									追加
								</Button>
							</Box>
						</ListItem>
						<ListItem sx={{ mt: 5.5, flexDirection: "column", alignItems: "start" }}>
							<InputLabel>{ServiceLinkConst.AppCd.Name}</InputLabel>
							<Box display="flex" alignItems="center" mb={2}>
								<Box mr={2}>
									<TextField
										id="app_cd"
										name="app_cd"
										variant="outlined"
										autoComplete="off"
										onBlur={getProcessList}
										InputLabelProps={{
											required: true,
										}}
										error={errors.app_cd ? true : false}
										inputRef={register({
											required: { value: true, message: `${ServiceLinkConst.AppCd.Name}は必須です` },
											validate: isPlusSafeIntegerString,
										})}
										disabled={is_archive}
										data-testid="form-servicelink-update-form-app-cd"
									/>
								</Box>
								<LoadingButton
									onClick={getProcessList}
									loading={processListLoading === LoadingStatus.Pending}
									size="large"
									variant="contained"
									disabled={!validAppCd()}
								>
									経路を取得する
								</LoadingButton>
							</Box>

							<Box display="flex" alignItems="center">
								<Box minWidth="460px" maxWidth="650px" mr={2}>
									<InputLabel>{ServiceLinkConst.ProcessesId.Name}</InputLabel>
									<Controller
										name="processes_id"
										control={control}
										as={
											<Select
												id="processes_id"
												name="processes_id"
												autoComplete="off"
												data-testid="form-servicelink-update-form-processes-id"
												disabled={isProcessReady}
												sx={{
													...(isProcessReady && {
														background: "rgba(0, 0, 0, 0.12)",
													}),
												}}
											>
												{selectProcessesListElement}
											</Select>
										}
										fullWidth
										variant="outlined"
										rules={{ required: true }}
									/>
								</Box>
							</Box>
						</ListItem>
					</List>
				</Box>

				<Divider />
				<DialogActions>
					<LoadingButton
						loading={loading}
						type="submit"
						variant="contained"
						color="primary"
						disabled={loading || !isValid || !Object.keys(dirtyFields).length || is_archive}
						data-testid="form-service-link-update-form-save-btn"
					>
						保存
					</LoadingButton>
				</DialogActions>
			</Form>
			<FadingModal open={modalView} onClose={handleModalClose}>
				<AuthenticationAddForm onClose={handleModalClose} onSuccess={addAuthentication} />
			</FadingModal>
		</Card>
	);
};

export default FormServiceLinkUpdateForm;
