import { useCallback, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { deleteFormSecret } from "../apis/forms/secret/delete";
import { getFormSecret } from "../apis/forms/secret/get";
import { PutFormSecretRequest, putFormSecret } from "../apis/forms/secret/put";
import { LoadingStatus } from "../typings/GeneralTypes";
import { WithWorkspaceId } from "../typings/WorkspaceTypes";

export type WithFormId<T = any> = T & { form_id: string };

type WithControl<T = any> = T & { formSecretControl: T };

type UseFormSecretBaseReturn = {
	fetchFormSecret: (req: WithWorkspaceId<WithFormId>) => Promise<string | undefined>;
	registerFormSecret: (req: WithWorkspaceId<WithFormId<PutFormSecretRequest>>) => Promise<void>;
	removeFormSecret: (req: WithWorkspaceId<WithFormId>) => Promise<void>;
	formSecretState: {
		password: string | undefined;
		fetching: LoadingStatus;
		registering: LoadingStatus;
		removing: LoadingStatus;
	};
};

export type FormSecretControl = UseFormSecretBaseReturn;

export type UseFormSecretReturn = WithControl<UseFormSecretBaseReturn>;

export const useFormSecret = (): UseFormSecretReturn => {
	const { getAccessTokenSilently } = useAuth0();

	const [password, setPassword] = useState<string | undefined>();
	const [registering, setRegistering] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [fetching, setFetching] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [removing, setRemoving] = useState<LoadingStatus>(LoadingStatus.Initial);

	const fetchFormSecret = useCallback(
		async (req: WithWorkspaceId<WithFormId>): Promise<string | undefined> => {
			setFetching(LoadingStatus.Pending);
			if (!req.workspace_id || !req.form_id) {
				return;
			}
			const { workspace_id, form_id } = req;

			const auth_token = await getAccessTokenSilently();
			const { password } = await getFormSecret(form_id, { auth_token, workspace_id });

			setFetching(LoadingStatus.Fulfilled);
			setPassword(password);

			return password;
		},
		[getAccessTokenSilently]
	);

	const registerFormSecret = useCallback(
		async (req: WithWorkspaceId<WithFormId<PutFormSecretRequest>>): Promise<void> => {
			setRegistering(LoadingStatus.Pending);
			if (!req.workspace_id || !req.form_id || !req.password) {
				return;
			}
			const { workspace_id, form_id, password } = req;

			const auth_token = await getAccessTokenSilently();

			await putFormSecret(form_id, { password }, { auth_token, workspace_id });

			setPassword(password);
			setRegistering(LoadingStatus.Fulfilled);
		},
		[getAccessTokenSilently]
	);

	const removeFormSecret = useCallback(
		async (req: WithWorkspaceId<WithFormId>): Promise<void> => {
			setRemoving(LoadingStatus.Pending);

			if (!req.workspace_id || !req.form_id) {
				return;
			}
			const { workspace_id, form_id } = req;

			const auth_token = await getAccessTokenSilently();
			await deleteFormSecret(form_id, { auth_token, workspace_id });

			setPassword(undefined);
			setRemoving(LoadingStatus.Fulfilled);
		},
		[getAccessTokenSilently]
	);

	const formSecretState = {
		password,
		fetching,
		registering,
		removing,
	};

	const formSecretControl = {
		fetchFormSecret,
		registerFormSecret,
		removeFormSecret,
		formSecretState,
	};

	return {
		...formSecretControl,
		formSecretControl,
	};
};
