import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { validate as uuidValidate } from "uuid";

import { Box, Typography } from "@mui/material";

import FormPasswordCard from "../../components/FormPasswordCard";
import FormPublicAddressCard from "../../components/FormPublicAddressCard";
import FormSettingsDrawer from "../../components/FormSettingsDrawer";
import NavigationMessage from "../../components/NavigationMessage";
import SettingCardsSkeleton from "../../components/SettingCardsSkeleton";
import ToastContent from "../../components/ToastContent";
import { useFormList } from "../../hooks/useFormList";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";
import { LoadingStatus } from "../../typings/GeneralTypes";

const FormAccess: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const form_id = match.params.form_id;
	const { id: workspace_id } = useSelector(selectWorkspace);
	// FIXME: MainTemplate と以下でフォームを二重取得して、同じスライスで管理しているのでデータ齟齬のリスクがある。スライスの分離が必要。
	const { fetchForm, createFormByIdSelector, isLoaded: isFormListLoaded } = useFormList();
	const form = useSelector(createFormByIdSelector(form_id));

	const [fetching, setFetching] = useState<LoadingStatus>(LoadingStatus.Initial);

	useEffect(() => {
		if (workspace_id && form_id) {
			(async () => {
				setFetching(LoadingStatus.Pending);
				fetchForm(workspace_id, form_id)
					.then(() => {
						setFetching(LoadingStatus.Fulfilled);
					})
					.catch((reason) => {
						console.error(reason);
						toast(<ToastContent subject="フォームの取得に失敗しました" message={reason?.message} />, {
							type: toast.TYPE.ERROR,
							autoClose: false,
						});
						setFetching(LoadingStatus.Error);
					});
			})();
		}
	}, [fetchForm, workspace_id, form_id]);

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate pageName="フォーム">
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	// FIXME: ローディングステータスも二重管理しているので要修正
	const loaded = fetching === LoadingStatus.Fulfilled && isFormListLoaded;

	return (
		<MainTemplate pageName="公開アドレス" drawer={<FormSettingsDrawer form_id={form_id} />} isLoading={!loaded}>
			<Box data-testid="form-access-root" mb={4}>
				<Box height={40} display="flex" alignItems="center" mb={1}>
					<Typography variant="h5" component="h1">
						フォーム設定
					</Typography>
				</Box>
				{loaded && form ? (
					<Box>
						<Box>
							<FormPublicAddressCard
								form_id={form.id}
								is_public={form.is_public}
								is_archive={form.is_archive}
								variant="outlined"
								data-testid="form-access-public-address-card"
							/>
						</Box>
						<Box mt={4}>
							<FormPasswordCard
								form_id={form.id}
								is_password_required={form.is_password_required}
								is_archive={form.is_archive}
								variant="outlined"
								data-testid="form-access-password-card"
							/>
						</Box>
					</Box>
				) : (
					<SettingCardsSkeleton />
				)}
			</Box>
		</MainTemplate>
	);
};

export default FormAccess;
