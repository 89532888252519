import React, { useState } from "react";

import { Box } from "@mui/material";

import { AuthAndServiceLink } from "../typings/AuthenticationTypes";
import { ListedForm } from "../typings/FormListTypes";

import FormWizardStep1 from "./form-wizard/Step1";
import FormWizardStep2 from "./form-wizard/Step2";
import FormWizardStep3 from "./form-wizard/Step3";
import FormWizardStep4 from "./form-wizard/Step4";
import FormWizardStep5 from "./form-wizard/Step5";

export type FormWizardStepType = "step1" | "step2" | "step3" | "step4" | "complete";

export type FormWizardStepStatus = "initial" | "success" | "error";

type Step = {
	[step: string]: FormWizardStepStatus;
};

type Props = {
	form: ListedForm;
	form_id: string;
	authAndServiceLink?: AuthAndServiceLink;
	setAuthAndServiceLink: React.Dispatch<React.SetStateAction<AuthAndServiceLink | undefined>>;
};

const FormWizardForm: React.VFC<Props> = ({ form, authAndServiceLink, setAuthAndServiceLink, form_id }) => {
	const [stepStatus, setStepStatus] = useState<Step>({ step1: "initial", step2: "initial", step3: "initial", step4: "initial" });

	const [expanded, setExpanded] = useState<FormWizardStepType>("step1");
	const changeStatus = (stepName: FormWizardStepType) => {
		setStepStatus({ ...stepStatus, [expanded]: "success" });
		setExpanded(stepName);
	};

	const errorStatusChanger = (stepName: FormWizardStepType) => {
		setStepStatus({ ...stepStatus, [stepName]: "error" });
	};

	return (
		<Box data-testid="form-wizard-form-root">
			<FormWizardStep1
				form={form}
				expanded={expanded === "step1"}
				changeStatus={changeStatus}
				errorStatusChanger={errorStatusChanger}
				disabled={false}
				stepSign={stepStatus.step1}
			/>
			<FormWizardStep2
				expanded={expanded === "step2"}
				changeStatus={changeStatus}
				errorStatusChanger={errorStatusChanger}
				disabled={false}
				stepSign={stepStatus.step2}
				setAuthAndServiceLink={setAuthAndServiceLink}
			/>
			<FormWizardStep3
				form_id={form_id}
				authAndServiceLink={authAndServiceLink}
				expanded={expanded === "step3"}
				changeStatus={changeStatus}
				errorStatusChanger={errorStatusChanger}
				disabled={false}
				stepSign={stepStatus.step3}
			/>
			<FormWizardStep4
				form_id={form_id}
				expanded={expanded === "step4"}
				changeStatus={changeStatus}
				errorStatusChanger={errorStatusChanger}
				disabled={false}
				stepSign={stepStatus.step4}
			/>
			<FormWizardStep5
				form_id={form_id}
				expanded={expanded === "complete"}
				disabled={false}
				stepSign={stepStatus.step1 && stepStatus.step2 && stepStatus.step3 && stepStatus.step4}
			/>
		</Box>
	);
};

export default FormWizardForm;
