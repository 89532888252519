import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Typography, Accordion, AccordionDetails, Button, Link } from "@mui/material";

import { FormWizardStepStatus } from "../FormWizardForm";

import StepHeader from "./StepHeader";

type Props = {
	form_id: string;
	expanded: boolean;
	stepSign: FormWizardStepStatus;
	disabled: boolean;
};

const FormWizardStep5: React.VFC<Props> = ({ form_id, expanded, stepSign, disabled }) => {
	return (
		<Accordion expanded={expanded} disabled={disabled} data-testid="form-wizard-step5-root">
			<StepHeader title={"初期設定の完了"} stepSign={stepSign} />

			<AccordionDetails>
				<Box pb={1.8} width="100%" position="relative">
					<Box mb={2}>
						<Typography className="text" variant="h5" component="p">
							フォームの初期設定が完了しました <br />
						</Typography>
						<Box mt={2}>
							<Link component={RouterLink} to={`/forms/${form_id}/access`}>
								<Button variant="contained" size="large" color="primary" data-testid="form-wizard-step5-link-button">
									フォームの公開設定へ
								</Button>
							</Link>
						</Box>
					</Box>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default FormWizardStep5;
