import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import styled from "@emotion/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ErrorIcon from "@mui/icons-material/Error";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import { APP_NAME } from "../../App";
import Copyright from "../../components/Copyright";
import theme from "../../components/Theme";

const CustomAvatar = styled(Avatar)({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
});

// TODO: 要デザイン
const ErrorPage: React.FC = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const errorMessage = params.get("m");

	return (
		<Container component="main" maxWidth="xs" data-testid="error-page">
			<Helmet>
				<title>エラー - {APP_NAME}</title>
			</Helmet>
			<CssBaseline />
			<Box marginTop={theme.spacing(8)} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				<CustomAvatar>
					<ErrorIcon />
				</CustomAvatar>
				<Typography component="h1" variant="h5">
					Error!
				</Typography>
				<Typography variant="subtitle1" align="center" color="textSecondary" component="p">
					おや…？何らかの不具合があったようです。
				</Typography>
				<Typography variant="subtitle2" align="center" color="textSecondary" component="p" whiteSpace="pre-line">
					{errorMessage}
				</Typography>
				<Box mt={2}>
					<Button href={window.location.origin} variant="outlined">
						<ArrowBackIosIcon color="inherit" />
						フォーム一覧に戻る
					</Button>
				</Box>
			</Box>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
};

export default ErrorPage;
