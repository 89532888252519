/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { v4, validate as uuidValidate } from "uuid";

import { Box, useMediaQuery } from "@mui/material";

import { postDocAction } from "../../apis/docs/action/post";
import { postDoc } from "../../apis/docs/post";
import { postAttachment } from "../../apis/docs/postAttachment";
import { prepareAttachmentPost } from "../../apis/docs/upload/post";
import { getLayoutData } from "../../apis/layouts/get";
import CenterTwoThirdsBox from "../../components/CenterTwoThirdsBox";
import DocumentViewDrawer from "../../components/DocumentViewDrawer";
import ErrorMessageCard from "../../components/ErrorMessageCard";
import FormPasswordLockCard from "../../components/FormPasswordLockCard";
import GuestLayoutHTMLContent from "../../components/GuestLayoutHTMLContent";
import NavigationMessage from "../../components/NavigationMessage";
import theme from "../../components/Theme";
import ToastContent from "../../components/ToastContent";
import { DocActionType } from "../../constants/DocConst";
import { JSONFieldValueTypeMap } from "../../constants/FieldConst";
import { useFormList } from "../../hooks/useFormList";
import { clear as clearDoc, docValueSelectors, selectDocMode, setMode } from "../../store/DocSlice";
import { fetchFieldsById, selectLayoutError, selectFields, selectFieldsLoading } from "../../store/LayoutSlice";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import updateDoc from "../../store/actions/UpdateDoc";
import { AppDispatch } from "../../store/store";
import GuestTemplate from "../../templates/GuestTemplate";
import { DocAction, DocMode } from "../../typings/DocTypes";
import { FieldEntity } from "../../typings/FieldTypes";
import { FieldValueEvent, JSONFieldValues, FieldValue, JSONFieldValue } from "../../typings/FieldValueTypes";
import { LoadingStatus } from "../../typings/GeneralTypes";

//#region Types
/** ドキュメントアクションを扱うイベントハンドラー */
export type DocActionHandler = (action: DocAction) => void;

/** フィールド値 (Primitives/Instances) を扱うイベントハンドラー */
export type FieldValueHandler = (entity: FieldValueEvent) => Promise<void>;

/** ドキュメントモードを変更するためのイベントハンドラー */
export type SetDocumentModeHandler = (mode: DocMode) => void;

export type AttachmentFileHandler = (action: "set" | "delete", attachmentFile: AttachmentFile) => void;

/**
 * アップロード用の添付ファイル
 */
export type AttachmentFile = {
	field_id: string;
	// 現状は 1 ファイルのみ
	file?: File;
	file_name?: string;
	file_size?: number;
	table_id?: string;
	table_row?: number;
};

/**
 * アップロード用の添付ファイルのマップ
 */
export type AttachmentFiles = { [key: string]: AttachmentFile };

//#endregion

/**
 * フィールドの入力値を送出用のJSON形式に変換します
 *
 * @param v
 * @param field
 * @returns
 */
const fieldValueToJson = (v: FieldValue, field: FieldEntity): JSONFieldValue | void => {
	// 定義済みなら該当のデータ型名、未定義なら文字列として扱う
	const type: string = JSONFieldValueTypeMap[field.field_type] || "S";

	let result: JSONFieldValue | undefined;

	// 配列値の処理
	if (v.values) {
		// FIXME 暫定的に配列データは 0x01 を区切り文字として文字列化している。ちゃんと配列を定義して送るべき。
		if (v.values.length) {
			result = { [type]: v.values.join("\x01") };
		}
		// 未選択だったら empty 扱い
		else {
			result = { E: true };
		}
	}

	// マップ値の処理
	else if ("valueMap" in v) {
		// 新規作成で null は送らない
		if (v.valueMap === null || v.valueMap === undefined) {
			return;
		}
		// 添付ファイルは送らない
		else if (v.valueMap.type !== "attachment") {
			result = { [type]: v.valueMap };
		}
	}

	// プリミティブ値の処理
	else if ("value" in v) {
		if (v.value === null || v.value === undefined) {
			result = { E: true };
		} else {
			result = { [type]: v.value };
		}
	}

	// テーブルの値の処理
	if (result && Object.keys(result).length) {
		if (v.table_id) result.table_id = v.table_id;
		if (v.table_row) result.table_row = v.table_row;
	}

	return result;
};

const errorHandler = (reason: any, toastSubject: string, callback: () => void) => {
	console.error(reason);
	callback();
	toast(<ToastContent subject={toastSubject} message={reason?.message} />, {
		type: toast.TYPE.ERROR,
		autoClose: false,
	});
};

const GuestDocumentView: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const form_id = match.params.form_id;

	const dispatch: AppDispatch = useDispatch();

	const { id: workspace_id } = useSelector(selectWorkspace);

	const { createFormByIdSelector, fetchFormWithoutAuth, encodedFormPassword, formFetchingError, formFetchingStatus } = useFormList();
	const form = useSelector(createFormByIdSelector(form_id));

	const fields = useSelector(selectFields);
	const fieldsLoading = useSelector(selectFieldsLoading);
	const fieldsError = useSelector(selectLayoutError);
	const docMode = useSelector(selectDocMode);
	const values = useSelector(docValueSelectors.selectEntities);

	const [layoutHtml, setLayoutHtml] = useState<string>("");
	const [layoutLoading, setLayoutLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [isSomeValueInvalid, setIsSomeValueInvalid] = useState<boolean>(true);
	const [sending, setSending] = useState<LoadingStatus>(LoadingStatus.Initial);

	const breakpointsMatches = useMediaQuery(theme.breakpoints.up("md"));

	const [attachmentFiles, setAttachmentFiles] = useState<AttachmentFiles>({});

	const onAttach: AttachmentFileHandler = (action, { field_id, file, file_name, table_id, table_row }) => {
		if (action === "set") {
			setAttachmentFiles((state) => ({ ...state, [field_id]: { field_id, file, file_name, table_id, table_row } }));
		} else if (action === "delete") {
			setAttachmentFiles(({ [field_id]: _removed, ...remains }) => remains);
		}
	};

	/** ドキュメントモードを変更するハンドラー */
	const handleSetMode: SetDocumentModeHandler = (mode: DocMode) => {
		dispatch(setMode(mode));
	};

	/** ユーザーの入力値更新 */
	const onChange: FieldValueHandler = async (entity: FieldValueEvent): Promise<void> => {
		dispatch(updateDoc({ content: { values: [entity] } }));
	};

	//#region ドキュメントアクション
	const onAction: DocActionHandler = (action: DocAction) => {
		switch (action.type) {
			case DocActionType.Create:
				(async () => {
					setSending(LoadingStatus.Pending);
					const jsonValues: JSONFieldValues = {};
					for (const field_id in values) {
						const v = values[field_id] || ({} as FieldValue);
						const f = fields[field_id] || ({} as FieldEntity);
						const value = fieldValueToJson(v, f);
						if (value !== undefined) jsonValues[field_id] = value;
					}

					const res = await postDoc({ form_id, values: jsonValues }, { workspace_id, encodedFormPassword }).catch((reason) =>
						errorHandler(reason, "ドキュメントの作成に失敗しました", () => setSending(LoadingStatus.Error))
					);

					const { id: docId } = res || {};

					if (!docId) return;

					//#region 添付ファイルをアップロード

					let errorOccurred = false;

					for (const key in attachmentFiles) {
						if (errorOccurred) break;

						const name = fields[key]?.name;
						const { field_id, file_name, file, table_id, table_row } = attachmentFiles[key] || {};

						if (!file_name || !file) continue;

						const toastId = v4();

						toast(<ToastContent subject="アップロードしています..." message={file_name} />, {
							toastId,
							type: toast.TYPE.DEFAULT,
							autoClose: false,
							closeOnClick: false,
							closeButton: false,
						});

						await prepareAttachmentPost(docId, { field_id, file_name, table_id, table_row }, { workspace_id })
							.then(({ url, fields }) => {
								return postAttachment({ url, fields, file }, { workspace_id });
							})
							.then(() => {
								toast.update(toastId, {
									render: <ToastContent subject="アップロード完了" message={file_name} />,
									type: toast.TYPE.SUCCESS,
									autoClose: 2000,
									closeOnClick: true,
									closeButton: true,
								});
							})
							.catch((reason) => {
								console.error(reason);
								toast.update(toastId, {
									render: <ToastContent subject="添付ファイルのアップロードに失敗しました" message={`${file_name} (${name})`} />,
									type: toast.TYPE.ERROR,
									autoClose: false,
									closeButton: true,
								});
								setSending(LoadingStatus.Error);
								errorOccurred = true;
							});

						// onChange({ id: field_id, valueMap: { type: "attachment", file_name, file_path } });
					}

					// すべての添付ファイルがアップロードされていなければアクションは実行しない
					if (errorOccurred) return;

					//#endregion

					await postDocAction(docId, { action: { type: DocActionType.Create } }, { workspace_id, encodedFormPassword })
						.then(() => {
							setSending(LoadingStatus.Fulfilled);
							dispatch(setMode(DocMode.Sent));
						})
						.catch((reason) => errorHandler(reason, "ドキュメントの送信に失敗しました", () => setSending(LoadingStatus.Error)));
				})().catch((reason) => errorHandler(reason, "送信中に失敗しました", () => setSending(LoadingStatus.Error)));
				return;
		}
	};
	//#endregion

	//#region 無効値のトースト通知
	useEffect(() => {
		setIsSomeValueInvalid(false);
		for (const key in values) {
			const { id, is_valid, validation_message } = values[key] as FieldValue;
			const toastId = `InvalidFieldValue:${id}`;
			if (toast.isActive(toastId)) {
				!is_valid ? toast.update(toastId, { render: validation_message }) : toast.dismiss(toastId);
			} else {
				!is_valid && toast(validation_message, { toastId, type: toast.TYPE.ERROR, autoClose: false });
			}

			if (!is_valid) {
				setIsSomeValueInvalid(true);
			}
		}
	}, [values]);
	//#endregion

	//#region ステート掃除
	useEffect(() => {
		setLayoutHtml("");
		setLayoutLoading(LoadingStatus.Initial);
		dispatch(clearDoc(null));
	}, [form_id]);
	//#endregion

	//#region フォーム取得
	useEffect(() => {
		if (formFetchingStatus === LoadingStatus.Pending || formFetchingStatus === LoadingStatus.Error) return;

		if (workspace_id && form_id) {
			fetchFormWithoutAuth(workspace_id, form_id).then(() => {
				dispatch(setMode(DocMode.Edit));
			});
		}
	}, [workspace_id, form_id, fetchFormWithoutAuth]);
	//#endregion

	//#region デザイン取得
	useEffect(() => {
		const layout_id = form?.layout_id;

		if (layout_id && workspace_id) {
			getLayoutData(layout_id, { workspace_id, encodedFormPassword }, form_id).then((rawHtml) => {
				setLayoutHtml(rawHtml);
				setLayoutLoading(LoadingStatus.Loaded);
			});
			dispatch(fetchFieldsById({ layout_id, options: { workspace_id, encodedFormPassword }, form_id }));
		}
	}, [form?.layout_id, form_id, workspace_id]);
	//#endregion

	useLayoutEffect(() => {
		// 確認時
		if (docMode === DocMode.Confirm) {
			for (const [id, field] of Object.entries(fields)) {
				if (field.required) {
					const fieldValue = values[id];

					const val: FieldValue = { id };
					if (fieldValue?.value !== undefined) {
						val.value = fieldValue.value ?? null;
						val.values = fieldValue.values;
					} else if (fieldValue?.valueMap !== undefined) {
						val.valueMap = fieldValue.valueMap ?? null;
					}

					dispatch(updateDoc({ content: { values: [val] } }));
				}
			}
		}
	}, [docMode, fields]);

	//
	// 以下レンダリング
	//
	if (!uuidValidate(form_id)) {
		return (
			<GuestTemplate>
				<Box data-testid="guest-document-view-invalid-doc-id">
					<NavigationMessage message="URL が間違っています" />
				</Box>
			</GuestTemplate>
		);
	}

	if (formFetchingError) {
		const errorName = formFetchingError?.name;

		const missing = errorName === "PasswordMissingError";
		const mismatch = errorName === "PasswordMismatchError";

		if (missing || mismatch) {
			const errorMessage = mismatch ? formFetchingError?.message : undefined;

			return (
				<GuestTemplate>
					<Box mt={4} display="flex" justifyContent="center">
						<FormPasswordLockCard form_id={form_id} errorMessage={errorMessage} />
					</Box>
				</GuestTemplate>
			);
		}

		return (
			<GuestTemplate>
				<CenterTwoThirdsBox data-testid="guest-document-view-invalid-form">
					<ErrorMessageCard title="アクセスできません" error={formFetchingError} />
				</CenterTwoThirdsBox>
			</GuestTemplate>
		);
	}

	// 読み込み状況
	const statuses = [layoutLoading, fieldsLoading];
	const isLoading: boolean = statuses.some((s) => s === LoadingStatus.Pending || s === LoadingStatus.Initial);
	const loaded: boolean = statuses.every((s) => s === LoadingStatus.Loaded) && form_id === form?.id;
	const errored: boolean = statuses.some((s) => s === LoadingStatus.Error);

	if (errored) {
		const hint = fieldsError?.message;
		return (
			<GuestTemplate drawer={<DocumentViewDrawer />}>
				<Box data-testid="guest-document-view-doc-loading-failed">
					<NavigationMessage message="ドキュメントの読み込みに失敗しました" hint={hint} />
				</Box>
			</GuestTemplate>
		);
	}

	return (
		<GuestTemplate isLoading={isLoading} pageName={form?.name}>
			<Box display="flex" justifyContent={breakpointsMatches ? "center" : "start"} data-testid="guest-document-view-root">
				{loaded && (
					<GuestLayoutHTMLContent
						mode={docMode}
						fields={fields}
						values={values}
						onAction={onAction}
						onChange={onChange}
						onAttach={onAttach}
						setMode={handleSetMode}
						sending={sending}
						fieldsLoading={fieldsLoading}
						layoutHtml={layoutHtml}
						isSomeValueInvalid={isSomeValueInvalid}
					/>
				)}
			</Box>
		</GuestTemplate>
	);
};

export default GuestDocumentView;
