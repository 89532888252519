import { InvalidValueMessageType } from "../constants/FieldConst";
import { CheckboxFieldEntity } from "../typings/FieldTypes";

import { FieldValidator, ValidateResult } from "./Validator";

/**
 * チェックボックスフィールドの入力値検証クラス
 */
export class CheckboxFieldValidator extends FieldValidator<CheckboxFieldEntity> {
	validate(_value: string | null | undefined): ValidateResult {
		throw new Error("Not implements. チェックボックスでは対応していない呼び出し");
	}

	validateAll(values: string[] | undefined): ValidateResult {
		if (this.field.required) {
			if (values === undefined || values === null || values.length === 0) {
				return this.invalid(InvalidValueMessageType.Required);
			}

			// 選択されている項目のうち 1 つでも無効な項目値（Nullish,空文字列）が含まれていれば NG とする
			const emptyValueIndex = values.findIndex((value) => {
				return value === undefined || value === null || value === "";
			});
			if (emptyValueIndex >= 0) {
				return this.invalid(InvalidValueMessageType.Required);
			}
		}

		return this.valid();
	}
}
