import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

type DeleteFormSecretResponse = {
	success: true;
};

/**
 * 	ロゴを 削除します
 *
 * @param options
 */
export const deleteWorkspaceLogo = async (options: ApiOptions): Promise<DeleteFormSecretResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.delete("/workspaces/logo", reqConfig).catch(axiosErrorHandler);

	return data;
};
