/**
 * ドキュメントのアクションタイプ
 */
export const DocActionType = {
	Create: "create",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DocActionType = typeof DocActionType[keyof typeof DocActionType];

/** ドキュメントステップのシステム生成値 */
export const DocSystemSteps = {
	Draft: {
		Id: "00000000-0000-4000-8000-000000000000",
		Name: "下書き",
	},
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DocSystemSteps = typeof DocSystemSteps[keyof typeof DocSystemSteps];

/**
 * ステップステータスの日本語変換表
 */
export const StepStatusNames = {
	waiting: "処理待ち",
	processing: "処理中",
	completed: "完了",
	failed: "失敗",
	error: "エラー",
};

/**
 * アップロード時に禁止する拡張子
 */
export const BannedExtensions = [
	// cspell:disable
	"ade",
	"adp",
	"apk",
	"appx",
	"appxbundle",
	"as",
	"asp",
	"aspx",
	"bat",
	"bin",
	"cab",
	"cfc",
	"cfm",
	"cfml",
	"cfr",
	"cfswf",
	"chm",
	"cmd",
	"com",
	"cpl",
	"dll",
	"dmg",
	"ex",
	"ex_",
	"exe",
	"hbxml",
	"hta",
	"ins",
	"iso",
	"isp",
	"jar",
	"js",
	"jse",
	"jsp",
	"jspx",
	"jws",
	"lib",
	"lnk",
	"mde",
	"msc",
	"msi",
	"msix",
	"msixbundle",
	"msp",
	"mst",
	"mxml",
	"nsh",
	"php",
	"pif",
	"ps1",
	"scr",
	"sct",
	"shb",
	"swc",
	"sws",
	"sys",
	"vb",
	"vbe",
	"vbs",
	"vxd",
	"wsc",
	"wsf",
	"wsh",
	// cspell:enable
];
