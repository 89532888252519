import { VFC } from "react";

import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import { LoadingStatus } from "../typings/GeneralTypes";

type Props = {
	loading: LoadingStatus;
	onClick: () => void;
};

const FormEmptyCard: VFC<Props> = ({ loading, onClick }) => {
	return (
		<Card data-testid="form-empty-card-root">
			<CardContent>
				<Box m={3} justifyContent="center">
					<Typography variant="h5" component="p">
						<b>下のボタンからフォームを追加しましょう！</b>
					</Typography>
					<Grid container justifyContent="center">
						<Box mt={4} data-testid="form-empty-card-form-add-btn">
							<LoadingButton
								color="primary"
								disabled={loading === LoadingStatus.Pending}
								startIcon={<AddIcon />}
								loading={loading === LoadingStatus.Pending || loading === LoadingStatus.Loaded}
								onClick={onClick}
								size="large"
								variant="contained"
							>
								フォーム追加
							</LoadingButton>
						</Box>
					</Grid>
				</Box>
			</CardContent>
		</Card>
	);
};

export default FormEmptyCard;
