export const WorkspaceConst = {
	name: { Name: "ワークスペースの名前", MinLength: 3, MaxLength: 40 },
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WorkspaceConst = typeof WorkspaceConst[keyof typeof WorkspaceConst];

/**
 * ワークスペースロックダウン時のメッセージのマップ
 */
export const LockedMessageMap: { [reason: string]: string } = {
	"": "ワークスペースは閉鎖されています。",
	expired: "利用期日を過ぎました。",
	trial_expired: "無料お試し期間が終了しました。",
};
