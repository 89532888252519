import React from "react";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

class LoginWithRedirect extends React.Component<WithAuth0Props> {
	render() {
		const { loginWithRedirect } = this.props.auth0;
		loginWithRedirect({ redirectUri: window.location.origin });
		return <>Redirecting...</>;
	}
}

export default withAuth0(LoginWithRedirect);
