import { VFC } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Box, ThemeProvider, StyledEngineProvider } from "@mui/material";

import { NotificationTracker } from "./components/NotificationTracker";
import ProtectedRoute from "./components/ProtectedRoute";
import theme from "./components/Theme";
import UserAuthProvider from "./components/UserAuthProvider";
import LoginWithRedirect from "./pages/LoginWithRedirect";
import WorkspaceLocked from "./pages/WorkspaceLocked";
import DevelopView1 from "./pages/develop/DevelopView1";
import DocumentNew from "./pages/docs/DocumentNew";
import DocumentView from "./pages/docs/DocumentView";
import AccessDeniedPage from "./pages/error/AccessDenied";
import ErrorPage from "./pages/error/ErrorPage";
import ArchivedFormList from "./pages/forms/ArchivedFormList";
import FormAccess from "./pages/forms/FormAccess";
import FormHome from "./pages/forms/FormHome";
import FormImport from "./pages/forms/FormImport";
import FormList from "./pages/forms/FormList";
import FormMaintenance from "./pages/forms/FormMaintenance";
import FormServiceLink from "./pages/forms/FormServiceLink";
import FormSettings from "./pages/forms/FormSettings";
import FormWizard from "./pages/forms/FormWizard";
import GuestDocumentView from "./pages/guest/GuestDocumentView";
import AdminAuthentications from "./pages/workspaces/AdminAuthentications";
import AdminContract from "./pages/workspaces/AdminContract";
import AdminCustomize from "./pages/workspaces/AdminCustomize";
import AdminSettings from "./pages/workspaces/AdminSettings";
import store from "./store/store";
import "./app.css";

export const APP_NAME: string = "コラボフォーム";

const Router: VFC = () => {
	return (
		<HashRouter>
			<Switch>
				<ProtectedRoute exact path="/docs/:document_id" component={DocumentView} />
				<ProtectedRoute path="/forms/archived" component={ArchivedFormList} />
				<ProtectedRoute exact path="/forms/:form_id/new" component={DocumentNew} />
				<ProtectedRoute exact path="/forms/:form_id/wizard" component={FormWizard} />
				<ProtectedRoute exact path="/forms/:form_id/settings" component={FormSettings} />
				<ProtectedRoute exact path="/forms/:form_id/connect" component={FormServiceLink} />
				<ProtectedRoute exact path="/forms/:form_id/import" component={FormImport} />
				<ProtectedRoute exact path="/forms/:form_id/access" component={FormAccess} />
				<ProtectedRoute exact path="/forms/:form_id/maintenance" component={FormMaintenance} />
				<ProtectedRoute exact path="/forms/:form_id" component={FormHome} />
				<ProtectedRoute path="/forms" component={FormList} />
				<ProtectedRoute path="/admin/contract" component={AdminContract} />
				<ProtectedRoute path="/admin/customize" component={AdminCustomize} />
				<ProtectedRoute path="/admin/authentications" component={AdminAuthentications} />
				<ProtectedRoute path="/admin" component={AdminSettings} />
				<Route path="/signin" component={LoginWithRedirect} />
				<Route path="/error/access-denied" component={AccessDeniedPage} />
				<Route path="/error" component={ErrorPage} />
				<ProtectedRoute path="/workspace-locked" component={WorkspaceLocked} />
				{/* 開発用の隠しページ（実装確認用） */}
				<ProtectedRoute path="/develop/view1/:id" component={DevelopView1} />
				<ProtectedRoute path="/develop/view1" component={DevelopView1} />
				<Route path="/g/:form_id" component={GuestDocumentView} />
				<Route>
					<Redirect to="/forms" />
				</Route>
			</Switch>
		</HashRouter>
	);
};

const App: VFC = () => {
	return (
		<Provider store={store}>
			<UserAuthProvider>
				<HelmetProvider>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<Box data-testid="app-root">
								<ToastContainer
									position="top-right"
									transition={Slide}
									autoClose={3000}
									hideProgressBar
									newestOnTop={false}
									pauseOnHover
									draggable={false}
								/>
								<NotificationTracker />
								<Router />
							</Box>
						</ThemeProvider>
					</StyledEngineProvider>
				</HelmetProvider>
			</UserAuthProvider>
		</Provider>
	);
};

export default App;
