import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { fetchNotifications } from "../store/NotificationsSlice";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";

// 既定のポーリング間隔 (30 秒)
const DefaultPolingSpan = 30_000;

/**
 * 通知取得 API 呼び出し用カスタムフック
 * @returns
 * - trackNotifications 通知取得を開始します
 */
const useNotificationMonitor = () => {
	const dispatch: AppDispatch = useDispatch();
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const workspace = useSelector(selectWorkspace);

	const workspace_id = workspace.id;

	const trackNotifications = useCallback(async () => {
		if (!workspace_id || !isAuthenticated) {
			return;
		}

		const auth_token = await getAccessTokenSilently();
		await dispatch(
			fetchNotifications({
				options: {
					workspace_id,
					auth_token,
				},
			})
		);
	}, [workspace_id, isAuthenticated, dispatch, getAccessTokenSilently]);

	return { trackNotifications };
};

// 監視タイマーのタイマー ID (コンポーネント共通。このコンポーネントは複数設置しない前提)
let timerId = 0;

/**
 * グローバルなタイマーリソースを解放します。
 *
 * タイマーが非稼働であれば何もしません。
 */
const cleanupTimer = () => {
	if (timerId) {
		window.clearTimeout(timerId);
		timerId = 0;
	}
};

/**
 * 通知メッセージ追跡コンポーネント
 *
 * グローバルに 1 個だけ設置してください。
 */
export const NotificationTracker: React.VFC = () => {
	const { trackNotifications } = useNotificationMonitor();
	const timeout_ms = DefaultPolingSpan;

	useEffect(() => {
		const doMonitor = () => {
			cleanupTimer();
			trackNotifications().finally(() => {
				// トラッキングの成功可否に関係なく、追跡を再開させる
				timerId = window.setTimeout(doMonitor, timeout_ms);
			});
		};

		// 初回起動
		doMonitor();

		// コンポーネントがアンマウントされる時にはグローバルなタイマーを確実に停止しておく
		return () => {
			cleanupTimer();
		};
	}, [trackNotifications, timeout_ms]);

	return <></>;
};
