import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

/** デザイン取り込み要求時のレスポンスペイロード */
export type PostDesignImportResponse = {
	notification: {
		id: string;
		correlation_id: string;
	};
};

/**
 * デザイン取り込み要求を行います。
 *
 * @param form_id - 取り込み要求を行う form_id
 * @param options - API のオプション
 */
export const postDesignImport = async (form_id: string, options: ApiOptions): Promise<PostDesignImportResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const reqData = {
		apply_mode: "immediate",
	};

	const res = await axios.post(`/forms/${form_id}/designs/import`, reqData, reqConfig).catch(axiosErrorHandler);

	const response = { ...res.data };
	return response;
};
