import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import { LoadingButton } from "@mui/lab";
import { Box, DialogActions, Divider, List, ListItem, TextField } from "@mui/material";

import { PatchWorkspaceRequest } from "../apis/workspace/patch";
import { WorkspaceConst } from "../constants/WorkspaceConst";
import { saveAsyncWorkspace, Workspace } from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";

import CopyToClipboardField from "./CopyToClipboardField";
import Form from "./Form";
import ToastContent from "./ToastContent";

type FormState = {
	name: string;
};

type Props = {
	workspace: Workspace;
};

const WorkspaceUpdateForm: React.VFC<Props> = ({ workspace }) => {
	const { getAccessTokenSilently } = useAuth0();
	const dispatch: AppDispatch = useDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const { errors, formState, handleSubmit, register, reset } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			name: workspace.name,
		},
	});
	const { isValid, dirtyFields } = formState;

	const onSubmit = handleSubmit(async (data: Workspace) => {
		setLoading(true);

		const patchData: PatchWorkspaceRequest = {
			id: workspace.id,
		};
		dirtyFields.name !== undefined && (patchData.name = data.name);

		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});
		const workspace_id = workspace.id;
		const auth_token = await getAccessTokenSilently();
		dispatch(saveAsyncWorkspace({ patchData, options: { auth_token, workspace_id } }))
			.then(() => {
				reset(data);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: null,
					render: "ワークスペース情報を保存しました",
				});
			})
			.catch((reason: any) => {
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
			});
		setLoading(false);
	});

	return (
		<Form onSubmit={onSubmit} data-testid="workspace-update-form-root">
			<Box pb={1.8}>
				<List>
					<ListItem>
						<TextField
							id="name"
							name="name"
							label={WorkspaceConst.name.Name}
							helperText={errors.name?.message || `${WorkspaceConst.name.MinLength}-${WorkspaceConst.name.MaxLength} 文字以内`}
							variant="outlined"
							autoComplete="off"
							InputLabelProps={{
								required: true,
							}}
							fullWidth
							error={errors.name ? true : false}
							inputRef={register({
								required: { value: true, message: `${WorkspaceConst.name.Name}は必須です` },
								minLength: {
									value: WorkspaceConst.name.MinLength,
									message: `${WorkspaceConst.name.Name}は ${WorkspaceConst.name.MinLength} 文字以上にしてください`,
								},
								maxLength: {
									value: WorkspaceConst.name.MaxLength,
									message: `${WorkspaceConst.name.Name}は ${WorkspaceConst.name.MaxLength} 文字以内にしてください`,
								},
							})}
							data-testid="workspace-update-form-name-field"
						/>
					</ListItem>
					<ListItem>
						<CopyToClipboardField
							value={workspace?.subdomain || ""}
							name="id"
							disabled
							label="サブドメイン"
							variant="outlined"
							autoComplete="off"
							fullWidth
							data-testid="workspace-update-form-subdomain-field"
						/>
					</ListItem>
					<ListItem>
						<CopyToClipboardField
							value={workspace.id}
							name="id"
							disabled
							label="ワークスペース ID"
							variant="outlined"
							autoComplete="off"
							fullWidth
							data-testid="workspace-update-form-workspace-id-field"
						/>
					</ListItem>
				</List>
			</Box>

			<Divider />
			<DialogActions>
				<LoadingButton
					type="submit"
					variant="contained"
					color="primary"
					disabled={loading || !isValid || !Object.keys(dirtyFields).length}
					loading={loading}
					data-testid="workspace-update-form-create-btn"
				>
					保存
				</LoadingButton>
			</DialogActions>
		</Form>
	);
};

export default WorkspaceUpdateForm;
