import { VFC } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { Avatar, Box, Card, Grid, Typography } from "@mui/material";

import FadingModal from "./FadingModal";
import theme from "./Theme";

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const DocumentSentModal: VFC<Props> = ({ isOpen, onClose }) => {
	return (
		<FadingModal open={isOpen} onClose={onClose}>
			<Card elevation={12} data-testid="document-sent-modal-root">
				<Box my={4} mx={6}>
					<Grid container justifyContent="center" alignItems="center" direction="column">
						<Avatar
							sx={{
								margin: theme.spacing(2),
								backgroundColor: theme.palette.primary.main,
							}}
						>
							<CheckIcon fontSize="large" />
						</Avatar>
						<Box>
							<Typography variant="h4" component="p">
								<b>送信しました</b>
							</Typography>
						</Box>
						<Box mt={0.5}>
							<Typography variant="body1" component="p">
								ブラウザのタブを閉じてください
							</Typography>
						</Box>
					</Grid>
				</Box>
			</Card>
		</FadingModal>
	);
};

export default DocumentSentModal;
