import { Dispatch, SetStateAction, useCallback, useEffect, useState, VFC } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, DialogActions, List, ListItem, TextField, Typography } from "@mui/material";

import { getAuthentication } from "../apis/authentications/get";
import { patchAuthentication, PatchAuthenticationRequest } from "../apis/authentications/patch";
import { AuthenticationConst } from "../constants/AuthenticationConst";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { OstensibleAuthentication } from "../typings/AuthenticationTypes";
import { LoadingStatus } from "../typings/GeneralTypes";

import Form from "./Form";
import SettingCardsSkeleton from "./SettingCardsSkeleton";
import theme from "./Theme";

type Props = {
	onClose: () => void;
	authentication: OstensibleAuthentication | undefined;
	setAuthentications: Dispatch<SetStateAction<OstensibleAuthentication[]>>;
};

type FormState = {
	authentication_name: string;
	provider: string;
	endpoint: string;
	user_id: string;
	basic_user?: string;
	basic_password?: string;
};

const AuthenticationSettingsForm: VFC<Props> = ({ onClose, authentication, setAuthentications }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [visibleBasicAuth, setVisibleBasicAuth] = useState(false);

	const { errors, formState, handleSubmit, register, setValue } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			provider: "collaboflow",
		},
		shouldUnregister: false,
	});
	const { isValid, dirtyFields } = formState;
	const [patchLoading, setPatchLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const onSubmit = handleSubmit(async (data: FormState) => {
		if (authentication?.id) {
			setPatchLoading(LoadingStatus.Pending);

			const patchData: PatchAuthenticationRequest = {
				name: data.authentication_name,
			};

			const auth_token = await getAccessTokenSilently();

			await patchAuthentication(authentication.id, patchData, { auth_token, workspace_id })
				.then(({ id, name }) => {
					setPatchLoading(LoadingStatus.Fulfilled);
					setAuthentications((state) => {
						return state.map((auth) => {
							if (auth.id === id) auth.name = name;
							return auth;
						});
					});
					onClose();
				})
				.catch((error: { message: string }) => {
					console.log(error);
					setPatchLoading(LoadingStatus.Error);
					setErrorMessage(error.message);
				});
		}
	});

	const fetchAuthentication = useCallback(async () => {
		if (authentication?.id) {
			const auth_token = await getAccessTokenSilently();
			const res = await getAuthentication(authentication.id, { workspace_id: workspace_id, auth_token });
			setValue("endpoint", res.endpoint);
			setValue("authentication_name", res.name);
			setValue("user_id", res.user_id);
			// BASIC 認証はユーザー名が設定されている時のみ表示する
			if (typeof res.basic_user === "string" && res.basic_user.length) {
				setValue("basic_user", res.basic_user);
				setVisibleBasicAuth(true);
			}
		}

		setLoading(LoadingStatus.Fulfilled);
	}, [authentication?.id, getAccessTokenSilently, setValue, workspace_id]);

	useEffect(() => {
		workspace_id && authentication?.id && fetchAuthentication();
	}, [authentication?.id, fetchAuthentication, workspace_id]);

	return (
		<Card variant="outlined" data-testid="authentication-settings-form-root">
			<CardHeader title="認証情報の編集" />
			<Form key="authentication-settings-form" onSubmit={onSubmit}>
				<CardContent>
					<Box width={500} maxWidth="100%">
						{authentication && loading === LoadingStatus.Fulfilled ? (
							<List>
								<ListItem>
									<TextField
										fullWidth
										id="authentication_name"
										name="authentication_name"
										label={AuthenticationConst.AuthenticationName.Name}
										variant="outlined"
										autoComplete="off"
										helperText={errors.authentication_name?.message || "認証を識別するための名前"}
										InputLabelProps={{
											required: true,
										}}
										inputRef={register({
											required: { value: true, message: `${AuthenticationConst.AuthenticationName.Name}は必須です` },
										})}
										error={errors.authentication_name ? true : false}
									/>
								</ListItem>
								<ListItem>
									<TextField
										fullWidth
										disabled
										id="endpoint"
										name="endpoint"
										label={AuthenticationConst.Endpoint.Name}
										variant="outlined"
										autoComplete="off"
										helperText={"例: https://cloud.collaboflow.com/example/api/index.cfm"}
										InputLabelProps={{
											required: false,
										}}
										inputRef={register({
											required: { value: true, message: `${AuthenticationConst.Endpoint.Name}は必須です` },
										})}
										error={errors.endpoint ? true : false}
									/>
								</ListItem>
								<ListItem>
									<TextField
										fullWidth
										disabled
										id="user_id"
										name="user_id"
										label={AuthenticationConst.UserId.Name}
										variant="outlined"
										autoComplete="off"
										helperText={"例: https://cloud.collaboflow.com/example/api/index.cfm"}
										InputLabelProps={{
											required: false,
										}}
										inputRef={register({
											required: { value: true, message: `${AuthenticationConst.UserId.Name}は必須です` },
										})}
										error={errors.user_id ? true : false}
									/>
								</ListItem>
								<ListItem>
									<TextField
										fullWidth
										disabled
										value="************"
										id="api_key"
										name="api_key"
										label={AuthenticationConst.ApiKey.Name}
										variant="outlined"
										autoComplete="off"
										helperText={"システム管理エリア > 環境設定 > REST API で発行した API キー"}
										type="password"
									/>
								</ListItem>
								{/* BASIC 認証 */}
								{visibleBasicAuth && (
									<>
										<ListItem>BASIC 認証 (設定している場合のみ)</ListItem>
										<ListItem>
											<TextField
												fullWidth
												disabled
												id="basic_user"
												name="basic_user"
												label={AuthenticationConst.BasicUser.Name}
												variant="outlined"
												autoComplete="off"
												helperText={"システム管理エリア > 環境設定 > BASIC 認証の共通ユーザー名"}
												inputRef={register()}
												error={errors.basic_user ? true : false}
											/>
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												disabled
												id="basic_password"
												name="basic_password"
												value="************"
												label={AuthenticationConst.BasicPassword.Name}
												variant="outlined"
												autoComplete="off"
												helperText={"システム管理エリア > 環境設定 > BASIC 認証の共通パスワード"}
												type="password"
											/>
										</ListItem>
									</>
								)}
							</List>
						) : (
							<SettingCardsSkeleton />
						)}
					</Box>
				</CardContent>
				<DialogActions>
					<Button onClick={onClose} tabIndex={-1} disabled={patchLoading === LoadingStatus.Pending}>
						キャンセル
					</Button>
					<LoadingButton
						disabled={!isValid || !Object.keys(dirtyFields).length || patchLoading === LoadingStatus.Pending}
						type="submit"
						variant="contained"
						loading={patchLoading === LoadingStatus.Pending}
					>
						保存
					</LoadingButton>
				</DialogActions>
			</Form>
			{errorMessage && (
				<Box>
					<Typography variant="body1" gutterBottom color={theme.palette.error.main}>
						{errorMessage}
					</Typography>
				</Box>
			)}
		</Card>
	);
};

export default AuthenticationSettingsForm;
