import { Box, colors } from "@mui/material";

import { FieldType } from "../constants/FieldConst";
import { AttachmentLinkCreateHandler, FieldValueHandler } from "../pages/docs/DocumentView";
import { AttachmentFileHandler } from "../pages/guest/GuestDocumentView";
import { MapFieldValue, StringFieldValue } from "../typings/FieldValueTypes";

import AttachmentField from "./fields/AttachmentField";
import CheckboxField from "./fields/CheckboxField";
import DateField from "./fields/DateField";
import DropdownField from "./fields/DropdownField";
import MultilineField from "./fields/MultilineField";
import NumberField from "./fields/NumberField";
import RadioButtonField from "./fields/RadioButtonField";
import TextField from "./fields/TextField";

/** フィールドコンポーネントのマッピング */
const fieldComponents: {
	[field_type: string]: React.VFC<any>;
} = {
	// TODO: 実装時にコンポーネントを追記する
	attachment: AttachmentField,
	checkbox: CheckboxField,
	date: DateField,
	// datetime: undefined,
	dropdown: DropdownField,
	// interval: undefined,
	// label: undefined,
	// listbox: undefined,
	multiline: MultilineField,
	number: NumberField,
	radiobutton: RadioButtonField,
	text: TextField,
	// time: undefined,
};

type Props = {
	field_type: FieldType;
	field?: any;
	value: StringFieldValue | undefined;
	values?: string[];
	valueMap?: MapFieldValue;
	editable?: boolean;
	onChange: FieldValueHandler;
	onAttach?: AttachmentFileHandler;
	onCreateLink?: AttachmentLinkCreateHandler;
	failed?: boolean;
	disableLink?: boolean;
};

const FieldBase: React.VFC<Props> = ({ field_type, field, value, values, valueMap, editable, onChange, onAttach, onCreateLink, failed, disableLink }) => {
	const SelectedField = fieldComponents[field_type];

	return SelectedField ? (
		<Box
			data-testid="field-base-root"
			height="100%"
			sx={{
				transition: "all 0.3s ease",
				...(failed ? { background: colors.red[100] } : editable && { background: colors.cyan[50] }),
			}}
		>
			<SelectedField
				value={value}
				values={values}
				valueMap={valueMap}
				field={field}
				editable={editable}
				onChange={onChange}
				onAttach={onAttach}
				onCreateLink={onCreateLink}
				disableLink={disableLink}
			/>
		</Box>
	) : (
		<></>
	);
};

export default FieldBase;
