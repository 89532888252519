import { createSlice, createEntityAdapter, createAsyncThunk, SliceCaseReducers, SerializedError, PayloadAction } from "@reduxjs/toolkit";

import { getDocument } from "../apis/docs/get";
import { PatchDocumentRequest, patchDocument } from "../apis/docs/patch";
import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import { DocSystemSteps } from "../constants/DocConst";
import { DocEntity, DocMode } from "../typings/DocTypes";
import { FieldValue, FieldValues, StringFieldValue } from "../typings/FieldValueTypes";
import { LoadingStatus } from "../typings/GeneralTypes";

import { State } from "./Reducers";
import syncDoc from "./actions/SyncDoc";
import updateDoc from "./actions/UpdateDoc";

const fetchDocumentById = createAsyncThunk("doc/fetchDocument", async (arg: { doc_id: string; options: ApiOptions }) => {
	return getDocument(arg.doc_id, arg.options).then((data) => {
		return data;
	});
});

const patchDocumentById = createAsyncThunk("doc/patchDocument", async (arg: { doc_id: string; reqData: PatchDocumentRequest; options: ApiOptions }) => {
	return patchDocument(arg.doc_id, arg.reqData, arg.options).then((data) => {
		return data;
	});
});

const valuesAdapter = createEntityAdapter<FieldValue>();

export type DocState = {
	loading: LoadingStatus;
	error?: SerializedError;
	mode: DocMode;
	info: DocEntity;
	values: FieldValues;
};

const initialState = {
	loading: LoadingStatus.Initial,
	mode: DocMode.Read,
	info: {},
	values: valuesAdapter.getInitialState(),
};

const slice = createSlice<DocState, SliceCaseReducers<DocState>>({
	name: "doc",
	initialState,
	reducers: {
		clear: (state, _acton: PayloadAction<void>) => {
			return { ...initialState, mode: state.mode };
		},
		update: (state, action: PayloadAction<FieldValue>) => {
			valuesAdapter.upsertOne(state.values, action.payload);
		},
		setMode: (state, action: PayloadAction<DocMode>) => {
			state.mode = action.payload;
		},
	},

	extraReducers: (builder) => {
		//#region ドキュメントデータの非同期取得
		// 取得中
		builder.addCase(fetchDocumentById.pending, (_state, _action) => {
			return { ...initialState, loading: LoadingStatus.Pending };
		});

		// 取得成功
		builder.addCase(fetchDocumentById.fulfilled, (state, action) => {
			state.info.id = action.payload.doc_id;
			state.info.workspace_id = action.payload.workspace_id;
			state.info.subject = action.payload.subject;
			state.info.form = action.payload.form;
			state.info.layout = action.payload.layout;
			state.info.step = action.payload.step;
			state.info.working_step = action.payload.working_step;
			state.info.rev = action.payload.rev;
			state.info.created_at = action.payload.created_at;
			state.info.updated_at = action.payload.updated_at;

			const editable = state.info.step.id === DocSystemSteps.Draft.Id;
			if (editable) state.mode = DocMode.Edit;

			// TODO: ステップから editable 属性をエンティティに反映させているが、将来的に機能改善時に修正する
			// const entities: FieldValue[] = Object.entries(action.payload.values).map(([id, value]) => ({ id, value, editable }));

			const entities: FieldValue[] = Object.entries(action.payload.values).map(([id, v]) => {
				if (v.E) {
					return { id, value: null, values: [] };
				}

				if (v.attachment) {
					const { file_name, file_path, file_size } = v.attachment;
					return { id, valueMap: { type: "attachment", file_name, file_path, file_size } };
				}

				const value: StringFieldValue = v.S ?? v.N ?? v.D ?? "";
				let values: string[] | undefined;

				// FIXME 文字列特殊処理：チェックボックス対策
				if (v.S !== undefined) {
					values = v.S.split("\x01");
				}

				return { id, value, values };
			});

			valuesAdapter.setAll(state.values, entities);

			state.loading = LoadingStatus.Loaded;
		});

		// 処理例外、通信エラーなど
		builder.addCase(fetchDocumentById.rejected, (state, action) => {
			state.loading = LoadingStatus.Error;
			state.error = action.error;
			console.error("ドキュメントの読み込みに失敗しました", action.error);
		});
		//#endregion

		//#region ドキュメントデータの非同期更新
		// 更新中
		builder.addCase(patchDocumentById.pending, (state, _action) => {
			state.error = undefined;
			state.loading = LoadingStatus.Pending;
		});

		// 取得成功
		builder.addCase(patchDocumentById.fulfilled, (state, _action) => {
			state.loading = LoadingStatus.Updated;
		});

		// 処理例外、通信エラーなど
		builder.addCase(patchDocumentById.rejected, (state, action) => {
			state.loading = LoadingStatus.Error;
			state.error = action.error;
			console.error("ドキュメントの更新に失敗しました", action.error);
		});
		//#endregion

		//#region updateDoc
		builder.addCase(updateDoc.pending, (_state, _action) => {});
		builder.addCase(updateDoc.fulfilled, (state, action) => {
			const { doc, values } = action.payload;
			if (doc?.subject) {
				state.info.subject = doc.subject;
			}
			if (values) {
				valuesAdapter.upsertMany(state.values, values);
			}
		});
		builder.addCase(updateDoc.rejected, (_state, _action) => {});
		//#endregion

		//#region syncDoc
		// FIXME: レンダリングの負荷との関連で、暫定的に state.loading を変更しないようにコメントアウトしています
		builder.addCase(syncDoc.pending, (state, _action) => {
			state.error = undefined;
			// state.loading = LoadingStatus.Pending;
		});

		builder.addCase(syncDoc.fulfilled, (state, action) => {
			if (action.payload) {
				state.info.rev = action.payload.doc.rev;
				valuesAdapter.upsertMany(state.values, action.payload.updatedValues);
			}
			// state.loading = LoadingStatus.Updated;
		});

		builder.addCase(syncDoc.rejected, (state, action) => {
			state.loading = LoadingStatus.Error;
			state.error = action.error;
			console.error("ドキュメントの同期に失敗しました", action.error);
		});
		//#endregion
	},
});

// アクションたち
export const { clear, update, setMode } = slice.actions;

// AsyncThunk
export { fetchDocumentById, patchDocumentById };

// useSelector 用
export const selectDocLoading = (state: State) => state.doc.loading;
export const docValueSelectors = valuesAdapter.getSelectors((state: State) => state.doc.values);
export const selectDocMode = (state: State) => state.doc.mode;
export const selectDocInfo = (state: State) => state.doc.info;
export const selectDocError = (state: State) => state.doc.error;

export default slice;
