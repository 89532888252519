import { VFC } from "react";

import styled from "@emotion/styled";
import { Box, Card, CardContent, CardProps, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";

const GreenCircularProgress = styled(CircularProgress)({
	color: green[500],
});

const CirclerProgressCard: VFC<CardProps> = ({ children, ...props }) => {
	return (
		<Card data-testid="circler-progress-card-root" {...props}>
			<CardContent>
				<Box display="flex" justifyContent="center" m={2}>
					<GreenCircularProgress size={24} />
				</Box>
				<Box data-testid="circler-progress-card-children">{children}</Box>
			</CardContent>
		</Card>
	);
};

export default CirclerProgressCard;
