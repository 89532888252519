import axios from "axios";

import { Step, WorkingStep } from "../../../typings/StepTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

/**
 * ドキュメントの概要
 */
type DocSummary = {
	/** ドキュメント ID */
	id: string;
	/** 作成日時 */
	created_at: string;
	/** 更新日時 */
	updated_at: string;
	/** 題名 */
	subject: string;
	/** 状態 */
	status: string;
	/** フォーム情報 */
	form: {
		/** フォーム ID */
		id: string;
		/** フォーム名 */
		name: string;
	};
	/** ステップ */
	step?: Step;
	/** 処理中のステップ */
	working_step?: WorkingStep;
};

export type ListDocResponse = {
	docs: DocSummary[];
};

/**
 * 指定フォームのドキュメント一覧を取得します。
 *
 * @param form_id フォーム ID
 * @param options - API のオプション
 * @returns
 */
export const listDoc = async (form_id: string, options: ApiOptions): Promise<ListDocResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	return axios.get<ListDocResponse>(`/forms/${form_id}/docs`, reqConfig).then((res) => res.data);
};
