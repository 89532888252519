import { InvalidValueMessageType } from "../constants/FieldConst";
import { DropdownFieldEntity } from "../typings/FieldTypes";

import { FieldValidator, ValidateResult } from "./Validator";

/**
 * ドロップダウンフィールドの入力値検証クラス
 */
export class DropdownFieldValidator extends FieldValidator<DropdownFieldEntity> {
	validate(value: string | null | undefined): ValidateResult {
		// 必須チェック
		if (this.field.required && [null, "", undefined].includes(value)) {
			return this.invalid(InvalidValueMessageType.Required);
		}

		return this.valid();
	}
}
