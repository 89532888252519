import { ReactNode } from "react";

import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

type Props = ButtonProps & {
	loading?: boolean;
	loadingIcon?: ReactNode;
};

const AdjustedButton = styled(Button)({
	"& .MuiButton-iconSizeLarge": {
		marginBottom: "2px",
	},
});

/** アクションボタン */
const ActionButton: React.VFC<Props> = ({ children, endIcon, loading, loadingIcon, ...props }) => {
	return (
		<AdjustedButton endIcon={loading ? loadingIcon : endIcon} size="large" data-testid="action-button-root" {...props}>
			{children}
		</AdjustedButton>
	);
};

export default ActionButton;
