import React from "react";
import { Link as RouterLink } from "react-router-dom";

import dayjs from "dayjs";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Link, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { FieldValueDictionary } from "../typings/FieldValueTypes";
import { LoadingStatus } from "../typings/GeneralTypes";
import { WorkingStep } from "../typings/StepTypes";

import DocumentStepStatus from "./DocumentStepStatus";
import StyledDrawer from "./StyledDrawer";

type Props = {
	form_id?: string;
	step?: WorkingStep;
	values?: FieldValueDictionary;
	onResendDocument?: () => void;
	isResendDocumentLoaded?: LoadingStatus;
	docDate?: {
		created_at?: Date;
		updated_at?: Date;
	};
};

const DocumentViewDrawer: React.VFC<Props> = ({ step, form_id, onResendDocument, isResendDocumentLoaded, docDate }) => {
	return (
		<StyledDrawer variant="permanent" data-testid="document-view-drawer-root">
			<List>
				<Link component={RouterLink} to={`/forms/${form_id}`} data-testid="drawer-workspace-settings-link">
					<ListItemButton>
						<ListItemIcon>
							<ArrowBackIosIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="フォームに戻る" />
					</ListItemButton>
				</Link>
			</List>
			<Divider />
			{step && (
				<>
					<DocumentStepStatus step={step} />
					<Box px={2}>
						{docDate?.created_at && (
							<Box pb={1}>
								<Typography color="textSecondary">作成日時</Typography>
								<Box display="flex" alignItems="center" px={2} pt={1}>
									<Typography component="p">{dayjs(docDate.created_at).local().format("YYYY/MM/DD HH:mm:ss")}</Typography>
								</Box>
							</Box>
						)}
						{docDate?.updated_at && (
							<Box pb={1}>
								<Typography color="textSecondary">最終更新日時</Typography>
								<Box display="flex" alignItems="center" px={2} pt={1}>
									<Typography component="p">{dayjs(docDate.updated_at).local().format("YYYY/MM/DD HH:mm:ss")}</Typography>
								</Box>
							</Box>
						)}
					</Box>

					{(step.status === "error" || step.status === "failed") && (
						<>
							<Divider />
							<Box pt={2} px={2}>
								<LoadingButton
									loading={isResendDocumentLoaded === LoadingStatus.Pending ? true : false}
									disabled={isResendDocumentLoaded === LoadingStatus.Fulfilled}
									size="large"
									onClick={() => onResendDocument && onResendDocument()}
									variant="contained"
									sx={{ width: "100%" }}
								>
									{isResendDocumentLoaded === "fulfilled" ? "再送信を行いました" : "再送信を行う"}
								</LoadingButton>
							</Box>
						</>
					)}
				</>
			)}
		</StyledDrawer>
	);
};

export default DocumentViewDrawer;
