import { BannedExtensions } from "../constants/DocConst";
import { InvalidValueMessageType } from "../constants/FieldConst";
import { AttachmentFieldEntity } from "../typings/FieldTypes";
import { MapFieldValue } from "../typings/FieldValueTypes";
import { isRequiredString } from "../utils/Validator";

import { FieldValidator, ValidateResult } from "./Validator";

// eslint-disable-next-line no-control-regex
const ForbiddenCharacters = /[<>:"/\\|?*|\x00-\x1F]/;

/**
 * 添付ファイルフィールドの入力値検証クラス
 */
export class AttachmentFieldValidator extends FieldValidator<AttachmentFieldEntity> {
	validate(valueMap: MapFieldValue | null | undefined): ValidateResult {
		if (this.field.required) {
			if (valueMap === undefined || valueMap === null || typeof valueMap !== "object" || !valueMap.file_name) {
				return this.invalid(InvalidValueMessageType.Required);
			}
		}

		const { file_name, file_size } = valueMap || {};

		if (file_name !== undefined) {
			if (!isRequiredString(file_name) || file_name !== file_name.trim() || ForbiddenCharacters.test(file_name)) {
				return this.invalid("不正な添付ファイル名です");
			}

			// 拡張子がない
			const splitted = file_name.split(".");
			if (splitted.length < 2) {
				return this.invalid("添付ファイルの拡張子は必須です");
			}

			// 禁止された拡張子を含む
			const extension = splitted.pop()?.toLowerCase() || "";

			if (BannedExtensions.includes(extension)) {
				return this.invalid(`この拡張子のファイルは添付できません (${BannedExtensions.join(", ")})`);
			}
		}

		if (file_size !== undefined) {
			// 30 MB = 31457280 B
			if (31_457_280 < file_size) {
				return this.invalid("添付ファイルのサイズは最大 30 MB までです");
			}
			if (file_size < 1) {
				return this.invalid("0 バイトのファイルは添付できません");
			}
		}

		return this.valid();
	}
}
