import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { validate as uuidValidate } from "uuid";

import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { Alert, AlertTitle, Box, Button, Card, CardContent, Grid, Link, Typography } from "@mui/material";

import ColorLink from "../../components/ColorLink";
import NavigationMessage from "../../components/NavigationMessage";
import TableView from "../../components/TableView";
import { StepStatusNames } from "../../constants/DocConst";
import { useFormList } from "../../hooks/useFormList";
import { selectFormDocsSelectors, fetchFormDocs, selectFormDocsColumns } from "../../store/FormDocsSlice";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import { AppDispatch } from "../../store/store";
import MainTemplate from "../../templates/MainTemplate";

dayjs.extend(utc);
dayjs.locale("ja");

const FormTop: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const form_id = match.params.form_id;

	const { getAccessTokenSilently } = useAuth0();
	const dispatch: AppDispatch = useDispatch();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const { fetchForm, createFormByIdSelector, isLoaded: formsLoaded } = useFormList();
	const form = useSelector(createFormByIdSelector(form_id));

	const formDocsColumns = useSelector(selectFormDocsColumns);
	const formDocs = useSelector(selectFormDocsSelectors.selectAll).map((e) => {
		return { ...e, updated_at: dayjs(e.updated_at).local().format("YYYY/MM/DD HH:mm:ss") };
	});

	// FIXME: 一時的に状態をテキストで表示
	const docsList = formDocs.map((doc) => {
		let step: string = "";
		if (doc.working_step) {
			const step_name: string = StepStatusNames[doc.working_step.status] ?? "";
			step = `${doc.working_step.name} - ${step_name}`;
		}
		return {
			id: doc.id,
			subject: doc.subject,
			step: step,
			updated_at: doc.updated_at,
		};
	});

	const fetchDoc = useCallback(async () => {
		await dispatch(fetchFormDocs({ form_id, options: { workspace_id, auth_token: await getAccessTokenSilently() } }));
	}, [dispatch, form_id, getAccessTokenSilently, workspace_id]);

	useEffect(() => {
		if (!workspace_id || !form_id) {
			return;
		}
		fetchForm(workspace_id, form_id).then(() => fetchDoc());
	}, [workspace_id, form_id, fetchDoc, fetchForm]);

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate>
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	if (!form && formsLoaded) {
		return (
			<MainTemplate>
				<NavigationMessage message="フォームが見つかりません" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	return (
		<MainTemplate
			pageName={form?.name}
			isLoading={!form}
			// TODO: フォームのヘッダーロゴは当面非表示。正式導入時に追加。
			// header={<HeaderLogo logo_picture={form?.logo_picture || ""} />}
		>
			<Box data-testid="form-home-root">
				{!form ? (
					<Typography variant="body2" component="span" color="textSecondary">
						フォームを取得中…
					</Typography>
				) : (
					<Grid container spacing={0} justifyContent="center">
						<Grid item xs={12}>
							<Box display="flex" height={40} alignItems="center" mb={1}>
								<Box flexGrow={1}>
									<Typography variant="h5" component="h1" data-testid="form-home-name">
										{form.name}
									</Typography>
								</Box>
								<Box>
									<Link component={RouterLink} to={`/forms/${form_id}/settings`} data-testid="form-home-setting-link">
										<Button variant="text" color="primary" size="large" startIcon={<SettingsIcon />}>
											設定
										</Button>
									</Link>
								</Box>
							</Box>
							{form.description && (
								<Box>
									<Card variant="outlined">
										<CardContent>
											<Typography variant="body2" component="p" whiteSpace="pre-line" data-testid="form-home-description">
												{form.description}
											</Typography>
										</CardContent>
									</Card>
								</Box>
							)}
							{/* TODO 一時的に localhost 以外では非表示 */}
							{window.location.host.split(".")[0] === "localhost" && (
								<Box mt={2}>
									<Link component={RouterLink} to={`/forms/${form_id}/new`} data-testid="form-home-new-link">
										<Button variant="contained" color="primary" startIcon={<AddIcon />} data-testid="form-home-new-button">
											新規に {form.name} を申請する
										</Button>
									</Link>
								</Box>
							)}
						</Grid>
						{!form?.layout_id && (
							<Grid item xs={12} mx={3} data-testid="form-home-alert">
								<Alert severity="info" sx={{ fontSize: "15px" }}>
									<AlertTitle sx={{ fontSize: "18px", fontWeight: 700 }}>フォームの初期セットアップが完了していません</AlertTitle>
									<ColorLink to={`/forms/${form_id}/settings`} startIcon={SettingsIcon}>
										設定
									</ColorLink>{" "}
									から初期セットアップを再開する
								</Alert>
							</Grid>
						)}
						<Grid item xs={12}>
							<Box my={3} data-testid="form-home-table-view">
								<Box pb={1}>
									<Typography variant="h6" component="h2" color="initial">
										受付済ドキュメント
									</Typography>
								</Box>
								<TableView columns={formDocsColumns} items={docsList} emptyMessage="該当するドキュメントはありません" />
							</Box>
						</Grid>
					</Grid>
				)}
			</Box>
		</MainTemplate>
	);
};

export default FormTop;
