import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { v4 as uuidV4 } from "uuid";

import { LoadingButton } from "@mui/lab";
import { Box, DialogActions, Divider, List, ListItem, TextField } from "@mui/material";

import { PatchFormRequest } from "../apis/forms/patch";
import { FormConst } from "../constants/FormConst";
import { useFormList } from "../hooks/useFormList";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { ListedForm } from "../typings/FormListTypes";

import Form from "./Form";
import ToastContent from "./ToastContent";

type FormState = {
	name: string;
	description: string;
	logo_picture: string;
	is_maintenance: boolean;
};

type Props = {
	form: ListedForm;
};

const FormUpdateForm: React.VFC<Props> = ({ form }) => {
	const { id: workspace_id } = useSelector(selectWorkspace);

	const { saveForm } = useFormList();

	const [loading, setLoading] = useState<boolean>(false);
	const { errors, formState, handleSubmit, register, reset } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			name: form.name,
			description: form.description,
			logo_picture: form.logo_picture,
			is_maintenance: form.is_maintenance,
		},
	});
	const { isValid, dirtyFields } = formState;

	const onSubmit = handleSubmit(async (data: ListedForm) => {
		const patchData: PatchFormRequest = {
			id: form.id,
		};

		dirtyFields.name !== undefined && (patchData.name = data.name);
		dirtyFields.description !== undefined && (patchData.description = data.description);
		dirtyFields.logo_picture !== undefined && (patchData.logo_picture = data.logo_picture);
		dirtyFields.is_maintenance !== undefined && (patchData.is_maintenance = data.is_maintenance);

		setLoading(true);
		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});

		saveForm({ workspace_id, ...patchData })
			.then(() => {
				reset(data);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: null,
					render: "フォームを保存しました",
				});
			})
			.catch((reason: any) => {
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
			});
	});

	return (
		<Form onSubmit={onSubmit} data-testid="form-update-form-root">
			<Box pb={1.8}>
				<List>
					<ListItem>
						<TextField
							id="name"
							name="name"
							label={FormConst.name.Name}
							helperText={errors.name?.message || `メニューや一覧に表示されます ${FormConst.name.MinLength}-${FormConst.name.MaxLength} 文字以内`}
							variant="outlined"
							autoComplete="off"
							InputLabelProps={{
								required: true,
							}}
							fullWidth
							error={errors.name ? true : false}
							inputRef={register({
								required: { value: true, message: `${FormConst.name.Name}は必須です` },
								minLength: {
									value: FormConst.name.MinLength,
									message: `${FormConst.name.Name}は ${FormConst.name.MinLength} 文字以上にしてください`,
								},
								maxLength: {
									value: FormConst.name.MaxLength,
									message: `${FormConst.name.Name}は ${FormConst.name.MaxLength} 文字以内にしてください`,
								},
							})}
							data-testid="form-update-form-name-field"
						/>
					</ListItem>
					<ListItem>
						<TextField
							id="description"
							name="description"
							label={FormConst.description.Name}
							helperText={errors.description?.message || `${FormConst.description.MaxLength} 文字以内`}
							multiline
							rows={4}
							variant="outlined"
							autoComplete="off"
							fullWidth
							error={errors.description ? true : false}
							inputRef={register({
								maxLength: {
									value: FormConst.description.MaxLength,
									message: `${FormConst.description.Name}は ${FormConst.description.MaxLength} 文字以内にしてください`,
								},
							})}
							data-testid="form-update-form-desc-field"
						/>
					</ListItem>
					{/* // TODO: フォームのロゴ */}
					{/* <ListItem>
						<TextField
							id="logo_picture"
							name="logo_picture"
							label={FormConst.logo_picture.Name}
							helperText={errors.logo_picture?.message || "ロゴとして表示する URL を指定します (*デバッグ用)"}
							variant="outlined"
							autoComplete="off"
							fullWidth
							error={errors.logo_picture ? true : false}
							inputRef={register({
								pattern: {
									value: /^https?:\/\/[\w/:%#$&?()~.=+-]+$/,
									message: "正しい URL を入力してください",
								},
							})}
							data-testid="form-update-form-logo-field"
						/>
					</ListItem> */}
					{/* // TODO: フォームのメンテナンスモード */}
					{/* // useForm の control を使用 */}
					{/* <ListItem>
						<Box ml={1}>
							<Controller
								name="is_maintenance"
								control={control}
								render={(props) => (
									<FormControlLabel
										control={<Switch onChange={(e) => props.onChange(e.target.checked)} checked={props.value} color="primary" />}
										label="メンテナンスモード"
									/>
								)}
							/>
							<Typography variant="caption" color="textSecondary" component="p">
								有効にするとフォーム管理権限を持っているユーザーを除き すべてのユーザーはフォームにアクセスできなくなります。
							</Typography>
						</Box>
					</ListItem> */}
				</List>
			</Box>

			<Divider />
			<DialogActions>
				<LoadingButton
					type="submit"
					variant="contained"
					color="primary"
					disabled={loading || !isValid || !Object.keys(dirtyFields).length}
					loading={loading}
					data-testid="form-update-form-create-btn"
				>
					保存
				</LoadingButton>
			</DialogActions>
		</Form>
	);
};

export default FormUpdateForm;
