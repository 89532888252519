import { VFC } from "react";

import { Box, Card, CardContent, CardProps } from "@mui/material";

const ContentCenteredCard: VFC<CardProps> = ({ children, ...props }) => {
	return (
		<Card data-testid="content-centered-card-root" {...props}>
			<CardContent>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Box>{children}</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ContentCenteredCard;
