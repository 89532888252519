import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { ApiOptions, generateAxiosRequestConfig } from "../../misc/generateAxiosRequestConfig";

/**
 * 公開フォームロゴの URL を取得します
 *
 * @param options
 */
export const getLogoUrl = async (options: ApiOptions): Promise<string> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const res = await axios.get<string>("/workspaces/logo", reqConfig).catch(axiosErrorHandler);

	return res.data;
};
