import React from "react";
import { Link as RouterLink } from "react-router-dom";

import styled from "@emotion/styled";
import PublicIcon from "@mui/icons-material/Public";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";

import { ListedForm } from "../typings/FormListTypes";

import OverflowTooltip from "./OverflowTooltip";
import theme from "./Theme";

interface Prop {
	form: ListedForm;
}

const CustomStatusBar = styled(Box)({
	height: "100%",
	background: theme.palette.primary.main,
	borderRadius: "3px",
});

const CustomStatusChipBox = styled(Box)({
	background: "rgba(0, 0, 0, 0.03)",
});

const FormCard: React.FC<Prop> = ({ form }) => {
	return (
		<Card data-testid="form-card-root">
			<Link component={RouterLink} to={`/forms/${form.id}`} color={theme.palette.text.primary}>
				<Box component={CardContent} padding={0} display="flex" sx={{ "&:last-child": { padding: 0 } }}>
					<Box m={0.5} width={4}>
						{form.is_public && <CustomStatusBar />}
					</Box>
					<Box
						pt={1.5}
						pr={1.25}
						pb={0.75}
						pl={0.5}
						display="flex"
						flex={1}
						flexDirection="column"
						justifyContent="space-between"
						overflow="hidden"
						textOverflow="ellipsis"
					>
						<Box>
							<Typography gutterBottom variant="h5" component="p" data-testid="form-card-name">
								<OverflowTooltip title={form.name || ""} placement="top">
									<>{form.name}</>
								</OverflowTooltip>
							</Typography>

							<Typography color="textSecondary" data-testid="form-card-description">
								<OverflowTooltip title={form.description || ""} placement="top">
									<>{form.description}</>
								</OverflowTooltip>
							</Typography>
						</Box>
						<Box display="flex" alignItems="center" data-testid="form-card-status">
							{form.is_public && (
								<CustomStatusChipBox p={0.5} display="flex" alignItems="center" fontSize="10px" color="primary" borderRadius={0.75}>
									<Box mr={0.25}>
										<PublicIcon color="primary" fontSize="inherit" />
									</Box>
									<Typography color="primary">公開中</Typography>
								</CustomStatusChipBox>
							)}
						</Box>
					</Box>
				</Box>
			</Link>
		</Card>
	);
};

export default FormCard;
