import axios from "axios";

import { ServiceLink } from "../../typings/ServiceLinkTypes";
import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** サービスリンク保存時のリクエスト */
export type PutServiceLinkRequest = {
	connection: { id: string };
	/** サービスリンク情報 */
	config: ServiceLink;
};

/** サービスリンク保存時のレスポンス */
export type PutServiceLinkResponse = {
	form_id: string;
	connection_id: string;
};

/**
 * サービスリンク設定を保存します
 *
 * @param options - API オプション
 */
export const putServiceLink = async (form_id: string, reqData: PutServiceLinkRequest, options: ApiOptions): Promise<PutServiceLinkResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	const { data } = await axios.put(`/forms/${form_id}/servicelink`, reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
