import { VFC } from "react";

import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { AccordionSummary, Box, Typography } from "@mui/material";

import { FormWizardStepStatus } from "../FormWizardForm";

const CustomAccordionSummary = styled(AccordionSummary)({
	cursor: "default",
	"&:hover:not(.Mui-disabled)": {
		cursor: "default",
	},
});

type Props = {
	title: string;
	stepSign: FormWizardStepStatus;
};

const StepHeader: VFC<Props> = ({ title, stepSign }) => {
	return (
		<CustomAccordionSummary aria-controls="panel1bh-content" data-testid="step-header-root">
			<Typography variant="h6" component="h3">
				{title}
			</Typography>
			<Box ml={1} display="flex" justifyContent="center" alignItems="center">
				{stepSign === "success" && <CheckCircleIcon color={"primary"} data-testid="step-header-success-icon" />}
				{stepSign === "error" && <ErrorIcon color={"error"} data-testid="step-header-error-icon" />}
			</Box>
		</CustomAccordionSummary>
	);
};

export default StepHeader;
