import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { validate as uuidValidate } from "uuid";

import { Box, Grid, LinearProgress, Typography } from "@mui/material";

import { getForm } from "../../apis/forms/get";
import { getServiceLink } from "../../apis/servicelink/get";
import FormSettingsDrawer from "../../components/FormSettingsDrawer";
import FormWizardForm from "../../components/FormWizardForm";
import NavigationMessage from "../../components/NavigationMessage";
import ToastContent from "../../components/ToastContent";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";
import { AuthAndServiceLink } from "../../typings/AuthenticationTypes";
import { ListedForm } from "../../typings/FormListTypes";

const FormWizard: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const { getAccessTokenSilently } = useAuth0();
	const [form, setForm] = useState<ListedForm | null>();

	const form_id = match.params.form_id;
	const { id: workspace_id } = useSelector(selectWorkspace);

	const fetchForm = useCallback(async () => {
		const auth_token = await getAccessTokenSilently();
		const res = await getForm(form_id, { workspace_id, auth_token });

		const form: ListedForm = {
			id: res.id,
			name: res.name,
			description: res.description,
			logo_picture: res.logo_picture,
			is_maintenance: res.is_maintenance,
			layout_id: res.layout_id,
		};
		setForm(form);
	}, [getAccessTokenSilently, form_id, workspace_id]);

	useEffect(() => {
		workspace_id && fetchForm();
	}, [fetchForm, workspace_id]);

	const [authAndServiceLink, setAuthAndServiceLink] = useState<AuthAndServiceLink>();
	const fetchServiceLink = useCallback(async () => {
		const auth_token = await getAccessTokenSilently();

		await getServiceLink(form_id, { workspace_id, auth_token })
			.then((res) => {
				if (res) {
					setAuthAndServiceLink({
						authentication: {
							id: res.connection.id,
						},
						servicelink: {
							app_cd: res.config.app_cd,
							processes_id: res.config.processes_id,
						},
					});
				}
			})
			.catch((reason) => {
				console.error(reason);
				toast(<ToastContent subject="接続の取得に失敗しました" message={reason.message} />, { type: toast.TYPE.ERROR, autoClose: 5000 });
			});
	}, [form_id, getAccessTokenSilently, workspace_id]);

	useEffect(() => {
		workspace_id && form_id && fetchServiceLink();
	}, [fetchServiceLink, form_id, workspace_id]);

	if (form?.layout_id) {
		return <Redirect to={`/forms/${form_id}/settings`} />;
	}

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate pageName="フォーム">
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	return (
		<MainTemplate pageName="初期セットアップ" drawer={<FormSettingsDrawer form_id={form_id} not_completed={!form?.layout_id} />}>
			<Grid container spacing={0} justifyContent="center" data-testid="form-wizard-page-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							初期セットアップ
						</Typography>
					</Box>

					<Box pt={1}>
						{form ? (
							<FormWizardForm
								form={form}
								authAndServiceLink={authAndServiceLink}
								setAuthAndServiceLink={setAuthAndServiceLink}
								form_id={form_id}
							/>
						) : (
							<LinearProgress />
						)}
					</Box>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default FormWizard;
