import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Box, TextField as MuiTextField } from "@mui/material";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { MultilineFieldEntity } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";

const CustomMuiTextField = styled(MuiTextField)({
	"& .MuiInput-root": {
		height: "100%",
		display: "block",
		"& textarea": {
			width: "100%",
		},
	},
});

const PreWrapBox = styled(Box)({
	whiteSpace: "pre-wrap",
	paddingLeft: 3,
	paddingRight: 3,
});

type Props = {
	value?: StringFieldValue;
	field: MultilineFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

const MultilineField: React.VFC<Props> = ({ value, field, editable, onChange }) => {
	const { id, table_id, table_row, initial_value } = field || {};

	const [displayValue, setDisplayValue] = useState<string>(value ?? initial_value ?? "");

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setDisplayValue(e.currentTarget.value || "");
	};

	const handleBlur = (_e: React.FocusEvent<HTMLTextAreaElement>) => {
		if (value !== displayValue) {
			onChange({ id, value: displayValue, table_id, table_row });
			setDisplayValue(value ?? "");
		}
	};

	// デフォルト値設定
	useEffect(() => {
		if (value === undefined && initial_value) {
			setDisplayValue(initial_value);
			onChange({ id, value: initial_value, table_id, table_row });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDisplayValue(value ?? "");
	}, [value]);

	const makeInternalComponent = (editable: boolean): JSX.Element => {
		if (editable) {
			return (
				<CustomMuiTextField
					multiline
					value={displayValue}
					{...(editable && {
						disabled: false,
						onChange: handleChange,
						onBlur: handleBlur,
					})}
					data-testid="multiline-field-editable"
				/>
			);
		} else {
			return <PreWrapBox data-testid="multiline-field-no-editable">{displayValue}</PreWrapBox>;
		}
	};

	const internalComponent = makeInternalComponent(editable ?? false);
	return (
		<Box data-testid="multiline-field-root" height={"100%"}>
			{internalComponent}
		</Box>
	);
};

export default MultilineField;
