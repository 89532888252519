import { isPlusSafeInteger } from "./isPlusSafeInteger";

/**
 * 正の安全な整数の文字列かどうか判定します
 *
 * @param val
 * @returns
 */
export const isPlusSafeIntegerString = (val: unknown): boolean => {
	if (typeof val !== "string") {
		return false;
	}

	try {
		const num = Number(val);

		if (isPlusSafeInteger(num)) {
			return true;
		}
	} catch (error) {
		return false;
	}

	return false;
};
