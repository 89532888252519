import axios from "axios";

import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/**
 * 埋め込み描画可能なレイアウトデータを返します
 *
 * 現状は HTML タグで記述された長い文字列です。
 *
 * @param layout_id レイアウト ID
 */
export const getLayoutData = async (layout_id: string, options: ApiOptions, form_id?: string): Promise<string> => {
	const reqConfig = generateAxiosRequestConfig(options);
	if (form_id) {
		reqConfig.params = { f: form_id };
	}

	const uri = options.auth_token ? `/layouts/${layout_id}` : `/g/layouts/${layout_id}`;
	const signedUrl = await axios.get(uri, reqConfig).then((res) => {
		if (res.status !== 200) {
			throw new Error(`${res.status}: ${res.statusText}`);
		}
		return res.data.layout_url;
	});

	const response = await axios.get(signedUrl);
	return response.data;
};
