import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { ApiOptions, generateAxiosRequestConfig } from "../misc/generateAxiosRequestConfig";

export type PatchAuthenticationRequest = {
	name: string;
};

export type PatchAuthenticationResponse = {
	id: string;
	workspace_id: string;
	name: string;
	provider: string;
	authentication_info: {
		caption: string;
		endpoint: string;
	};
	created_at: string;
	updated_at: string;
};

/**
 * 認証情報を変更します。
 *
 * @param options - API オプション
 */
export const patchAuthentication = async (
	authentication_id: string,
	reqData: PatchAuthenticationRequest,
	options: ApiOptions
): Promise<PatchAuthenticationResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.patch(`/authentications/${authentication_id}`, reqData, reqConfig).catch(axiosErrorHandler);
	return data;
};
