import { VFC } from "react";

import { AppBar, Backdrop, Box, Toolbar } from "@mui/material";

import SimpleTemplate from "../templates/SimpleTemplate";

import AppBarSkeletons from "./AppBarSkeletons";
import theme from "./Theme";

const Waiting: VFC = () => {
	return (
		<Box data-testid="waiting-root">
			<SimpleTemplate
				isLoading={true}
				appBar={
					<AppBar position="fixed">
						<Toolbar>
							<AppBarSkeletons />
						</Toolbar>
					</AppBar>
				}
			>
				<Backdrop
					sx={{
						color: theme.palette.primary.main,
						backgroundColor: "rgba(245, 245, 245, 0.5)",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}
				></Backdrop>
			</SimpleTemplate>
		</Box>
	);
};

export default Waiting;
