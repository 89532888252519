import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import styled from "@emotion/styled";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Avatar, Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";

import { ACCOUNT_PAGE_URL } from "../constants";
import { CollaboCloudAuth0User } from "../typings/Auth0Types";

import SignoutListItemButton from "./SignoutListItemButton";
import theme from "./Theme";

const AvatarBox = styled(Box)({
	display: "flex",
	justifyContent: "center",
	position: "relative",
	paddingBottom: theme.spacing(2),
	zIndex: 0,
	"&::before": {
		display: "block",
		position: "absolute",
		content: '""',
		top: theme.spacing(8),
		right: 0,
		left: 0,
		height: 200,
		backgroundColor: theme.palette.primary.main,
		transform: "skewY(10deg)",
		zIndex: -1,
	},
});

const CustomAvatar = styled(Avatar)({
	width: theme.spacing(15),
	height: theme.spacing(15),
	zIndex: 0,
});

const UserMenu: React.FC = () => {
	const { user, isAuthenticated } = useAuth0<CollaboCloudAuth0User>();
	const { email, name, picture } = user || {};

	const [userMenu, setUserMenu] = React.useState<HTMLElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setUserMenu(event.currentTarget);
	};

	const handleClose = () => {
		setUserMenu(null);
	};

	return (
		<Box data-testid="user-menu-root">
			<IconButton color="inherit" onClick={handleOpen}>
				<Avatar sx={{ cursor: "pointer", width: 32, height: 32 }} src={picture || "./Avatar.png"} data-testid="user-menu-avatar-button" />
			</IconButton>
			<Popover open={Boolean(userMenu)} anchorEl={userMenu} onClose={handleClose} data-testid="user-menu-popover">
				<Box pt={3} width={320} maxWidth="100%">
					<AvatarBox data-testid="user-menu-avatar">
						<CustomAvatar src={picture || "./Avatar.png"} />
					</AvatarBox>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						position="relative"
						pt={3}
						px={3}
						sx={{ backgroundColor: "white" }}
						data-testid="user-menu-profile"
					>
						<Typography variant="h5">{name}</Typography>
						<Typography variant="subtitle1">{email}</Typography>
					</Box>
					<Box position="relative" pt={1} px={3} pb={3} sx={{ backgroundColor: "white" }}>
						<List>
							<ListItemButton
								onClick={() => {
									window.location.href = ACCOUNT_PAGE_URL;
								}}
								data-testid="user-menu-profile-button"
							>
								<ListItemIcon>
									<AccountBoxIcon color="primary" />
								</ListItemIcon>
								<ListItemText primary="アカウント情報" />
							</ListItemButton>
							{isAuthenticated && <SignoutListItemButton />}
						</List>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
};
export default UserMenu;
