import { Box, Typography } from "@mui/material";

export const VersionInfo = (): JSX.Element => {
	return (
		<Box display="flex" flexShrink={0} alignItems="center" ml={1} data-testid="version-info">
			<Typography component="span" variant="caption" color="inherit" noWrap>
				v1.0
			</Typography>
		</Box>
	);
};
