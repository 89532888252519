import axios from "axios";

import { NotificationEntity } from "../../typings/NotificationsType";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** サービスリンク保存時のレスポンス */
type GetNotificationsResponse = {
	notifications: NotificationEntity[];
};

/**
 * 個人の通知一覧を取得します。
 *
 * @param options - API オプション
 */
export const getNotifications = async (options: ApiOptions): Promise<GetNotificationsResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const res = await axios.get(`/notifications`, reqConfig);
	const response = { ...res.data };
	return response;
};
