import { VFC } from "react";

import { AppBar, AppBarProps, Box, Toolbar, Typography } from "@mui/material";

import AppBarSkeletons from "./AppBarSkeletons";

type Props = AppBarProps & {
	workspaceName?: string;
	logoEnabled?: boolean;
	logoUrl?: string;
	loading?: boolean;
};

const AppBarContents: VFC<{ logoUrl?: string; workspaceName?: string }> = ({ logoUrl, workspaceName }) => {
	return (
		<>
			{logoUrl && <Box component="img" maxWidth={220} maxHeight={40} pr={1} src={logoUrl} alt="" />}
			<Box px={1} flexGrow={1} flexShrink={1} width="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
				<Typography variant="h5" component="span" data-testid="guest-main-app-bar-workspace-name">
					{workspaceName}
				</Typography>
			</Box>
		</>
	);
};

const GuestMainAppBar: VFC<Props> = ({ workspaceName, logoUrl, loading, ...props }) => {
	return (
		<AppBar position="fixed" data-testid="guest-main-app-bar-root" {...props}>
			<Toolbar>{loading ? <AppBarSkeletons /> : <AppBarContents workspaceName={workspaceName} logoUrl={logoUrl} />}</Toolbar>
		</AppBar>
	);
};

export default GuestMainAppBar;
