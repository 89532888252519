import { createAsyncThunk } from "@reduxjs/toolkit";

import { FieldEntity } from "../../typings/FieldTypes";
import { FieldValue } from "../../typings/FieldValueTypes";
import { StringConverter, StringConvertRule } from "../../utils/StringConverter";
import {
	CheckboxFieldValidator,
	DateFieldValidator,
	DropdownFieldValidator,
	MultilineFieldValidator,
	NumberFieldValidator,
	TextFieldValidator,
	RadioButtonFieldValidator,
	ValidateResult,
} from "../../validators";
import { AttachmentFieldValidator } from "../../validators/AttachmentFieldValidator";
import { State } from "../Reducers";

type UpdateDocContent = {
	/** ドキュメント */
	doc?: { id: string; subject?: string };
	/** フィールド値のリスト */
	values?: FieldValue[];
};

type UpdateDocPayload = {
	content: UpdateDocContent;
};

/**
 * フィールド値エンティティに変換ルールを適用します
 *
 * 引数のフィールド値オブジェクトはの参照は維持したまま、value のみ書き換わります
 *
 * @param fieldValue 対象のフィールド値
 * @param convert_rules 変換ルールの配列
 */
function applyConvertRules(fieldValue?: FieldValue, convert_rules?: StringConvertRule[]) {
	if (fieldValue?.value && convert_rules && convert_rules.length) {
		fieldValue.value = new StringConverter().convert(fieldValue.value, convert_rules);
	}
}

/** フィールド値処理関数 */
type Processor = ((field: FieldEntity, value: FieldValue) => ProcessedResults) | undefined;

/** 処理後の結果 */
type ProcessedResults = { results: { validation: ValidateResult } };

/**
 * フィールドタイプ別のフィールド値処理関数マップ
 */
const processors: { [processor: string]: Processor } = {
	attachment: (field: FieldEntity, entity: FieldValue) => {
		const validation = new AttachmentFieldValidator(field).validate(entity.valueMap);
		return { results: { validation } };
	},

	checkbox: (field: FieldEntity, entity: FieldValue) => {
		const validation = new CheckboxFieldValidator(field).validateAll(entity.values);
		return { results: { validation } };
	},

	date: (field: FieldEntity, entity: FieldValue) => {
		const validation = new DateFieldValidator(field).validate(entity.value);
		return { results: { validation } };
	},

	// datetime: undefined,

	dropdown: (field: FieldEntity, entity: FieldValue) => {
		const validation = new DropdownFieldValidator(field).validate(entity.value);
		return { results: { validation } };
	},

	// interval: undefined,

	// label: undefined,

	// listbox: undefined,

	multiline: (field: FieldEntity, entity: FieldValue) => {
		const validation = new MultilineFieldValidator(field).validate(entity.value);
		return { results: { validation } };
	},

	number: (field: FieldEntity, entity: FieldValue) => {
		const validation = new NumberFieldValidator(field).validate(entity.value);
		return { results: { validation } };
	},

	radiobutton: (field: FieldEntity, entity: FieldValue) => {
		const validation = new RadioButtonFieldValidator(field).validate(entity.value);
		return { results: { validation } };
	},

	text: (field: FieldEntity, entity: FieldValue) => {
		const validation = new TextFieldValidator(field).validate(entity.value);
		return { results: { validation } };
	},

	// time: undefined,
};

/**
 * 配列の要素が完全に一致しているか比較します
 *
 * @param a 配列A
 * @param b 配列B
 * @returns 要素数および要素内の文字列も完全に一致している又は互いに nullish であれば true、違えば false
 */
const isValuesEqual = (a: string[] | null | undefined, b: string[] | null | undefined): boolean => {
	// どちらかが未定義状態の場合、もう片方も未定義か否かで同定する
	// （中身がある場合、他方が未定義の時点で差異が出ているので中身比較は不要）
	if (a === undefined || a === null) {
		return b === undefined || b === null;
	}
	if (b === undefined || b === null) {
		return a === undefined || a === null;
	}

	// 配列の要素数が一致してない時点で差異有り
	if (a.length !== b.length) {
		return false;
	}

	// 全ての要素が順番まで一致しているか（一部でも不一致なら false）
	return a.every((value, index) => value === b[index]);
};

/**
 * フィールド値を処理します
 *
 * 引数のフィールド値オブジェクトはの参照は維持したまま、value のみ書き換わります
 *
 * @param field フィールド
 * @param fieldValue フィールド値
 * @param lastValue 直前のフィールド値
 */
function valueProcess(field: FieldEntity, fieldValue: FieldValue, lastValue?: FieldValue): void {
	if (!field || !fieldValue) return;

	// 共通事前処理
	applyConvertRules(fieldValue, field.convert_rules);

	const processor = processors[field.field_type];
	if (processor) {
		// 個別処理
		const { results } = processor(field, fieldValue);

		// 共通事後処理
		const { is_valid, validation_message } = results.validation;

		let is_dirty = false;
		if (lastValue?.values || fieldValue.values) {
			if (!isValuesEqual(lastValue?.values, fieldValue.values)) {
				is_dirty = true;
			}
		} else if (lastValue?.value !== fieldValue.value) {
			is_dirty = true;
		}

		Object.assign(fieldValue, { is_valid, validation_message, is_dirty });
	}
}

/**
 * ドキュメントを検証し、スライスを更新します
 */
const updateDoc = createAsyncThunk<UpdateDocContent, UpdateDocPayload, { state: State }>("doc/updateDoc", async ({ content }, thunkApi) => {
	const { values } = content;

	if (values && values.length) {
		const state = thunkApi.getState();
		const fields = state.layout.fields;
		const lastValues = state.doc.values.entities;

		for (const idx in values) {
			const value = values[idx];
			const field = fields[value.id];
			const lastValue = lastValues[value.id];
			valueProcess(field, value, lastValue);
		}
	}

	return content;
});

export default updateDoc;
