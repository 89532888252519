import React, { useEffect, useState } from "react";

import { Box, FormControl, MenuItem, Select } from "@mui/material";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { DropdownFieldEntity } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";

type Props = {
	value?: StringFieldValue;
	field: DropdownFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

const DropdownField: React.VFC<Props> = ({ value, field, editable, onChange }) => {
	const { id, table_id, table_row, initial_value } = field || {};

	const [displayValue, setDisplayValue] = useState<string>(value ?? initial_value ?? "");

	const handleChange = (e: any) => {
		setDisplayValue((e.target.value as string) || "");
		onChange({ id, value: e.target.value ?? null, table_id, table_row });
	};

	// デフォルト値設定
	useEffect(() => {
		if (value === undefined && initial_value) {
			setDisplayValue(initial_value);
			onChange({ id, value: initial_value, table_id, table_row });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDisplayValue(value ?? "");
	}, [value]);

	return (
		<Box data-testid="dropdown-field-root">
			<FormControl fullWidth>
				<Select
					id="dropdown-field-select"
					value={displayValue}
					defaultValue={displayValue}
					displayEmpty
					disabled
					{...(editable && {
						disabled: false,
						onChange: handleChange,
					})}
				>
					{field.selections?.map(({ label, value }) => (
						<MenuItem value={value} key={label}>
							{label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default DropdownField;
