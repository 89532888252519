import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** フォーム更新 のリクエストペイロード */
export type PatchFormRequest = {
	/** フォーム ID */
	id: string;
	/** フォーム名 */
	name?: string;
	/** フォーム説明文 */
	description?: string;
	/** フォームロゴ URL */
	logo_picture?: string;
	/** メンテナンスモード */
	is_maintenance?: boolean;
	/** 公開設定 */
	is_public?: boolean;
	/** アーカイブ状態 */
	is_archive?: boolean;
};

/** フォーム更新 のレスポンスペイロード */
export type FormPatchResponse = {
	/** フォーム ID */
	id: string;
	/** フォーム ID */
	workspace_id: string;
	/** フォーム名 */
	name: string;
	/** フォーム説明文 */
	description?: string;
	/** フォームロゴ URL */
	logo_picture?: string;
	/** メンテナンスモード */
	is_maintenance?: boolean;
	/** 公開設定 */
	is_public?: boolean;
	/** アーカイブ状態 */
	is_archive?: boolean;
	/** レイアウト ID */
	layout_id?: string;
	/** 更新日時 */
	created_at?: string;
	/** 更新日時 */
	updated_at?: string;
};

/**
 * フォームを更新します
 *
 * @param reqData - 更新するフォームのリクエストデータ
 * @param options - API のオプション
 */
export const patchForm = async (reqData: PatchFormRequest, options: ApiOptions): Promise<FormPatchResponse> => {
	const { id, ...data } = reqData;
	const reqConfig = generateAxiosRequestConfig(options);
	return axios
		.patch<FormPatchResponse>(`/forms/${id}`, data, reqConfig)
		.then(({ data }) => data)
		.catch(axiosErrorHandler);
};
