import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import { SerializedError } from "@reduxjs/toolkit";

import {
	fetchAsyncWorkspace,
	fetchWorkspaceEnd,
	fetchWorkspaceStart,
	selectWorkspace,
	selectWorkspaceLastError,
	selectWorkspaceLoaded,
	Workspace,
} from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";

function getSubdomainByLocation(): string {
	const host: string = window.location.host;
	if (host === "localhost:3000") {
		return "localhost";
	}
	return host.split(".")[0];
}

/**
 * ワークスペース用のカスタムフック
 * @returns
 */
export const useWorkspace = (): {
	fetchWorkspace: () => Promise<void>;
	isWorkspaceLoaded: boolean;
	workspace: Workspace;
	workspaceLastError: SerializedError | undefined;
	forcedFetchWorkspace: () => Promise<void>;
} => {
	const dispatch: AppDispatch = useDispatch();
	const { getAccessTokenSilently } = useAuth0();

	const workspace = useSelector(selectWorkspace);
	const workspaceLastError = useSelector(selectWorkspaceLastError);
	const isWorkspaceLoaded = useSelector(selectWorkspaceLoaded);

	const fetchWorkspace = useCallback(async () => {
		if (isWorkspaceLoaded) {
			return;
		}
		const subdomain = getSubdomainByLocation();
		const auth_token = await getAccessTokenSilently();
		dispatch(fetchWorkspaceStart());

		await dispatch(
			fetchAsyncWorkspace({
				subdomain: { subdomain },
				options: { auth_token },
			})
		);

		dispatch(fetchWorkspaceEnd());
	}, [dispatch, isWorkspaceLoaded, getAccessTokenSilently]);

	const forcedFetchWorkspace = useCallback(async () => {
		const subdomain = getSubdomainByLocation();
		const auth_token = await getAccessTokenSilently();
		dispatch(fetchWorkspaceStart());

		await dispatch(
			fetchAsyncWorkspace({
				subdomain: { subdomain },
				options: { auth_token },
			})
		);

		dispatch(fetchWorkspaceEnd());
	}, [dispatch, getAccessTokenSilently]);

	return {
		fetchWorkspace,
		isWorkspaceLoaded,
		workspace,
		workspaceLastError,
		forcedFetchWorkspace,
	};
};
