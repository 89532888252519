import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { v4 as uuidV4 } from "uuid";

import styled from "@emotion/styled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardHeader, DialogActions, Divider, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Switch } from "@mui/material";

import { PatchFormRequest } from "../apis/forms/patch";
import { useFormList } from "../hooks/useFormList";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { WithWorkspaceId } from "../typings/WorkspaceTypes";

import Form from "./Form";
import ToastContent from "./ToastContent";

const BulletPointsList = styled(List)({
	padding: "0 0 8px 0",
	"& .MuiListItem-root": {
		padding: "0 16px",
	},
	"& .MuiListItemIcon-root": {
		minWidth: "18px",
		"& .MuiSvgIcon-root": {
			fontSize: "9px",
		},
	},
});

type FormState = {
	is_archive: boolean;
};

type Props = {
	form_id: string;
	is_archive: boolean | undefined;
};

const FormArchiveSettingCard: React.VFC<Props> = ({ form_id, is_archive = false }) => {
	const { id: workspace_id } = useSelector(selectWorkspace);
	const { saveForm } = useFormList();

	const [loading, setLoading] = useState<boolean>(false);
	const { control, formState, handleSubmit, reset } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			is_archive,
		},
	});
	const { isValid, dirtyFields } = formState;

	const onSubmit = handleSubmit(async (data) => {
		const req: WithWorkspaceId<PatchFormRequest> = {
			workspace_id,
			id: form_id,
		};

		dirtyFields.is_archive !== undefined && (req.is_archive = data.is_archive);

		setLoading(true);
		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});

		saveForm(req)
			.then(() => {
				reset(data);
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: null,
					render: "フォームを保存しました",
				});
			})
			.catch((reason: any) => {
				setLoading(false);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={reason.message} />,
				});
			});
	});

	return (
		<Card variant="outlined">
			<CardHeader title="アーカイブ" subheader="フォームをアーカイブし一覧を整理します" />
			<Form onSubmit={onSubmit} data-testid="form-archive-setting-card-root">
				<Box pb={1.8}>
					<List>
						<ListItem>
							<Box ml={1}>
								<Controller
									name="is_archive"
									control={control}
									render={(props) => (
										<FormControlLabel
											control={
												<Switch
													onChange={(e) => props.onChange(e.target.checked)}
													checked={props.value}
													color="primary"
													data-testid="form-archive-setting-switch"
												/>
											}
											label="アーカイブする"
										/>
									)}
								/>
							</Box>
						</ListItem>
						<ListItem>
							<Box>
								<ListItemText primary="アーカイブすると以下の制限がかかります" />
								<BulletPointsList>
									{[
										// 箇条書き
										"公開アドレスが自動的に非公開になります",
										"フォーム一覧に表示されなくなります",
										// "ドキュメントを操作できなくなります",
									].map((s, idx) => (
										<ListItem key={idx}>
											<ListItemIcon>
												<FiberManualRecordIcon />
											</ListItemIcon>
											<ListItemText primary={s} />
										</ListItem>
									))}
								</BulletPointsList>
							</Box>
						</ListItem>
					</List>
				</Box>

				<Divider />
				<DialogActions>
					<LoadingButton
						type="submit"
						color="primary"
						disabled={loading || !isValid || !Object.keys(dirtyFields).length}
						loading={loading}
						size="large"
						variant="contained"
						data-testid="form-archive-setting-save-button-root"
					>
						保存
					</LoadingButton>
				</DialogActions>
			</Form>
		</Card>
	);
};

export default FormArchiveSettingCard;
