import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { SerializedError } from "@reduxjs/toolkit";
import { validate as uuidValidate } from "uuid";

import { Box, Grid, Typography } from "@mui/material";

import { postDoc } from "../../apis/docs/post";
import NavigationMessage from "../../components/NavigationMessage";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";
import { LoadingStatus } from "../../typings/GeneralTypes";

const DocumentNew: React.VFC<RouteComponentProps<{ form_id: string }>> = ({ match }) => {
	const form_id = match.params.form_id;
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [condition, setCondition] = useState<{ doc_id?: string; status: LoadingStatus; error?: Error | SerializedError }>({ status: "initial" });

	const post = useCallback(async () => {
		setCondition({ status: "pending" });
		const auth_token = await getAccessTokenSilently();
		await postDoc({ form_id }, { auth_token, workspace_id })
			.then(({ id: doc_id }) => {
				setCondition({ doc_id, status: "loaded" });
			})
			.catch((reason: any) => {
				console.log(reason);
				setCondition({ status: "error", error: reason });
			});
	}, [workspace_id, form_id, getAccessTokenSilently]);

	useEffect(() => {
		if (workspace_id && form_id && condition.status === "initial") {
			post();
		}
	}, [workspace_id, form_id, condition.status, post]);

	if (!uuidValidate(form_id)) {
		return (
			<MainTemplate>
				<NavigationMessage message="正しい形式の フォーム ID ではないようです" hint="メニューの「フォーム一覧」からもう一度試してみてください" />
			</MainTemplate>
		);
	}

	switch (condition.status) {
		case "error":
			return (
				<MainTemplate>
					<NavigationMessage message="ドキュメントの作成に失敗しました" hint={condition.error?.message} />
				</MainTemplate>
			);

		case "loaded":
			return <Redirect to={`/docs/${condition.doc_id}`} />;

		default:
			return (
				<MainTemplate isLoading={true}>
					<Box data-testid="document-new-root">
						<Grid container spacing={0} justifyContent="center">
							<Grid item xs={12}>
								<Typography variant="body2" component="span" color="textSecondary">
									ドキュメントを作成しています...
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</MainTemplate>
			);
	}
};

export default DocumentNew;
