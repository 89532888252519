import React from "react";
import { Helmet } from "react-helmet-async";

import { Box, Container, CssBaseline, LinearProgress } from "@mui/material";

import MainBox from "./parts/MainBox";
import NavBox from "./parts/NavBox";

interface Props {
	children: React.ReactNode;
	appBar?: React.ReactNode;
	drawer?: React.ReactNode;
	title?: string;
	isLoading?: boolean;
}

const SimpleTemplate: React.VFC<Props> = ({ children, appBar, drawer, title, isLoading }) => {
	return (
		<Box data-testid="simple-template-root">
			<Helmet title={title} />
			<CssBaseline />
			{isLoading && <LinearProgress sx={{ position: "absolute", zIndex: 9999, width: "100%" }} />}
			<Box display="flex">
				<Box data-testid="simple-template-app-bar">{appBar}</Box>
				<NavBox data-testid="simple-template-drawer">{drawer}</NavBox>
				<MainBox data-testid="simple-template-content">
					<Container maxWidth="lg">
						<>{children}</>
					</Container>
				</MainBox>
			</Box>
		</Box>
	);
};

export default SimpleTemplate;
