import { Dispatch, SetStateAction, useState, VFC } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Button } from "@mui/material";
import { grey } from "@mui/material/colors";

import { Workspace } from "../store/WorkspaceSlice";
import { OstensibleAuthentication } from "../typings/AuthenticationTypes";

import AuthenticationSettingsForm from "./AuthenticationSettingsForm";
import FadingModal from "./FadingModal";

type Props = {
	workspace: Workspace;
	authentications: OstensibleAuthentication[] | undefined;
	setAuthentications: Dispatch<SetStateAction<OstensibleAuthentication[]>>;
};

const WorkspaceAuthentications: VFC<Props> = ({ workspace: _workspace, authentications, setAuthentications }) => {
	const [selectedAuthentication, setSelectedAuthentication] = useState<OstensibleAuthentication>();
	const [modalView, setModalView] = useState<boolean>(false);

	const handleModalOpen = (id: OstensibleAuthentication): void => {
		setModalView(true);
		setSelectedAuthentication(id);
	};
	const handleModalClose = (): void => {
		setModalView(false);
		setSelectedAuthentication(undefined);
	};
	return (
		<Box p={1.8} data-testid="workspace-authentications-root">
			{authentications && (
				<Box>
					<Box
						p={1.8}
						display="grid"
						gridTemplateColumns="repeat(12, 1fr)"
						gap={0}
						sx={{
							fontWeight: "bold",
							fontSize: "18px",
							color: "rgba(58,142,132,1)",
							borderBottom: "1px solid",
							borderColor: "rgba(0, 0, 0, 0.12)",
						}}
					>
						<Box gridColumn="span 5">認証情報名</Box>
						<Box gridColumn="span 2">プロバイダー</Box>
						<Box gridColumn="span 2">最終更新日</Box>
						<Box gridColumn="span 3">編集</Box>
					</Box>

					{authentications.map((authentication) => (
						<Box
							p={1.8}
							display="grid"
							gridTemplateColumns="repeat(12, 1fr)"
							gap={0}
							sx={{
								borderBottom: "1px solid",
								borderColor: "rgba(0, 0, 0, 0.12)",
								"&:hover": {
									backgroundColor: grey[50],
									button: {
										backgroundColor: grey[100],
									},
								},
								button: {
									"&:hover": {
										backgroundColor: grey[200],
									},
								},
							}}
						>
							<Box gridColumn="span 5" display="flex" alignItems="center">
								{authentication.name}
							</Box>
							<Box gridColumn="span 2" display="flex" alignItems="center">
								{authentication.provider}
							</Box>
							<Box gridColumn="span 2" display="flex" alignItems="center">
								{authentication.updated_at}
							</Box>
							<Box gridColumn="span 3" display="flex" alignItems="center">
								<Button startIcon={<EditIcon />} size="large" onClick={() => handleModalOpen(authentication)}>
									修正する
								</Button>
							</Box>
						</Box>
					))}
				</Box>
			)}
			<FadingModal open={modalView} onClose={handleModalClose}>
				<AuthenticationSettingsForm onClose={handleModalClose} authentication={selectedAuthentication} setAuthentications={setAuthentications} />
			</FadingModal>
		</Box>
	);
};

export default WorkspaceAuthentications;
