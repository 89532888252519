import { FieldEntity } from "../typings/FieldTypes";
import { MapFieldValue, StringFieldValue } from "../typings/FieldValueTypes";

/**
 * 値の検証結果
 */
export type ValidateResult = {
	/** 検証した値が妥当なら true */
	is_valid: boolean;
	/** 無効 */
	validation_message?: string;
};

/**
 * 値が定められた条件を満たしているか検査するクラスの定義
 */
export interface Validator {
	validate(value: string | null): ValidateResult;
}

/**
 * 検証を素通しさせる検証クラス
 */
export class AlwaysOkayValidator implements Validator {
	validate(_value: string | null): ValidateResult {
		return { is_valid: true };
	}
}

/**
 * フィールドを検証するクラスの定義
 */
export abstract class FieldValidator<TField extends FieldEntity> implements Validator {
	private _name: string;
	private _field: TField;

	get name() {
		return this._name;
	}

	get field() {
		return this._field;
	}

	abstract validate(value: StringFieldValue | string[] | MapFieldValue | null | undefined): ValidateResult;

	protected valid(): ValidateResult {
		return { is_valid: true };
	}

	protected invalid(message: string, ..._args: (string | number)[]): ValidateResult {
		return {
			is_valid: false,
			validation_message: `${this._name}: ${message}`,
		};
	}

	constructor(field: FieldEntity) {
		this._name = field.name || "";
		this._field = field as TField;
	}
}
