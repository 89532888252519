import React, { useEffect, useState } from "react";

import Big from "big.js";

import styled from "@emotion/styled";
import { Box, InputAdornment, TextField } from "@mui/material";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { NumberFieldEntity } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";
import { BigUtil } from "../../utils/BigUtil";
import { FieldsConverter } from "../../utils/FieldsConverter";

const StyledBox = styled(Box)({
	paddingLeft: 3,
	paddingRight: 3,
});

const CustomTextField = styled(TextField)({
	"& input": {
		textAlign: "right",
	},
});

type Props = {
	value?: StringFieldValue;
	field: NumberFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

const converter = new FieldsConverter();

/** 小数点を適用した文字列を返します */
const getDecimalPlacedString = (value?: Big, decimal_places?: number): string | undefined => {
	if (value instanceof Big) {
		return value.toFixed(decimal_places || 0, Big.roundHalfUp);
	}
};

/** カンマ表示属性に従い、数値文字列にカンマを付与した文字列を返します */
const addShowComma = (value: string = "", show_comma?: boolean): string => {
	if (show_comma && typeof value === "string") {
		const ary = value.split(".");
		ary[0] = ary[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return ary.join(".");
	}
	return value;
};

/** 文字列中のカンマを削除した文字列を返します */
const removeComma = (value: string): string => {
	return value.replace(/,/g, "");
};

/**
 * 表示する形式に値を変換した文字列を返します
 */
const toDisplayValue = (value?: StringFieldValue, field?: NumberFieldEntity): string => {
	let val: string | undefined = value || undefined;

	const big = BigUtil.toBig(value);

	if (!big) {
		return value || "";
	}

	val = addShowComma(val, field?.show_comma);

	return val || "";
};

/**
 * 入力値とデフォルト値の内部的な同一性を確認し、結果と内部値を返します
 *
 * @param inputValue ユーザーの入力値
 * @param defaultValue コンポーネント生成時の値
 * @param field フィールド
 */
const innerValueInspector = (inputValue: string, defaultValue: StringFieldValue, field: NumberFieldEntity): { dirty: boolean; value: StringFieldValue } => {
	const commaRemovedValue = removeComma(inputValue);

	if (commaRemovedValue === "") {
		if (["", null].includes(defaultValue)) {
			return { dirty: false, value: null };
		} else {
			return { dirty: true, value: null };
		}
	}

	const big = BigUtil.toBig(commaRemovedValue);
	const value = getDecimalPlacedString(big, field?.decimal_places) ?? inputValue;
	if (defaultValue === commaRemovedValue) {
		return { dirty: false, value };
	} else {
		return { dirty: true, value };
	}
};

const NumberField: React.VFC<Props> = ({ value, field, editable, onChange }) => {
	const { id, table_id, table_row, initial_value } = field || {};

	const defaultValue = toDisplayValue(value ?? initial_value, field);
	const [displayValue, setDisplayValue] = useState<string>(defaultValue);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDisplayValue(e.currentTarget.value || "");
	};

	const handleBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
		const { dirty, value } = innerValueInspector(displayValue, defaultValue, field);

		if (dirty) {
			onChange({ id, value, table_id, table_row });
			setDisplayValue(toDisplayValue(value, field));
		}
	};

	let unitProps;
	if (field.unit_position && field.unit_text) {
		const position = converter.toInputUnitPosition(field.unit_position);
		unitProps = {
			[position]: (
				<InputAdornment position="end" data-testid={`number-field-unit-${field.unit_position}`}>
					{field.unit_text}
				</InputAdornment>
			),
		};
	}

	// デフォルト値設定
	useEffect(() => {
		if (value === undefined && initial_value) {
			const initialValue = toDisplayValue(initial_value, field);
			const { value } = innerValueInspector(displayValue, defaultValue, field);

			setDisplayValue(initialValue);
			onChange({ id, value, table_id, table_row });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const makeInternalComponent = (editable: boolean, unitProps: any, unit_text: string, unit_position: string): JSX.Element => {
		if (editable) {
			return (
				<CustomTextField
					type="text"
					value={displayValue}
					variant="standard"
					InputProps={{ ...unitProps, disableUnderline: true }}
					{...(editable && {
						disabled: false,
						onChange: handleChange,
						onBlur: handleBlur,
					})}
					data-testid="number-field-editable"
				/>
			);
		} else {
			return (
				<StyledBox data-testid="number-field-no-editable">
					{unit_position === "prefix" && `${unit_text} `}
					{displayValue}
					{unit_position === "suffix" && ` ${unit_text}`}
				</StyledBox>
			);
		}
	};

	const internalComponent = makeInternalComponent(editable ?? false, unitProps, field.unit_text ?? "", field.unit_position ?? "");
	return <Box data-testid="number-field">{internalComponent}</Box>;
};

export default NumberField;
