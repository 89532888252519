import { VFC } from "react";
import { Link as RouterLink } from "react-router-dom";

import SettingsIcon from "@mui/icons-material/Settings";
import { Divider, Link, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import StyledDrawer from "./StyledDrawer";

const WorkspaceLockedDrawer: VFC = () => {
	return (
		<StyledDrawer variant="permanent" open data-testid="workspace-locked-drawer-root">
			<List>
				<Link component={RouterLink} to="/admin" data-testid="workspace-locked-drawer-workspace-settings-link">
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="ワークスペース設定" />
					</ListItemButton>
				</Link>
			</List>
			<Divider />
		</StyledDrawer>
	);
};

export default WorkspaceLockedDrawer;
