// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequiredString = (val: unknown): val is string => {
	if (typeof val !== "string") {
		return false;
	}

	if (!val.length) {
		return false;
	}

	if (!val.trim().length) {
		return false;
	}

	return true;
};
