import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { getForm, GetFormResponse } from "../apis/forms/get";
import { ListFormResponse, listForms } from "../apis/forms/list";
import { patchForm, PatchFormRequest } from "../apis/forms/patch";
import { postForm, PostFormRequest } from "../apis/forms/post";
import { ApiOptions } from "../apis/misc/generateAxiosRequestConfig";
import { ErrorPayload } from "../typings/ErrorTypes";
import { ListedForm } from "../typings/FormListTypes";
import { LoadingStatus } from "../typings/GeneralTypes";

import { State } from "./Reducers";

type ListFormEntities = ListFormResponse["entities"];

/**
 * フォーム一覧を取得し、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 * - `is_archive` 取得対象をアーカイブ済みフォームの一覧にするには true
 */
export const fetchAsyncFormList = createAsyncThunk<ListFormEntities, { is_archive?: boolean; options: ApiOptions }, { rejectValue: ErrorPayload }>(
	"formList/fetchFormList",
	async (request, { rejectWithValue }) => {
		return listForms(request.options, request.is_archive)
			.then(({ entities }) => {
				return entities;
			})
			.catch(({ message, name, statusCode }) => {
				return rejectWithValue({ message, name, statusCode });
			});
	}
);

/**
 * フォームを取得、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 */
export const fetchAsyncForm = createAsyncThunk<GetFormResponse, { id: string; options: ApiOptions }, { rejectValue: ErrorPayload }>(
	"formList/fetchForm",
	async (request, { rejectWithValue }) => {
		return getForm(request.id, request.options)
			.then((res) => {
				return res;
			})
			.catch(({ message, name, statusCode }) => {
				return rejectWithValue({ message, name, statusCode });
			});
	}
);

/**
 * フォームを新規作成し、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `postData` 新規作成データ
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 */
export const registerAsyncForm = createAsyncThunk<ListedForm, { postData: PostFormRequest; options: ApiOptions }, { rejectValue: ErrorPayload }>(
	"formList/registerForm",
	async (request, { rejectWithValue }) => {
		return await postForm(request.postData, request.options)
			.then((res) => {
				return res;
			})
			.catch(({ message, name, statusCode }) => {
				return rejectWithValue({ message, name, statusCode });
			});
	}
);

/**
 * 特定のフォームを更新し、スライスに渡します。
 *
 * @param request リクエストに必要な情報
 * - `patchData` 新規作成データ
 * - `options.auth_token` トークン *
 * - `options.workspace_id` ワークスペース ID *
 */
export const saveAsyncForm = createAsyncThunk<ListedForm, { patchData: PatchFormRequest; options: ApiOptions }, { rejectValue: ErrorPayload }>(
	"formList/saveForm",
	async (request, { rejectWithValue }) => {
		return patchForm(request.patchData, request.options)
			.then((res) => {
				return res;
			})
			.catch(({ message, name, statusCode }) => {
				return rejectWithValue({ message, name, statusCode });
			});
	}
);

type AdditionalState = {
	isLoading: boolean;
	loaded: boolean;
	lastError?: ErrorPayload;
	// FIXME: 一時的な間借り
	encodedFormPassword?: string;

	// FIXME: 一時的な間借り
	formFetchingStatus: LoadingStatus;
	formFetchingError?: ErrorPayload;
};

const defaultSortComparer = (a: ListedForm, b: ListedForm) => a.name.localeCompare(b.name);

const formListAdapter = createEntityAdapter<ListedForm>({
	sortComparer: defaultSortComparer,
});

const formListSlice = createSlice({
	name: "formList",
	initialState: formListAdapter.getInitialState<AdditionalState>({
		isLoading: false,
		loaded: false,
		// FIXME: 一時的な間借り
		formFetchingStatus: LoadingStatus.Initial,
	}),
	reducers: {
		// FIXME: 一時的な間借り
		setFormEncodedPassword(state, action) {
			state.encodedFormPassword = action.payload;
		},
	},
	extraReducers: (builder) => {
		//#region fetchAsyncFormList
		builder.addCase(fetchAsyncFormList.pending, (state, _action) => {
			state.isLoading = true;
			state.lastError = undefined;
		});
		builder.addCase(fetchAsyncFormList.fulfilled, (state, action) => {
			formListAdapter.setMany(state, action.payload);
			state.isLoading = false;
			state.loaded = true;
			state.lastError = undefined;
		});
		builder.addCase(fetchAsyncFormList.rejected, (state, action) => {
			state.isLoading = false;
			state.loaded = true;
			state.lastError = {
				name: action.error.name,
				message: action.error.message,
			};
		});
		//#endregion

		//#region registerAsyncForm - フォームの新規追加
		builder.addCase(registerAsyncForm.fulfilled, (state, action) => {
			formListAdapter.addOne(state, action.payload);
			state.lastError = undefined;
		});
		builder.addCase(registerAsyncForm.rejected, (state, action) => {
			state.lastError = {
				name: action.error.name,
				message: action.error.message,
			};
		});
		//#endregion

		//#region saveAsyncForm - 設定を更新
		builder.addCase(saveAsyncForm.fulfilled, (state, action) => {
			// FIXME 本来なら setOne(). Redux Toolkit を v1.6.0 以降に更新が必要
			formListAdapter.removeOne(state, action.payload.id);
			formListAdapter.addOne(state, action.payload);
			state.lastError = undefined;
		});
		builder.addCase(saveAsyncForm.rejected, (state, action) => {
			state.lastError = {
				name: action.error.name,
				message: action.error.message,
			};
		});
		//#endregion

		// #region fetchAsyncForm - 1フォーム分の取得
		builder.addCase(fetchAsyncForm.pending, (state) => {
			state.formFetchingStatus = LoadingStatus.Pending;
		});
		builder.addCase(fetchAsyncForm.fulfilled, (state, action) => {
			// FIXME 本来なら setOne(). Redux Toolkit を v1.6.0 以降に更新が必要
			formListAdapter.removeOne(state, action.payload.id);
			formListAdapter.addOne(state, action.payload);
			state.formFetchingStatus = LoadingStatus.Fulfilled;
			state.formFetchingError = undefined;
		});
		builder.addCase(fetchAsyncForm.rejected, (state, action) => {
			const { name, statusCode, message } = action.payload || {};
			state.formFetchingStatus = LoadingStatus.Error;
			state.formFetchingError = { name, statusCode, message };
		});
		//#endregion
	},
});

export const { setFormEncodedPassword } = formListSlice.actions;

export const selectFormListIsLoading = (state: State) => state.formList.isLoading;
export const selectFormListLoaded = (state: State) => state.formList.loaded;
export const selectFormListLastError = (state: State) => state.formList.lastError;

// FIXME: 一時的な間借り
export const selectFormFetchingStatus = (state: State) => state.formList.formFetchingStatus;
export const selectFormFetchingError = (state: State) => state.formList.formFetchingError;

// FIXME: 一時的な間借り
export const selectEncodedFormPassword = (state: State) => state.formList.encodedFormPassword;

export const formListSelectors = formListAdapter.getSelectors<State>((state) => state.formList);

export const createFormByIdSelector = (form_id: string) => {
	return createSelector(
		(state: State) => state,
		(state: State) => formListSelectors.selectById(state, form_id)
	);
};

/**
 * 運用中のフォームだけ選出するセレクター関数
 */
export const selectOperationalFormList = createSelector(
	(state: State) => state,
	(state: State) =>
		formListSelectors.selectAll(state).filter((item) => {
			return item.is_archive !== true;
		})
);

/**
 * アーカイブ済みのフォームだけ選出するセレクター関数
 */
export const selectArchivedFormList = createSelector(
	(state: State) => state,
	(state) =>
		formListSelectors.selectAll(state).filter((item) => {
			return item.is_archive === true;
		})
);

export default formListSlice;
