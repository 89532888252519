import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import MainAppBar from "../../components/MainAppBar";
import RoundSkeleton from "../../components/RoundSkeleton";
import WorkspaceAdminDrawer from "../../components/WorkspaceAdminDrawer";
import WorkspaceContractViewCard from "../../components/WorkspaceContractViewCard";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import SimpleTemplate from "../../templates/SimpleTemplate";

const AdminContract: React.VFC = () => {
	const workspace = useSelector(selectWorkspace);

	return (
		<SimpleTemplate title="契約情報" appBar={<MainAppBar />} drawer={<WorkspaceAdminDrawer />} isLoading={!workspace.contract}>
			<Grid container spacing={0} justifyContent="center" data-testid="workspace-contract-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							契約情報
						</Typography>
					</Box>
					<Box pt={1}>
						{workspace.contract ? (
							<WorkspaceContractViewCard contract={workspace.contract} variant="outlined" />
						) : (
							<RoundSkeleton variant="rectangular" height={180} />
						)}
					</Box>
				</Grid>
			</Grid>
		</SimpleTemplate>
	);
};

export default AdminContract;
