import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** フォーム一覧取得 のリクエストペイロード */
export type ListFormRequest = {
	/** ワークスペース ID */
	workspace_id: string;
};

/** フォーム一覧取得 のレスポンスペイロード */
export type ListFormResponse = {
	entities: {
		/** フォーム ID */
		id: string;
		/** ワークスペース ID */
		workspace_id: string;
		/** フォーム名 */
		name: string;
		/** フォーム説明文 */
		description?: string;
		/** フォームロゴ URL */
		logo_picture?: string;
		/** メンテナンスモード */
		is_maintenance?: boolean;
		/** 公開設定 */
		is_public?: boolean;
		/** アーカイブ状態 */
		is_archive?: boolean;
		/** パスワード制限状態 */
		is_password_required?: boolean;
	}[];
	lastEvaluatedKey?: { [key: string]: any };
};

/**
 * フォームの一覧を取得します。
 *
 * @param reqData - 取得対象のフォーム一覧リクエストデータ
 * @param options - API のオプション
 */
export const listForms = async (options: ApiOptions, is_archive?: boolean): Promise<ListFormResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	if (is_archive) {
		reqConfig.params = {
			is_archive: is_archive,
		};
	}

	return axios
		.get<ListFormResponse>("/forms", reqConfig)
		.then(({ data }) => data)
		.catch(axiosErrorHandler);
};
