import { VFC } from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Copyright: VFC = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center" data-testid="copyright-collabostyle">
			{"© "}
			<Link color="inherit" href="https://www.collabo-style.co.jp/">
				COLLABO STYLE INC.
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
};

export default Copyright;
