import { FC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import { v4 } from "uuid";

import AssignmentIcon from "@mui/icons-material/Assignment";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";

type Props = Omit<TextFieldProps, "value"> & {
	value: string;
	readOnly?: boolean;
	onCopy?: () => void;
};

const defaultOnCopy = () => {
	toast("コピーしました", {
		type: toast.TYPE.INFO,
		toastId: v4(),
		autoClose: 3000,
	});
};

const CopyToClipboardField: FC<Props> = ({ readOnly = true, onCopy, ...args }) => {
	return (
		<TextField
			data-testid="copy-to-clipboard-field-root"
			{...args}
			InputProps={{
				readOnly,
				endAdornment: (
					<InputAdornment position="end">
						<CopyToClipboard text={args.value || ""}>
							<IconButton onClick={onCopy ?? defaultOnCopy} tabIndex={-1} data-testid="copy-to-clipboard-field-button" size="large">
								<AssignmentIcon />
							</IconButton>
						</CopyToClipboard>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default CopyToClipboardField;
