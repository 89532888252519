import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/**
 * フォーム情報取得 のリクエストペイロード
 */
export type GetFormRequest = {
	/** フォーム ID */
	id: string;
};

/**
 * フォーム情報取得 のレスポンスペイロード
 */
export type GetFormResponse = {
	/** フォーム ID */
	id: string;
	/** ワークスペース ID */
	workspace_id: string;
	/** ワークスペース名 */
	name: string;
	/** ワークスペース説明文 */
	description?: string;
	/** ワークスペース説明文 */
	logo_picture?: string;
	/** システム項目の可否 */
	is_maintenance?: boolean;
	/** 公開設定 */
	is_public?: boolean;
	/** アーカイブ状態 */
	is_archive?: boolean;
	/** パスワード制限状態 */
	is_password_required?: boolean;
	/** 作成日時 */
	created_at?: string;
	/** 更新日時 */
	updated_at?: string;
	/** レイアウト ID */
	layout_id?: string;
	/** コネクション ID */
	connection_id?: string;
	/** コネクション設定 */
	connection_config?: { [key: string]: string | number | boolean };
};

/**
 * フォームの情報を取得します。
 *
 * @param form_id
 * @param options - API のオプション
 */
export const getForm = async (form_id: string, options: ApiOptions): Promise<GetFormResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const uri = options.auth_token ? `/forms/${form_id}` : `/g/forms/${form_id}?t=${new Date().getTime()}`;

	return axios
		.get<GetFormResponse>(uri, reqConfig)
		.then(({ data }) => data)
		.catch(axiosErrorHandler);
};
