import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Box, CardActions, List, ListItem, TextField, Typography } from "@mui/material";

import { PostAuthenticationRequest, postAuthentication } from "../../../apis/authentications/post";
import { AuthenticationConst } from "../../../constants/AuthenticationConst";
import { selectWorkspace } from "../../../store/WorkspaceSlice";
import { AuthAndServiceLink } from "../../../typings/AuthenticationTypes";
import { StringConverter } from "../../../utils/StringConverter";
import Form from "../../Form";
import { FormWizardStepType } from "../../FormWizardForm";
import theme from "../../Theme";
import ToastContent from "../../ToastContent";

const CustomTypography = styled(Typography)({
	padding: "8px 14px",
});

type FormState = {
	authentication_name: string;
	endpoint: string;
	user_id: string;
	api_key: string;
	basic_user?: string;
	basic_password?: string;
	provider: string;
};

type Props = {
	changeStatus: (value: FormWizardStepType) => void;
	errorStatusChanger: (value: FormWizardStepType) => void;
	setAuthAndServiceLink: React.Dispatch<React.SetStateAction<AuthAndServiceLink | undefined>>;
};

const NewAuthenticationForm: React.VFC<Props> = ({ changeStatus, errorStatusChanger, setAuthAndServiceLink }) => {
	const stringConverter = new StringConverter();
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const { errors, formState, handleSubmit, register, reset, setValue, getValues } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			authentication_name: "コラボフロー",
			endpoint: "",
			user_id: "",
			api_key: "",
			provider: "collaboflow",
		},
	});
	const { isValid, dirtyFields } = formState;

	const resetState = (): void => {
		setErrorMessage("");
		reset();
	};

	const onSubmit = handleSubmit(async (data: FormState) => {
		setLoading(true);

		const patchData: PostAuthenticationRequest = {
			provider: "collaboflow",
			name: data.authentication_name,
			config: {
				endpoint: data.endpoint,
				user_id: data.user_id,
				api_key: data.api_key,
				basic_user: data.basic_user,
				basic_password: data.basic_password,
			},
		};

		const toastId = uuidV4();
		toast("認証情報を確認中...", {
			toastId: toastId,
			autoClose: false,
		});

		const auth_token = await getAccessTokenSilently();
		await postAuthentication(patchData, { auth_token, workspace_id })
			.then((res) => {
				toast.update(toastId, {
					type: toast.TYPE.INFO,
					autoClose: 1500,
					render: "認証情報を保存しました",
				});
				setLoading(false);
				resetState();
				setAuthAndServiceLink((state) => ({ ...state, authentication: { id: res.id } }));
				changeStatus("step3");
			})
			.catch((error: { message: string }) => {
				console.error(error);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="保存に失敗しました" message={error.message} />,
				});
				setLoading(false);
				setErrorMessage(error.message);
				errorStatusChanger("step2");
			});
	});

	const trimValue = (): void => {
		if (getValues("authentication_name")) {
			const s = getValues("authentication_name");
			setValue("authentication_name", stringConverter.trim(s));
		}
	};

	return (
		<Form onSubmit={onSubmit} data-testid="new-authentication-form-root">
			<Box pb={1.8}>
				<List>
					<ListItem>
						<TextField
							fullWidth
							id="authentication_name"
							name="authentication_name"
							label={AuthenticationConst.AuthenticationName.Name}
							variant="outlined"
							autoComplete="off"
							helperText={errors.authentication_name?.message}
							InputLabelProps={{
								required: true,
							}}
							inputRef={register({
								required: { value: true, message: `${AuthenticationConst.AuthenticationName.Name}は必須です` },
							})}
							error={errors.authentication_name ? true : false}
							onBlur={trimValue}
							data-testid="new-authentication-form-dialog-authentication_name"
						/>
					</ListItem>
					<ListItem>
						<Box width={1}>
							<TextField
								fullWidth
								id="endpoint"
								name="endpoint"
								label={AuthenticationConst.Endpoint.Name}
								variant="outlined"
								autoComplete="off"
								helperText={errors.endpoint?.message}
								InputLabelProps={{
									required: true,
								}}
								inputRef={register({
									required: { value: true, message: `${AuthenticationConst.Endpoint.Name}は必須です` },
								})}
								error={errors.endpoint ? true : false}
								data-testid="new-authentication-form-dialog-endpoint"
							/>
							<CustomTypography>例: https://cloud.collaboflow.com/example/api/index.cfm</CustomTypography>
						</Box>
					</ListItem>
					<ListItem>
						<Box width={1}>
							<TextField
								fullWidth
								id="user_id"
								name="user_id"
								label={AuthenticationConst.UserId.Name}
								variant="outlined"
								autoComplete="off"
								helperText={errors.user_id?.message}
								InputLabelProps={{
									required: true,
								}}
								inputRef={register({
									required: { value: true, message: `${AuthenticationConst.UserId.Name}は必須です` },
								})}
								error={errors.user_id ? true : false}
								data-testid="new-authentication-form-dialog-user_id"
							/>
							<CustomTypography>システム管理者を有するユーザー ID</CustomTypography>
						</Box>
					</ListItem>
					<ListItem>
						<Box width={1}>
							<TextField
								fullWidth
								id="api_key"
								name="api_key"
								label={AuthenticationConst.ApiKey.Name}
								variant="outlined"
								autoComplete="off"
								helperText={errors.api_key?.message}
								InputLabelProps={{
									required: true,
								}}
								inputRef={register({
									required: { value: true, message: `${AuthenticationConst.ApiKey.Name}は必須です` },
								})}
								error={errors.api_key ? true : false}
								data-testid="new-authentication-form-dialog-api_key"
							/>
							<CustomTypography>システム管理エリア &gt; 環境設定 &gt; REST API で発行した API キー</CustomTypography>
						</Box>
					</ListItem>
					{/* BASIC 認証 */}
					<ListItem>BASIC 認証 (設定している場合のみ)</ListItem>
					<ListItem>
						<TextField
							fullWidth
							id="basic_user"
							name="basic_user"
							label={AuthenticationConst.BasicUser.Name}
							variant="outlined"
							autoComplete="off"
							helperText={errors.basic_user?.message || "システム管理エリア > 環境設定 > BASIC 認証の共通ユーザー名"}
							inputRef={register()}
							error={errors.basic_user ? true : false}
							data-testid="authentication-add-form-basic_user"
						/>
					</ListItem>
					<ListItem>
						<TextField
							fullWidth
							id="basic_password"
							name="basic_password"
							label={AuthenticationConst.BasicPassword.Name}
							variant="outlined"
							autoComplete="off"
							helperText={errors.basic_password?.message || "システム管理エリア > 環境設定 > BASIC 認証の共通パスワード"}
							inputRef={register()}
							error={errors.basic_password ? true : false}
							data-testid="authentication-add-form-basic_password"
						/>
					</ListItem>
				</List>
			</Box>
			<CardActions>
				<Box>
					<LoadingButton
						data-testid="form-wizard-step2-submit-button"
						type="submit"
						color="primary"
						disabled={loading || !isValid || !Object.keys(dirtyFields).length}
						loading={loading}
						size="medium"
						variant="contained"
					>
						次へ
					</LoadingButton>
				</Box>
				{errorMessage && (
					<Box data-testid="new-authentication-form-dialog-error-message">
						<Typography variant="body1" gutterBottom color={theme.palette.error.main}>
							{errorMessage}
						</Typography>
					</Box>
				)}
			</CardActions>
		</Form>
	);
};

export default NewAuthenticationForm;
