import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";

import styled from "@emotion/styled";
import { SvgIconComponent } from "@mui/icons-material";

import theme from "./Theme";

type Props = LinkProps & {
	color?: string;
	// TODO: ReactNode を渡すようにしたほうが使い勝手が良いが、現状は位置調整の関係でアイコン直指定
	startIcon?: SvgIconComponent;
};

const ColorLink: FC<Props> = ({ children, color, startIcon: beforeIcon, ...args }) => {
	const StyledLink = styled(Link)({
		textDecoration: "none",
		color: color || theme.palette.primary.main,
	});

	const AdjustedSettingIcon =
		beforeIcon &&
		styled(beforeIcon)({
			marginBottom: -3,
		});

	return (
		<StyledLink {...args} data-testid="color-link-root">
			{AdjustedSettingIcon && <AdjustedSettingIcon fontSize="small" color="inherit" />}
			{children}
		</StyledLink>
	);
};

export default ColorLink;
