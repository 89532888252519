import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

/** 添付ファイルを送信するための準備時のリクエスト */
export type PrepareAttachmentPostRequest = {
	field_id: string;
	file_name: string;
	table_id?: string;
	table_row?: number;
};

/** 添付ファイルを送信するための準備時のレスポンス */
export type PrepareAttachmentPostResponse = {
	/** ドキュメント ID */
	doc_id: string;
	/** フィールド ID */
	field_id: string;
	/** ファイルアップロード URL */
	url: string;
	/** 添付ファイル情報 */
	fields: { [key: string]: any };

	table_id?: string;
	table_row?: number;
};

/**
 * 添付ファイルを送信するための準備をします
 *
 * @param options - API オプション
 */
export const prepareAttachmentPost = async (
	doc_id: string,
	reqData: PrepareAttachmentPostRequest,
	options: ApiOptions
): Promise<PrepareAttachmentPostResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	const prefix = options.auth_token ? "" : "/g";
	const { data } = await axios.post(`${prefix}/docs/${doc_id}/upload`, reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
