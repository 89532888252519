import { useState, VFC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, CardProps, TextField, Typography } from "@mui/material";

import { useFormList } from "../hooks/useFormList";
import { setFormEncodedPassword } from "../store/FormListSlice";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";
import { LoadingStatus } from "../typings/GeneralTypes";
import { StringConverter } from "../utils/StringConverter";

import Form from "./Form";

type FormState = {
	password: string;
};

type Props = CardProps & {
	form_id: string;
	errorMessage?: string;
};

const FormPasswordLockCard: VFC<Props> = ({ form_id, errorMessage, ...props }) => {
	const dispatch = useDispatch<AppDispatch>();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const { fetchFormWithoutAuth } = useFormList();

	const [fetching, setFetching] = useState<LoadingStatus>(LoadingStatus.Initial);

	const { errors, formState, getValues, handleSubmit, register, setValue } = useForm<FormState>({
		mode: "onChange",
		reValidateMode: "onBlur",
		defaultValues: {
			password: "",
		},
	});

	const { isValid, dirtyFields, isDirty } = formState;

	const onSubmit = handleSubmit(async (data) => {
		if (!isDirty) return;

		const encodedPassword = Buffer.from(data.password).toString("base64");
		dispatch(setFormEncodedPassword(encodedPassword));

		setFetching(LoadingStatus.Pending);
		await fetchFormWithoutAuth(workspace_id, form_id, encodedPassword);
		setFetching(LoadingStatus.Fulfilled);
	});

	const pending = fetching === LoadingStatus.Pending;

	return (
		<Card data-testid="form-password-lock-card-root" {...props}>
			<CardHeader title="パスワードを入力してください" />
			<Form onSubmit={onSubmit} width={380} maxWidth="100%">
				<CardContent>
					<Box>
						<TextField
							fullWidth
							id="password"
							name="password"
							label="パスワード"
							variant="outlined"
							autoComplete="off"
							helperText={errors.password?.message}
							InputLabelProps={{
								required: true,
							}}
							inputRef={register({
								required: { value: true, message: "パスワードは必須です" },
								pattern: { value: /^[!-~]+$/, message: "半角の英字、数字、記号のみにしてください" },
								maxLength: { value: 70, message: "70 文字以下にしてください" },
							})}
							error={errors.password ? true : false}
							onBlur={() => {
								setValue("password", new StringConverter().trim(getValues("password")));
							}}
							data-testid="form-password-lock-card-password-input"
						/>
					</Box>
					<Box mt={1} mx={1}>
						<Typography color="error">{errorMessage}</Typography>
					</Box>
					<Box mt={2} display="flex" alignItems="center" justifyContent="center">
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							size="large"
							disabled={pending || !isValid || !Object.keys(dirtyFields).length}
							loading={pending}
							startIcon={<LockOpenIcon />}
							data-testid="form-password-lock-card-submit-btn"
						>
							開く
						</LoadingButton>
					</Box>
				</CardContent>
			</Form>
		</Card>
	);
};

export default FormPasswordLockCard;
