/**
 * 正の安全な整数かどうか判定します
 *
 * @param val
 * @returns
 */
export const isPlusSafeInteger = (val: unknown): boolean => {
	if (Number.isSafeInteger(val)) {
		if (Math.sign(val as number) === 1) {
			return true;
		}
	}

	return false;
};
