import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

export type PutFormSecretRequest = {
	password: string;
};

type PutFormSecretResponse = {
	success: true;
};

/**
 * フォームのパスワードを保存します
 *
 * @param form_id
 * @param reqData
 * @param options
 */
export const putFormSecret = async (form_id: string, reqData: PutFormSecretRequest, options: ApiOptions): Promise<PutFormSecretResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.put(`/forms/${form_id}/secret`, reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
