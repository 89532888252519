import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Box, TextField as MuiTextField } from "@mui/material";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { TextFieldEntity } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";

const StyledBox = styled(Box)({
	paddingLeft: 3,
	paddingRight: 3,
});

type Props = {
	value?: StringFieldValue;
	field: TextFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

const TextField: React.VFC<Props> = ({ value, field, editable, onChange }) => {
	const { id, table_id, table_row, initial_value, required } = field || {};

	const [displayValue, setDisplayValue] = useState<string>(value ?? initial_value ?? "");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDisplayValue(e.currentTarget.value || "");
	};

	const handleBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
		if (value !== displayValue) {
			onChange({ id, value: displayValue, table_id, table_row });
			setDisplayValue(value ?? "");
		}
	};

	// デフォルト値設定
	useEffect(() => {
		if (value === undefined && initial_value) {
			setDisplayValue(initial_value);
			onChange({ id, value: initial_value, table_id, table_row });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDisplayValue(value ?? "");
	}, [value]);

	const makeTextFieldComponent = (editable: boolean): JSX.Element => {
		if (editable) {
			return (
				<MuiTextField
					type="text"
					value={displayValue}
					required={required}
					{...(editable && {
						disabled: false,
						onChange: handleChange,
						onBlur: handleBlur,
					})}
					data-testid="text-field-editable"
				/>
			);
		} else {
			return <StyledBox data-testid="text-field-no-editable">{displayValue}</StyledBox>;
		}
	};

	const component = makeTextFieldComponent(editable ?? false);
	return <Box data-testid="text-field-root">{component}</Box>;
};

export default TextField;
