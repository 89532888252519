import { InvalidValueMessageType } from "../constants/FieldConst";
import { MultilineFieldEntity } from "../typings/FieldTypes";
import { StringUtil } from "../utils/StringUtil";

import { FieldValidator, ValidateResult } from "./Validator";

/**
 * 複数行フィールドの入力値検証クラス
 */
export class MultilineFieldValidator extends FieldValidator<MultilineFieldEntity> {
	validate(value: string | null | undefined): ValidateResult {
		value ??= "";

		// 必須チェック
		if (this.field.required && value.trim() === "") {
			return this.invalid(InvalidValueMessageType.Required);
		}

		// 文字数不足
		if (StringUtil.lessThanMinLength(value, this.field.min_length)) {
			if (value.trim() !== "") {
				return this.invalid(InvalidValueMessageType.LessThanMinLength);
			}
		}

		// 文字数超過
		if (StringUtil.overMaxLength(value, this.field.max_length)) {
			return this.invalid(InvalidValueMessageType.OverMaxLength);
		}

		return this.valid();
	}
}
