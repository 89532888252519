import axios from "axios";

import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

export type ListAuthenticationsResponse = {
	id: string;
	name: string;
	provider: string;
	workspace_id: string;
	created_at: string;
	updated_at: string;
};

/**
 * 認証情報の配列を取得します
 *
 * @param options - API オプション
 */
export const listAuthentications = async (options: ApiOptions, provider?: string): Promise<ListAuthenticationsResponse[]> => {
	const reqConfig = generateAxiosRequestConfig(options);
	provider && (reqConfig.params = { provider });
	// FIXME: エンドポイント名称変更
	const res = await axios.get("/connections", reqConfig);
	return res.data.connections;
};
