import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { FieldValueHandler } from "../../pages/docs/DocumentView";
import { RadioButtonFieldEntity, Selection } from "../../typings/FieldTypes";
import { StringFieldValue } from "../../typings/FieldValueTypes";

const DisabledRadio = styled(Radio)({
	cursor: "unset",
});

const DisabledFormControlLabel = styled(FormControlLabel)({
	cursor: "unset",
});

type Props = {
	value?: StringFieldValue;
	field: RadioButtonFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
};

const makeInternalComponent = (selections: Selection[], checkedValue: string, editable: boolean): JSX.Element[] => {
	const controls = selections.map((entry) => {
		if (editable) {
			return <FormControlLabel key={entry.value} value={entry.value} control={<Radio checked={entry.value === checkedValue} />} label={entry.label} />;
		} else {
			return (
				<DisabledFormControlLabel
					checked={entry.value === checkedValue}
					key={entry.value}
					value={entry.value}
					control={<DisabledRadio readOnly={true} disableFocusRipple={true} disableRipple={true} disableTouchRipple={true} />}
					label={entry.label}
				/>
			);
		}
	});
	return controls;
};

const RadioButtonField: React.VFC<Props> = ({ value, field, editable, onChange }) => {
	const { id, table_id, table_row, initial_value } = field || {};

	// 省略されてる設定を補完
	const selections = field.selections ?? [];

	const align: boolean = field.align === "horizontal" ? true : false;

	const [checkedValue, setCheckedValue] = useState<string>(initial_value ?? "");

	const handleChange = (e: any) => {
		setCheckedValue((e.target.value as string) || "");
		onChange({ id, value: e.target.value ?? null, table_id, table_row });
	};

	// デフォルト値設定
	useEffect(() => {
		if (value === undefined && initial_value) {
			setCheckedValue(initial_value);
			onChange({ id, value: initial_value, table_id, table_row });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCheckedValue(value ?? "");
	}, [value]);

	const internalComponent = makeInternalComponent(selections, checkedValue, editable ?? false);
	return (
		<Box data-testid="radiobutton-field-root">
			<FormControl component="fieldset" fullWidth>
				<RadioGroup
					row={align}
					disabled
					{...(editable && {
						disabled: false,
						onChange: handleChange,
					})}
				>
					{internalComponent}
				</RadioGroup>
			</FormControl>
		</Box>
	);
};

export default RadioButtonField;
