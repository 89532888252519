import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

const RoundedSkeleton = styled(Skeleton)({
	borderRadius: "4px",
});

const RoundSkeleton: typeof Skeleton = ({ ...args }) => {
	return <RoundedSkeleton {...args} />;
};

export default RoundSkeleton;
