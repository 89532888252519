import axios from "axios";

import axiosErrorHandler from "../../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../../misc/generateAxiosRequestConfig";

type DeleteFormSecretResponse = {
	success: true;
};

/**
 * フォームのパスワードを削除します
 *
 * @param form_id
 * @param options
 */
export const deleteFormSecret = async (form_id: string, options: ApiOptions): Promise<DeleteFormSecretResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.delete(`/forms/${form_id}/secret`, reqConfig).catch(axiosErrorHandler);

	return data;
};
