import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Grid, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import { APP_NAME } from "../../App";
import Copyright from "../../components/Copyright";
import theme from "../../components/Theme";

const AccessDeniedPage: React.VFC = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const errorMessage = params.get("m") !== null ? params.get("m") : "AccessDenied";

	const baseDomain = window.location.origin.slice(window.location.origin.indexOf(".") + 1);

	return (
		<Container component="main" data-testid="access-denied-root" maxWidth="md">
			<Helmet>
				<title>エラー - {APP_NAME}</title>
			</Helmet>
			<CssBaseline />
			<Box component="header" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				<Box m={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box component="img" width={250} src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="" />
				</Box>
			</Box>
			<Grid container marginTop={theme.spacing(6)} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				<Grid item xs={8} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box component="img" width="40%" src={`${process.env.PUBLIC_URL}/assets/images/warning.svg`} alt="" />
					<Box mt={3} textAlign="center">
						<Typography component="h1" variant="h4">
							アクセスが許可されていません
						</Typography>
						<Typography variant="subtitle2" align="center" color="textSecondary" component="div">
							{errorMessage}
						</Typography>
					</Box>
					<Box mt={2}>
						<Link href={`https://${baseDomain}/#/landing`}>
							<Button color="primary" variant="outlined" size="large">
								<ArrowBackIosIcon color="inherit" />
								ワークスペースの一覧
							</Button>
						</Link>
					</Box>
				</Grid>
			</Grid>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
};

export default AccessDeniedPage;
