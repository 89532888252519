import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** ワークスペース更新 のレスポンスペイロード */
export type GetWorkspaceResponse = {
	/** ワークスペース ID */
	id: string;
	/** ワークスペース名 */
	name: string;
	/** ワークスペース説明文 */
	description?: string;
	/** 公開フォームロゴ有効状態 */
	logo_enabled?: boolean;
	/** ワークスペースの利用可否値 */
	is_active?: boolean;
	/** 契約情報 */
	contract?: {
		plan_name: string;
		is_trial: boolean;
		end_date?: string;
	};
	/** 更新日時 */
	created_at?: Date;
	/** 更新日時 */
	updated_at?: Date;
	/** 有効期限日時 */
	expires_at?: Date;
};

/**
 * ワークスペースを取得します。
 *
 * @param reqData - 更新するワークスペースのリクエストデータ
 * @param options - API のオプション
 */
export const getWorkspace = async (id: string, options: ApiOptions): Promise<GetWorkspaceResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const res = await axios.get(`/workspaces/${id}`, reqConfig).catch(axiosErrorHandler);
	const response = { ...res.data };

	return response;
};
