import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, DialogActions, List, ListItem, TextField, Typography } from "@mui/material";

import { PostAuthenticationRequest, postAuthentication, PostAuthenticationResponse } from "../apis/authentications/post";
import { AuthenticationConst } from "../constants/AuthenticationConst";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { LoadingStatus } from "../typings/GeneralTypes";
import { StringConverter } from "../utils/StringConverter";

import Form from "./Form";
import theme from "./Theme";

type Props = {
	onClose: () => void;
	onSuccess: (value: any) => void;
};

type FormState = {
	authentication_name: string;
	endpoint: string;
	user_id: string;
	api_key: string;
	basic_user?: string;
	basic_password?: string;
	provider: string;
};

const AuthenticationAddForm: React.VFC<Props> = ({ onClose, onSuccess }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const { errors, formState, handleSubmit, register, getValues, setValue } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			authentication_name: "",
			endpoint: "",
			user_id: "",
			api_key: "",
			provider: "collaboflow",
		},
	});
	const { isValid, dirtyFields } = formState;

	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const onsubmit = handleSubmit(async (data: FormState) => {
		setLoading(LoadingStatus.Pending);

		const patchData: PostAuthenticationRequest = {
			provider: "collaboflow",
			name: data.authentication_name,
			config: {
				endpoint: data.endpoint,
				user_id: data.user_id,
				api_key: data.api_key,
				basic_user: data.basic_user,
				basic_password: data.basic_password,
			},
		};

		const auth_token = await getAccessTokenSilently();

		await postAuthentication(patchData, { auth_token, workspace_id })
			.then((action: PostAuthenticationResponse) => {
				setLoading(LoadingStatus.Fulfilled);
				onSuccess(action);
				onClose();
			})
			.catch((error: { message: string }) => {
				console.log(error);
				setLoading(LoadingStatus.Error);
				setErrorMessage(error.message);
			});
	});

	const trimValue = (): void => {
		const name = getValues("authentication_name");
		if (name) {
			setValue("authentication_name", new StringConverter().trim(name));
		}
	};

	return (
		<Card variant="outlined" data-testid="authentication-add-form-root">
			<CardHeader title="コラボフローの認証情報の追加" />
			<Form key="authentication-add-form" onSubmit={onsubmit}>
				<CardContent>
					<Box width={500} maxWidth="100%">
						<List>
							<ListItem>
								<TextField
									fullWidth
									id="authentication_name"
									name="authentication_name"
									label={AuthenticationConst.AuthenticationName.Name}
									variant="outlined"
									autoComplete="off"
									helperText={errors.authentication_name?.message || "認証を識別するための名前"}
									InputLabelProps={{
										required: true,
									}}
									inputRef={register({
										required: { value: true, message: `${AuthenticationConst.AuthenticationName.Name}は必須です` },
									})}
									error={errors.authentication_name ? true : false}
									onBlur={trimValue}
									data-testid="authentication-add-form-authentication_name"
								/>
							</ListItem>
							<ListItem>
								<TextField
									fullWidth
									id="endpoint"
									name="endpoint"
									label={AuthenticationConst.Endpoint.Name}
									variant="outlined"
									autoComplete="off"
									helperText={errors.endpoint?.message || "例: https://cloud.collaboflow.com/example/api/index.cfm"}
									InputLabelProps={{
										required: true,
									}}
									inputRef={register({
										required: { value: true, message: `${AuthenticationConst.Endpoint.Name}は必須です` },
									})}
									error={errors.endpoint ? true : false}
									data-testid="authentication-add-form-endpoint"
								/>
							</ListItem>
							<ListItem>
								<TextField
									fullWidth
									id="user_id"
									name="user_id"
									label={AuthenticationConst.UserId.Name}
									variant="outlined"
									autoComplete="off"
									helperText={errors.user_id?.message || "システム管理者を有するユーザー ID"}
									InputLabelProps={{
										required: true,
									}}
									inputRef={register({
										required: { value: true, message: `${AuthenticationConst.UserId.Name}は必須です` },
									})}
									error={errors.user_id ? true : false}
									data-testid="authentication-add-form-user_id"
								/>
							</ListItem>
							<ListItem>
								<TextField
									fullWidth
									id="api_key"
									name="api_key"
									label={AuthenticationConst.ApiKey.Name}
									variant="outlined"
									autoComplete="off"
									helperText={errors.api_key?.message || "システム管理エリア > 環境設定 > REST API で発行した API キー"}
									InputLabelProps={{
										required: true,
									}}
									inputRef={register({
										required: { value: true, message: `${AuthenticationConst.ApiKey.Name}は必須です` },
									})}
									error={errors.api_key ? true : false}
									data-testid="authentication-add-form-api_key"
								/>
							</ListItem>
							{/* BASIC 認証 */}
							<ListItem>BASIC 認証 (設定している場合のみ)</ListItem>
							<ListItem>
								<TextField
									fullWidth
									id="basic_user"
									name="basic_user"
									label={AuthenticationConst.BasicUser.Name}
									variant="outlined"
									autoComplete="off"
									helperText={errors.basic_user?.message || "システム管理エリア > 環境設定 > BASIC 認証の共通ユーザー名"}
									inputRef={register()}
									error={errors.basic_user ? true : false}
									data-testid="authentication-add-form-basic_user"
								/>
							</ListItem>
							<ListItem>
								<TextField
									fullWidth
									id="basic_password"
									name="basic_password"
									label={AuthenticationConst.BasicPassword.Name}
									variant="outlined"
									autoComplete="off"
									helperText={errors.basic_password?.message || "システム管理エリア > 環境設定 > BASIC 認証の共通パスワード"}
									inputRef={register()}
									error={errors.basic_password ? true : false}
									data-testid="authentication-add-form-basic_password"
								/>
							</ListItem>
						</List>
					</Box>
				</CardContent>
				<DialogActions>
					<Button data-testid="authentication-add-form-cancel-button" onClick={onClose} tabIndex={-1} disabled={loading === LoadingStatus.Pending}>
						キャンセル
					</Button>
					<LoadingButton
						disabled={!isValid || !Object.keys(dirtyFields).length || loading === LoadingStatus.Pending}
						type="submit"
						variant="contained"
						data-testid="authentication-add-form-submit-button"
						loading={loading === LoadingStatus.Pending}
					>
						保存
					</LoadingButton>
				</DialogActions>
			</Form>
			{errorMessage && (
				<Box data-testid="authentication-add-form-error-message">
					<Typography variant="body1" gutterBottom color={theme.palette.error.main}>
						{errorMessage}
					</Typography>
				</Box>
			)}
		</Card>
	);
};

export default AuthenticationAddForm;
