import React, { useEffect, useCallback, VFC } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { Box, Container, CssBaseline, LinearProgress } from "@mui/material";

import { APP_NAME } from "../App";
import MainAppBar from "../components/MainAppBar";
import MainDrawer from "../components/MainDrawer";
import WorkspaceProvider from "../components/WorkspaceProvider";
import { useFormList } from "../hooks/useFormList";
import {
	fetchAsyncSubdomains,
	fetchWorkspaceEnd,
	fetchWorkspaceStart,
	selectWorkspace,
	selectWorkspaceLastError,
	selectWorkspaceLoaded,
} from "../store/WorkspaceSlice";
import { AppDispatch } from "../store/store";

import MainBox from "./parts/MainBox";
import NavBox from "./parts/NavBox";

/** サブドメインからワークスペースをストアに保存します */
function getSubdomainByLocation(): string {
	const host: string = window.location.host;
	if (host === "localhost:3000") {
		return "localhost";
	}
	return host.split(".")[0];
}
interface Props {
	children: React.ReactNode;
	pageName?: string;
	drawer?: React.ReactNode;
	isLoading?: boolean;
	disableContainer?: true;
}

const InnerMainTemplate: VFC<Props> = ({ children, pageName, drawer = <MainDrawer />, isLoading, disableContainer }) => {
	const dispatch: AppDispatch = useDispatch();
	const workspace = useSelector(selectWorkspace);
	const workspaceLastError = useSelector(selectWorkspaceLastError);
	const isWorkspaceLoaded = useSelector(selectWorkspaceLoaded);
	const { fetchFormList, lastError: formsLastError } = useFormList();

	/** サブドメインからワークスペースを取得しストアに保存します */
	const fetchWorkspace = useCallback(async () => {
		if (isWorkspaceLoaded) {
			return;
		}
		const subdomain = getSubdomainByLocation();
		dispatch(fetchWorkspaceStart());
		await dispatch(fetchAsyncSubdomains({ subdomain: { subdomain }, options: {} }));
		dispatch(fetchWorkspaceEnd());
		//TODO: getAccessTokenSilently を含めると document.title のテストがうまくいかない
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		fetchWorkspace();
	}, [fetchWorkspace]);

	useEffect(() => {
		fetchFormList(workspace.id);
	}, [workspace.id, fetchFormList]);

	if (workspaceLastError) {
		console.error(workspaceLastError);
		if (workspaceLastError.code === "403") {
			return <Redirect to={`/error/access-denied?m=${workspaceLastError.message}`} />;
		} else {
			return <Redirect to={`/error?m=${workspaceLastError.message}`} />;
		}
	}

	if (formsLastError) {
		console.error(formsLastError);
		return <Redirect to={`/error?m=${formsLastError.message}`} />;
	}

	const title = `${workspace.name}${pageName ? " | " + pageName : ""} - ${APP_NAME}`;

	return (
		<>
			<Helmet title={title} />
			<CssBaseline />
			<Box display="flex">
				{isLoading && <LinearProgress sx={{ position: "absolute", zIndex: 9999, width: "100%" }} />}
				<MainAppBar />
				<NavBox>{drawer}</NavBox>
				<MainBox>
					{disableContainer ? (
						<>{children}</>
					) : (
						<Container maxWidth="lg">
							<>{children}</>
						</Container>
					)}
				</MainBox>
			</Box>
		</>
	);
};

const MainTemplate: VFC<Props> = (props) => {
	return (
		<WorkspaceProvider>
			<InnerMainTemplate {...props} />;
		</WorkspaceProvider>
	);
};

export default MainTemplate;
