import axios from "axios";

import { JSONFieldValues } from "../../typings/FieldValueTypes";
import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** ドキュメント新規作成時のリクエスト */
export type PostDocRequest = {
	form_id: string;
	subject?: string;
	values?: JSONFieldValues;
};

/** ドキュメント新規作成時のレスポンス */
export type PostDocResponse = {
	/** ドキュメント ID */
	id: string;
};

/**
 * ドキュメントを新規作成します
 *
 * @param options - API オプション
 */
export const postDoc = async (reqData: PostDocRequest, options: ApiOptions): Promise<PostDocResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	const prefix = options.auth_token ? "" : "/g";
	const { data } = await axios.post(prefix + "/docs", reqData, reqConfig).catch(axiosErrorHandler);

	return data;
};
