import { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { v4 as uuidV4 } from "uuid";

import { Box, Paper, ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";

import { FieldValueHandler, AttachmentLinkCreateHandler, SetDocumentModeHandler } from "../pages/docs/DocumentView";
import { selectDocLoading } from "../store/DocSlice";
import { selectFieldsLoading } from "../store/LayoutSlice";
import { DocMode } from "../typings/DocTypes";
import { Fields } from "../typings/FieldTypes";
import { FieldValueDictionary } from "../typings/FieldValueTypes";
import { LoadingStatus } from "../typings/GeneralTypes";

import ActionButton from "./ActionButton";
import ActionCard from "./ActionCard";
import FieldBase from "./FieldBase";
import fieldTheme from "./fields/FieldTheme";

/**
 * フィールド ID から DOM のエレメントを探索し返します
 */
function findFieldElement(field_id: string, table_row?: number): Element | null {
	if (table_row !== undefined) {
		return document.querySelector(`div[data-parts-id="${field_id}"][data-row="${table_row}"]`);
	}

	return document.querySelector(`div[data-parts-id="${field_id}"]`);
}

type Props = {
	mode: DocMode;
	layoutHtml: string;
	fields: Fields;
	values: FieldValueDictionary;
	onChange: FieldValueHandler;
	onCreateLink: AttachmentLinkCreateHandler;
	setMode: SetDocumentModeHandler;
	isSomeValueInvalid: boolean;
};

const LayoutHTMLContent: React.VFC<Props> = ({ mode, layoutHtml, values, fields, onChange, setMode, onCreateLink, isSomeValueInvalid }) => {
	const docLoading = useSelector(selectDocLoading);
	const fieldsLoading = useSelector(selectFieldsLoading);

	const divRef = useRef<HTMLDivElement>(null);
	const [ready, setReady] = useState<boolean>(false);

	// レイアウト HTML を DOM に配置する
	useEffect(() => {
		setReady(false);
		if (divRef.current) {
			divRef.current.innerHTML = layoutHtml;
		}
	}, [layoutHtml]);

	// DOM にフィールドを配置する
	useEffect(() => {
		if (fieldsLoading === LoadingStatus.Loaded && !["initial", "pending"].includes(docLoading)) {
			for (const [key, field] of Object.entries(fields)) {
				if (!field.field_id) continue;

				const target = findFieldElement(field.field_id, field.table_row);
				if (target) {
					const entity = values[key];
					const { value, valueMap, editable, is_valid } = entity || {};
					// FIXME: 未保存の値の editable は true として暫定処理
					const props = {
						field_type: field.field_type,
						field,
						value,
						valueMap,
						values: entity?.values,
						editable: mode === DocMode.Edit && editable !== false,
						failed: is_valid === false,
						onChange,
						onCreateLink,
					};

					// FIXME: テーマを渡さないと適用されないため暫定処理
					const element = (
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={fieldTheme}>
								<FieldBase {...props} />
							</ThemeProvider>
						</StyledEngineProvider>
					);
					ReactDOM.render(element, target);
				}
			}
			setReady(true);
		}
	}, [fieldsLoading, docLoading, mode, fields, values, onChange, onCreateLink]);

	// FIXME ダミーボタン 保存機能実装時に削除
	const mockSend = () => toast("この機能は現在使用できません", { toastId: uuidV4(), type: toast.TYPE.INFO });

	const handleConfirm = () => {
		// TODO: 確認ボタンを押したとき、ユーザーに確認させるためのスクロールや表示、必須項目の指摘など追加の挙動が必要。
		setMode(DocMode.Confirm);
	};

	const actionArea = (): ReactNode => {
		switch (mode) {
			case DocMode.Edit:
				return (
					<ActionCard>
						<ActionButton variant="contained" color="primary" onClick={handleConfirm}>
							確認
						</ActionButton>
					</ActionCard>
				);
			case DocMode.Confirm:
				return (
					<ActionCard>
						<ActionButton variant="outlined" color="primary" onClick={() => setMode(DocMode.Edit)}>
							修正する
						</ActionButton>
						<ActionButton variant="contained" color="primary" disabled={isSomeValueInvalid} onClick={mockSend}>
							送信する
						</ActionButton>
					</ActionCard>
				);
			case DocMode.Sent:
				return <Typography>Sent</Typography>;
		}

		return <></>;
	};

	return (
		<Box display="flex" data-testid="layout-html-content-root">
			<Box display={ready ? undefined : "none"} flexShrink={0} p={3}>
				<Paper variant="outlined">
					<Box p={4}>
						<Box id="document" ref={divRef} data-testid="layout-html-content-div-ref" />
					</Box>
				</Paper>
				{ready ? <Box>{actionArea()}</Box> : <></>}
			</Box>
		</Box>
	);
};

export default LayoutHTMLContent;
