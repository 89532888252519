import axios from "axios";

import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

type Process = {
	id: number;
	title: string;
	description: string;
	category: {
		id: number;
		name: string;
	};
	link: string;
};

type ListProcessRequest = {
	auth_id: string;
	app_cd: string | number | boolean;
};

export type ListProcessResponse = {
	processes: Process[];
};

/**
 * 経路一覧 を取得します
 *
 * @param options - API オプション
 */
export const listProcess = async (options: ApiOptions, input: ListProcessRequest): Promise<ListProcessResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	const { auth_id, app_cd } = input || {};
	reqConfig.params = { auth_id, app_cd };

	const res = await axios.get("/processes", reqConfig);
	return res.data;
};
