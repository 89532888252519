import React, { useState } from "react";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, DialogActions, Divider, List, ListItem, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { activateWorkspaceLogo } from "../apis/workspace/logo/activateWorkspaceLogo";
import { deleteWorkspaceLogo } from "../apis/workspace/logo/delete";
import { postWorkspaceLogo } from "../apis/workspace/logo/post";
import { prepareLogoPosting } from "../apis/workspace/logo/prepareLogoPosting";
import { LogoConst } from "../constants/LogoConst";
import { useWorkspace } from "../hooks/useWorkspace";
import { Workspace } from "../store/WorkspaceSlice";
import { LoadingStatus } from "../typings/GeneralTypes";

import ExternalSiteLink from "./ExternalSiteLink";
import Form from "./Form";
import LogoAttachment from "./LogoAttachment";

type Props = {
	workspace: Workspace;
	logo_url: string;
};

const WorkspaceLogoUpdateCard: React.VFC<Props> = ({ workspace, logo_url }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { forcedFetchWorkspace } = useWorkspace();

	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const [logo, setLogo] = useState<File | undefined>();
	const [action, setAction] = useState<"initial" | "set" | "delete">("initial");

	const onAttach = (action: "set" | "delete", file?: File) => {
		if (action === "set") {
			setLogo(file);
			setAction("set");
			setLoading(LoadingStatus.Initial);
		}
		if (action === "delete") {
			setLogo(undefined);
			setAction("delete");
			setLoading(LoadingStatus.Initial);
		}
	};

	const onSubmit = async () => {
		setLoading(LoadingStatus.Pending);

		const workspace_id = workspace.id;
		const auth_token = await getAccessTokenSilently();

		const toastId = uuidV4();
		toast("保存中...", {
			toastId: toastId,
			autoClose: false,
		});

		if (action === "set" && logo) {
			try {
				await prepareLogoPosting({ file_name: logo.name }, { auth_token, workspace_id }).then(({ url, fields }) => {
					postWorkspaceLogo({ url, fields, file: logo }, { workspace_id }).then(() => {
						activateWorkspaceLogo({ auth_token, workspace_id }).then(() => {
							forcedFetchWorkspace().then(() => {
								toast.update(toastId, {
									type: toast.TYPE.INFO,
									autoClose: null,
									render: "ロゴを保存しました",
								});
								setLoading(LoadingStatus.Fulfilled);
							});
						});
					});
				});
			} catch (reason) {
				console.log(reason);
				toast.update(toastId, {
					type: toast.TYPE.ERROR,
					autoClose: null,
					render: "ロゴの保存に失敗しました",
				});
				setLoading(LoadingStatus.Error);
			}
		} else if (action === "delete") {
			await deleteWorkspaceLogo({ auth_token, workspace_id })
				.then(() => {
					forcedFetchWorkspace().then(() => {
						toast.update(toastId, {
							type: toast.TYPE.INFO,
							autoClose: null,
							render: "ロゴを削除しました",
						});
						setLoading(LoadingStatus.Fulfilled);
					});
					toast.update(toastId, {
						type: toast.TYPE.INFO,
						autoClose: null,
						render: "ロゴを削除しました",
					});
					setLoading(LoadingStatus.Fulfilled);
				})
				.catch((reason) => {
					console.log(reason);
					toast.update(toastId, {
						type: toast.TYPE.ERROR,
						autoClose: null,
						render: "ロゴの削除に失敗しました",
					});
					setLoading(LoadingStatus.Error);
				});
		}
	};

	const selectLogoUrl = (): string | undefined => {
		if (logo && action === "set") {
			return URL.createObjectURL(logo);
		} else if (action === "initial") {
			return logo_url;
		} else if (action === "delete") {
			return "";
		}
	};

	const isCheckDisabled = (): boolean => {
		if (loading === LoadingStatus.Fulfilled) {
			return false;
		}

		if (logo_url) {
			console.log("こちら");
			return action !== "initial" ? true : false;
		} else {
			return logo ? true : false;
		}
	};

	return (
		<Card variant="outlined" data-testid="workspace-logo-update-card-root">
			<CardHeader title="公開フォームロゴ" />
			<CardContent>
				<Typography>
					公開フォームの左上部分にロゴを設定できるようになります。
					<ExternalSiteLink href="https://collaboform.zendesk.com/hc/ja/articles/5288254879375">サポート記事</ExternalSiteLink>
					<br />
					保存すると、すべての公開フォームに即時反映されます。
				</Typography>
				<Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 0.5, color: grey[500] }}>
					<Box display="flex">
						<Typography width="130px">使用可能な拡張子</Typography>
						<Typography>: .gif, .jpg, .jpeg, .png .svg</Typography>
					</Box>
					<Box display="flex">
						<Typography width="130px">最大ファイルサイズ</Typography>
						<Typography>: 300KB</Typography>
					</Box>
					<Box display="flex">
						<Typography width="130px">推奨画像サイズ</Typography>
						<Typography>
							: 横幅: {LogoConst.ImageSize.Width} 縦幅: {LogoConst.ImageSize.Height}
						</Typography>
					</Box>
				</Box>
			</CardContent>
			<Form>
				<Box pb={1.8}>
					<List>
						<ListItem>
							<LogoAttachment onAttach={onAttach} logo_file_path={selectLogoUrl()} />
						</ListItem>
					</List>
				</Box>

				<Divider />
				<DialogActions>
					<LoadingButton
						type="button"
						variant="contained"
						color="primary"
						disabled={loading === LoadingStatus.Pending || !isCheckDisabled()}
						loading={loading === LoadingStatus.Pending}
						onClick={onSubmit}
						data-testid="workspace-logo-update-card-btn"
					>
						保存
					</LoadingButton>
				</DialogActions>
			</Form>
		</Card>
	);
};

export default WorkspaceLogoUpdateCard;
