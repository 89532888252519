import { useCallback, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import { Box, Card, CardHeader, Grid, Typography } from "@mui/material";

import { listAuthentications } from "../../apis/authentications/list";
import MainAppBar from "../../components/MainAppBar";
import SettingCardsSkeleton from "../../components/SettingCardsSkeleton";
import WorkspaceAdminDrawer from "../../components/WorkspaceAdminDrawer";
import WorkspaceAuthentications from "../../components/WorkspaceAuthentications";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import SimpleTemplate from "../../templates/SimpleTemplate";
import { OstensibleAuthentication } from "../../typings/AuthenticationTypes";
import { LoadingStatus } from "../../typings/GeneralTypes";

const AdminAuthentications: VFC = () => {
	const { getAccessTokenSilently } = useAuth0();

	const workspace = useSelector(selectWorkspace);
	const workspace_id = workspace.id;

	const [authentications, setAuthentications] = useState<OstensibleAuthentication[]>([]);
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const fetchAuthentications = useCallback(async () => {
		const auth_token = await getAccessTokenSilently();

		const res = await listAuthentications({ workspace_id, auth_token });
		const authentications = res.map((auth) => {
			return {
				id: auth.id,
				name: auth.name,
				provider: auth.provider,
				updated_at: dayjs(auth.updated_at).local().format("YYYY/MM/DD HH:mm:ss"),
			};
		});

		authentications.sort(function (a, b) {
			if (a.updated_at < b.updated_at) return 1;
			if (a.updated_at > b.updated_at) return -1;
			return 0;
		});

		if (authentications.length) {
			setAuthentications(authentications);
		} else {
			setAuthentications([]);
		}

		setLoading(LoadingStatus.Fulfilled);
	}, [getAccessTokenSilently, workspace_id]);

	useEffect(() => {
		workspace_id && fetchAuthentications();
	}, [fetchAuthentications, workspace_id]);

	return (
		<SimpleTemplate title="認証情報" appBar={<MainAppBar />} drawer={<WorkspaceAdminDrawer />}>
			<Grid container spacing={0} justifyContent="center" data-testid="workspace-admin-authentications-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Typography variant="h5" component="h1">
							認証情報
						</Typography>
					</Box>
					{workspace_id && loading === LoadingStatus.Fulfilled ? (
						<Box pt={1}>
							<Card variant="outlined">
								<CardHeader title="認証情報一覧" subheader="ワークスペースに保存された認証情報一覧" />
								<WorkspaceAuthentications workspace={workspace} authentications={authentications} setAuthentications={setAuthentications} />
							</Card>
						</Box>
					) : (
						<SettingCardsSkeleton />
					)}
				</Grid>
			</Grid>
		</SimpleTemplate>
	);
};

export default AdminAuthentications;
