import { VFC } from "react";

import { Drawer, DrawerProps } from "@mui/material";

import theme, { AppBarHeight, DrawerWidth } from "./Theme";

const StyledDrawer: VFC<DrawerProps> = (props) => {
	return (
		<Drawer
			{...props}
			PaperProps={{
				...props.PaperProps,
				sx: {
					marginTop: `${AppBarHeight}px`,
					maxHeight: `calc(100vh - ${AppBarHeight}px)`,
					width: DrawerWidth,
					backgroundColor: "rgba(255,255,255,1)",
					[theme.breakpoints.down("sm")]: {
						mt: `${AppBarHeight - 8}px`,
						maxHeight: `calc(100vh - ${AppBarHeight - 8}px)`,
					},
					...props.PaperProps?.sx,
				},
			}}
		/>
	);
};

export default StyledDrawer;
