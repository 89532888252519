import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidV4 } from "uuid";

import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { LoadingButton } from "@mui/lab";
import { Accordion, AccordionDetails, Box, CardActions, Typography } from "@mui/material";

import { postDesignImport } from "../../apis/forms/designs/post";
import { selectNotificationsSelectors } from "../../store/NotificationsSlice";
import { State } from "../../store/Reducers";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import { LoadingStatus } from "../../typings/GeneralTypes";
import { FormWizardStepStatus, FormWizardStepType } from "../FormWizardForm";
import ToastContent from "../ToastContent";

import StepHeader from "./StepHeader";

type Props = {
	form_id: string;
	changeStatus: (value: FormWizardStepType) => void;
	errorStatusChanger: (value: FormWizardStepType) => void;
	expanded: boolean;
	stepSign: FormWizardStepStatus;
	disabled: boolean;
};

const FormWizardStep4: React.VFC<Props> = ({ form_id, changeStatus, errorStatusChanger, expanded, stepSign, disabled }) => {
	const { getAccessTokenSilently } = useAuth0();
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const [monitoringId, setMonitoringId] = useState<string>("");

	const [toastId, setToastId] = useState<string>("");
	const [isImportDesign, setIsImportDesign] = useState<boolean>(false);

	const startImportDesign = async (): Promise<void> => {
		setLoading(LoadingStatus.Pending);

		const uuid = uuidV4();
		toast("デザイン取り込み中...", { toastId: uuid, autoClose: false });
		setToastId(uuid);
		const auth_token = await getAccessTokenSilently();

		await postDesignImport(form_id, { auth_token, workspace_id })
			.then((res) => {
				//NOTE 検証用 console.log
				console.log("postDesignImport", res);
				setMonitoringId(res.notification.id);
			})
			.catch((reason: any) => {
				console.log(reason);
				setLoading(LoadingStatus.Error);
				toast.update(toastId, {
					toastId: toastId,
					type: toast.TYPE.ERROR,
					autoClose: 5000,
					render: <ToastContent subject="デザインの取り込みを開始できませんでした" message={reason.message} />,
				});
			});
	};

	const notification = useSelector((state: State) => {
		return selectNotificationsSelectors.selectById(state, monitoringId);
	});

	useEffect(() => {
		if (!isImportDesign && monitoringId && notification?.subject === "デザイン取り込み完了") {
			toast.update(toastId, {
				type: toast.TYPE.INFO,
				autoClose: 1500,
				render: "デザイン取り込みが完了しました",
			});
			setLoading(LoadingStatus.Loaded);
			setIsImportDesign(true);
			changeStatus("complete");
		}
		if (!isImportDesign && monitoringId && notification?.subject === "デザイン取り込み失敗") {
			toast.update(toastId, {
				toastId: toastId,
				type: toast.TYPE.ERROR,
				autoClose: 5000,
				render: <ToastContent subject="デザインの取り込みに失敗しました" message={notification.message} />,
			});
			setLoading(LoadingStatus.Error);
			setIsImportDesign(false);
			errorStatusChanger("step4");
		}
	}, [errorStatusChanger, changeStatus, isImportDesign, monitoringId, notification, toastId]);

	return (
		<Accordion expanded={expanded} disabled={disabled} data-testid="form-wizard-step4-root">
			<StepHeader title={"デザイン取り込み"} stepSign={stepSign} />

			<AccordionDetails>
				<Box pb={1.8}>
					<Box ml={1} mb={2}>
						<Typography className="text" variant="body1" color="textSecondary">
							<LiveHelpIcon fontSize="small" />
							「デザインを取り込む」ボタンを押すと、上記項目で設定したコラボフローの経路 ID をもとに、デザインの取り込みを開始します
						</Typography>
					</Box>

					<CardActions>
						<LoadingButton
							type="submit"
							color="primary"
							disabled={loading === LoadingStatus.Pending}
							loading={loading === LoadingStatus.Pending}
							size="large"
							variant="contained"
							onClick={startImportDesign}
							data-testid="form-wizard-step4-submit-button"
						>
							{loading === LoadingStatus.Pending ? "デザインを取り込み中" : "デザインを取り込む"}
						</LoadingButton>
					</CardActions>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default FormWizardStep4;
