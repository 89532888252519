import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import ArchivedFormsEmptyCard from "../../components/ArchivedFormsEmptyCard";
import FormCard from "../../components/FormCard";
import RoundSkeleton from "../../components/RoundSkeleton";
import { useFormList } from "../../hooks/useFormList";
import { selectWorkspace } from "../../store/WorkspaceSlice";
import MainTemplate from "../../templates/MainTemplate";

const ArchivedFormList: VFC = () => {
	const { id: workspace_id } = useSelector(selectWorkspace);
	const { archivedFormList: forms, isLoaded: formsLoaded, fetchArchivedFormList } = useFormList();

	useEffect(() => {
		fetchArchivedFormList(workspace_id);
	}, [workspace_id, fetchArchivedFormList]);

	return (
		<MainTemplate isLoading={!formsLoaded}>
			<Grid container spacing={0} justifyContent="center" alignItems="center" data-testid="archived-form-list-page-root">
				<Grid item xs={12}>
					<Box height={40} display="flex" alignItems="center">
						<Box flexGrow={1}>
							<Typography variant="h5" component="h2">
								アーカイブ済みフォーム一覧
							</Typography>
						</Box>
					</Box>
					<Box mx={-1}>
						<Grid container justifyContent="flex-start">
							{formsLoaded ? (
								forms.length ? (
									forms.map((form, index) => (
										<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
											<Box p={1}>
												<FormCard form={form} />
											</Box>
										</Grid>
									))
								) : (
									<Grid container justifyContent="center">
										<Box mt={3} ml={1}>
											<ArchivedFormsEmptyCard />
										</Box>
									</Grid>
								)
							) : (
								[...Array(4)].map((_, index) => (
									<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
										<Box p={1}>
											<RoundSkeleton variant="rectangular" height={100} />
										</Box>
									</Grid>
								))
							)}
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default ArchivedFormList;
