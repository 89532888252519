import axios from "axios";

import axiosErrorHandler from "../misc/axiosErrorHandler";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** ワークスペース更新 のリクエストペイロード */
export type PatchWorkspaceRequest = {
	/** ワークスペース ID */
	id: string;
	/** ワークスペース名 */
	name?: string;
	/** ワークスペース説明文 */
	description?: string;
	/** ワークスペースの利用可否値 */
	is_active?: boolean;
};

/** ワークスペース更新 のレスポンスペイロード */
export type PatchWorkspaceResponse = {
	/** ワークスペース ID */
	id: string;
	/** ワークスペース名 */
	name: string;
	/** ワークスペース説明文 */
	description?: string;
	/** ワークスペースの利用可否値 */
	is_active?: boolean;
	/** 更新日時 */
	created_at?: Date;
	/** 更新日時 */
	updated_at?: Date;
	/** 有効期限日時 */
	expires_at?: Date;
};

/**
 * ワークスペースを更新します。
 *
 * @param reqData - 更新するワークスペースのリクエストデータ
 * @param options - API のオプション
 */
export const patchWorkspace = async (reqData: PatchWorkspaceRequest, options: ApiOptions): Promise<PatchWorkspaceResponse> => {
	const { id, ...data } = reqData;
	const reqConfig = generateAxiosRequestConfig(options);

	const res = await axios.patch(`/workspaces/${id}`, data, reqConfig).catch(axiosErrorHandler);
	const response = { ...res.data };

	return response;
};
