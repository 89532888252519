import { ErrorPayload } from "../../typings/ErrorTypes";

export default function axiosErrorHandler(reason: any): any {
	if (reason.response) {
		const error = reason.response?.data?.error as ErrorPayload;

		throw Object.assign(new Error(), error);
	} else if (reason.request) {
		const xhr = reason.request as XMLHttpRequest;
		console.log(reason.request);
		throw new Error(`送信エラー: ReadyState:${xhr.readyState}, Status:${xhr.status}, StatusText:${xhr.statusText}`);
	}
	console.error(reason);
	throw reason;
}
