import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import AssignmentIcon from "@mui/icons-material/Assignment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, CardProps, IconButton, InputAdornment, TextField } from "@mui/material";

import { useFormSecret } from "../hooks/useFormSecret";
import { selectWorkspace } from "../store/WorkspaceSlice";
import { LoadingStatus } from "../typings/GeneralTypes";

import FadingModal from "./FadingModal";
import FormPasswordSettingCard from "./FormPasswordSettingCard";
import RoundSkeleton from "./RoundSkeleton";

type Props = CardProps & {
	form_id: string;
	is_password_required: boolean | undefined;
	is_archive?: boolean;
};

const FormPasswordCard: React.VFC<Props> = ({ form_id, is_password_required, is_archive = false, ...args }) => {
	const { id: workspace_id } = useSelector(selectWorkspace);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

	const { fetchFormSecret, removeFormSecret, formSecretState, formSecretControl } = useFormSecret();
	const { password, fetching, removing, registering } = formSecretState;

	const removeSecret = async () => {
		await removeFormSecret({ form_id, workspace_id });
	};

	useEffect(() => {
		if (is_password_required) {
			(async () => {
				await fetchFormSecret({ form_id, workspace_id });
			})();
		}
	}, [fetchFormSecret, form_id, is_password_required, workspace_id]);

	const onClose = () => {
		if (registering === "pending") return;
		setIsOpen(false);
	};

	const onCopy = (): void => {
		toast("パスワードをコピーしました", {
			type: toast.TYPE.DEFAULT,
			autoClose: 2000,
		});
	};

	return (
		<Card data-testid="form-password-card-root" {...args}>
			<CardHeader title="パスワード制限" subheader="公開フォームを開くときにパスワードによる制限をかけます" />

			<CardContent>
				{is_password_required && fetching !== LoadingStatus.Fulfilled ? (
					<Box mb={1} data-testid="form-password-card-password-fetching">
						<Box width={220} maxWidth="100%">
							<RoundSkeleton variant="rectangular" height={40} />
						</Box>
						<Box width={390} maxWidth="100%" mt={2}>
							<RoundSkeleton variant="rectangular" height={50} />
							<Box pt={0.5} px={2}>
								<RoundSkeleton variant="rectangular" height={12} />
							</Box>
						</Box>
					</Box>
				) : password ? (
					<Box data-testid="form-password-card-password-settled">
						<Box>
							<LoadingButton
								variant="outlined"
								color="primary"
								size="large"
								disabled={removing === LoadingStatus.Pending || is_archive}
								loading={removing === LoadingStatus.Pending}
								onClick={removeSecret}
								startIcon={<LockOpenIcon style={{ marginBottom: 1 }} />}
							>
								パスワード制限を解除する
							</LoadingButton>
						</Box>
						<Box mt={2}>
							<TextField
								value={password}
								label="パスワード"
								helperText="パスワードを変更する場合は、パスワード制限を解除して再設定してください"
								variant="outlined"
								autoComplete="off"
								type={isPasswordShown ? "text" : "password"}
								InputProps={{
									readOnly: true,
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => setIsPasswordShown(!isPasswordShown)}
												data-testid="form-password-card-password-visibility-toggle-btn"
												tabIndex={-1}
												size="large"
											>
												{isPasswordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
											</IconButton>
											<CopyToClipboard text={password} options={{ message: "コピーする" }}>
												<IconButton onClick={onCopy} data-testid="form-password-card-password-copy-button" tabIndex={-1} size="large">
													<AssignmentIcon />
												</IconButton>
											</CopyToClipboard>
										</InputAdornment>
									),
								}}
								data-testid="form-password-card-password-text-field"
							/>
						</Box>
					</Box>
				) : (
					<Box data-testid="form-password-card-password-unsettled">
						<Button variant="contained" color="primary" size="large" disabled={is_archive} onClick={() => setIsOpen(true)}>
							パスワードを設定する
						</Button>
					</Box>
				)}
			</CardContent>
			<FadingModal open={isOpen} onClose={onClose}>
				<FormPasswordSettingCard form_id={form_id} onClose={onClose} formSecretControl={formSecretControl} />
			</FadingModal>
		</Card>
	);
};

export default FormPasswordCard;
