import React, { useState, VFC } from "react";
import { useDropzone } from "react-dropzone";

import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Link, Typography } from "@mui/material";

import { AttachmentLinkCreateHandler, FieldValueHandler } from "../../pages/docs/DocumentView";
import { AttachmentFileHandler } from "../../pages/guest/GuestDocumentView";
import { AttachmentFieldEntity } from "../../typings/FieldTypes";
import { MapFieldValue } from "../../typings/FieldValueTypes";
import { LoadingStatus } from "../../typings/GeneralTypes";

type AttachmentZoneProps = {
	files: File[];
	field_id: string;
	removeFile: (e: React.MouseEvent<HTMLInputElement>, field_id: string) => void;
};

const AttachmentZone: VFC<AttachmentZoneProps> = ({ files, field_id, removeFile }) => {
	if (files.length) {
		const file = files[0];

		return (
			<Box
				display="flex"
				alignItems="center"
				color="gray"
				onClick={(e: React.MouseEvent<HTMLInputElement>) => {
					removeFile(e, field_id);
				}}
				overflow="hidden"
			>
				<Typography variant="body1" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{`${file.name}`}</Typography>
				<CloseIcon sx={{ mb: 0.25, mr: 0.5 }} fontSize="small" />
			</Box>
		);
	} else {
		return (
			<Typography variant="body1" color="gray">
				ファイルを選択
			</Typography>
		);
	}
};

type Props = {
	valueMap?: MapFieldValue;
	field: AttachmentFieldEntity;
	editable?: boolean;
	onChange: FieldValueHandler;
	onAttach?: AttachmentFileHandler;
	onCreateLink?: AttachmentLinkCreateHandler;
	disableLink?: boolean;
};

const AttachmentField: React.VFC<Props> = ({ valueMap, field, editable, onAttach, onCreateLink, onChange, disableLink }) => {
	const { id, table_id, table_row } = field || {};
	const field_id = id;

	const { file_path, file_name } = valueMap || {};

	const [link, setLink] = useState<string | undefined>();
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	const onDrop = ([file]: File[]) => {
		const file_name = file.name;
		const file_size = file.size;

		onAttach && onAttach("set", { field_id, file, file_name, file_size, table_id, table_row });
		// TODO: 随時添付ファイルを送信するときは、解決済みのファイルパスもイベントに送るようにする
		onChange({ id, valueMap: { type: "attachment", file_name, file_size }, table_id, table_row });
	};

	const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	const removeFile = (e: React.MouseEvent<HTMLInputElement>, field_id: string) => {
		acceptedFiles.splice(0);
		onAttach && onAttach("delete", { field_id });
		onChange({ id, valueMap: null, table_id, table_row });
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	const redirectToDownloadLink = async () => {
		if (!onCreateLink || !field_id || !file_path) return;
		setLoading(LoadingStatus.Pending);
		const res = await onCreateLink({ field_id, file_path });

		const { url } = res || {};

		if (url) {
			setLink(url);
			setLoading(LoadingStatus.Fulfilled);
			location.href = url;
			setTimeout(
				() => {
					setLink(undefined);
					setLoading(LoadingStatus.Initial);
				},
				// 59 分後にリンクを無効にする
				3540_000
			);
		}
	};

	if (!editable) {
		return (
			<Box
				data-testid="attachment-field-root"
				p={1}
				display="flex"
				alignItems="center"
				height="100%"
				sx={{
					borderWidth: "1px",
					borderStyle: "dashed",
					borderColor: "transparent",
				}}
			>
				{!file_name ? (
					<></>
				) : disableLink ? (
					<>
						<Box sx={{ mb: -0.75, mr: 0.5 }} color="gray">
							<AttachmentIcon />
						</Box>
						<Box display="flex" alignItems="center" color="gray" overflow="hidden">
							<Typography variant="body1" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
								{file_name}
							</Typography>
						</Box>
					</>
				) : link ? (
					<Button component={Link} href={link} variant="text" startIcon={<FileDownloadIcon sx={{ color: "gray" }} />} sx={{ textTransform: "none" }}>
						<Box display="flex" alignItems="center" color="gray" overflow="hidden">
							<Typography variant="body1" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
								{file_name}
							</Typography>
						</Box>
					</Button>
				) : (
					<LoadingButton
						onClick={redirectToDownloadLink}
						onAuxClick={redirectToDownloadLink}
						loading={loading === LoadingStatus.Pending}
						startIcon={<FileDownloadIcon sx={{ color: "gray" }} />}
						loadingPosition="start"
						variant="text"
						sx={{ textTransform: "none" }}
					>
						<Box display="flex" alignItems="center" color="gray" overflow="hidden">
							<Typography variant="body1" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
								{file_name}
							</Typography>
						</Box>
					</LoadingButton>
				)}
			</Box>
		);
	}

	return (
		<Box
			data-testid="attachment-field-root"
			p={1}
			display="flex"
			alignItems="center"
			height="100%"
			sx={{
				borderWidth: "1px",
				borderStyle: "dashed",
				borderColor: "gray",
				cursor: "pointer",
				...(isDragActive && { backgroundColor: "whitesmoke" }),
			}}
			{...getRootProps({ className: "dropzone" })}
		>
			<Box component="input" {...getInputProps()} />
			<Box sx={{ mb: -0.75, mr: 0.5 }} color="gray">
				<AttachmentIcon />
			</Box>

			<AttachmentZone files={acceptedFiles} field_id={field_id} removeFile={removeFile} />
		</Box>
	);
};

export default AttachmentField;
