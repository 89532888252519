import { createTheme } from "@mui/material";

import theme from "../Theme";

const fieldTheme = createTheme(
	{
		components: {
			MuiTextField: {
				defaultProps: {
					variant: "standard",
					InputProps: { disableUnderline: true },
				},
				styleOverrides: {
					root: {
						width: "100%",
						height: "100%",
						paddingLeft: "3px",
						paddingRight: "3px",
						"& input": {
							width: "100%",
							height: "auto",
						},
					},
				},
			},
			MuiSelect: {
				defaultProps: {
					variant: "standard",
					disableUnderline: true,
				},
				styleOverrides: {
					standard: {
						paddingLeft: "3px",
						paddingRight: "3px",
					},
				},
			},
			MuiFormControlLabel: {
				styleOverrides: {
					root: {
						margin: 0,
						paddingRight: 2,
					},
				},
			},
			MuiRadio: {
				styleOverrides: {
					root: {
						marginRight: -4,
					},
				},
			},
		},
	},
	theme
);

export default fieldTheme;
